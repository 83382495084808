.seller-app-policy-container {
  padding: 2rem;
  font-family: Inter;
  color: #333;

  h1 {
    font-size: 28px;
    margin-bottom: 40px;
    color: #222;
    text-align: center;
  }
  
  h2 {
    color: #333;
    font-size: 22px;
    margin-block: 60px 20px;
  }

  h3 {
    color: #333;
    font-size: 18px;
    margin-block: 40px 20px;
    font-weight: 600;
  }

  ol {
    margin: 10px 30px;
    padding: unset;
  }

    .innerList {
      list-style: lower-roman;
    }


  p, li {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
}