.small-spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #c5c4c4;
  border-top-color: #989898;
  transform: rotate(0deg);
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @at-root {
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.spinner-container {
  position: fixed;
  display: flex;
  justify-content: center;
  // background: linear-gradient(90deg, #003A72, #E32028);
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100vh;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1500;
  flex-direction: column;
  gap: 5px;

  .loader {
    display: block;
    width: 120px;
    #easypay-loader {
      width: 100%;
    }
  }
  .my-p-loading {
    font-size: 50px;
    font-weight: bold;
    // color: #fff;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
  .progress {
    opacity: 0.6;
    width: 340px;
    height: 30px;
    background: #6ed1ff;
    border-radius: 12px;
    background-image: linear-gradient(
      -45deg,
      #ffffff44 0%,
      #ffffff44 25%,
      transparent 25%,
      transparent 50%,
      #ffffff44 50%,
      #ffffff44 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 30px;
    animation: progress 1s linear infinite;
  }
  @keyframes progress {
    to {
      background-position-x: 30px;
    }
  }
}
