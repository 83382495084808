.ticket-list-container {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 24px;
    border: 1px solid #e6e6e6;
    border-radius: 8px 8px 0 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .ticketlist-main-content {
    padding: 24px;
    border: 1px solid #e5e5e5;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    .tabs {
      display: flex;
      gap: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #d5d9d9;

      .tab {
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        position: relative;

        &.active {
          color: #181725;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            padding: 0;
            height: 2px;
            bottom: -15px;
            background: #b0dcc0;
          }
        }
      }
    }
    .issue-list {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      gap: 20px;
      margin-top: 32px;

      .issue-card {
        padding-bottom: 20px;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
        transition: box-shadow 0.3s ease;

        &:hover {
          border-color: #929292;
        }

        .issue-id-row {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
          .issue-head-label {
            font-weight: 600;
            color: #666;
          }
        }

        .image-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;

          .issue-image {
              width: 60px;
              height: 60px;
              object-fit: contain;
              border-radius: 8px;
          }

          .text-column {
            display: flex;
            flex-direction: column;
            flex: 1;

            .issue-category,
            .issue-issue {
              font-size: 12px;
              font-weight: 400;
              color: #777;
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;

              span {
                color: #000;
              }
            }
          }

          .issue-status {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            white-space: nowrap;

            .status-text {
              color: #1a8c38;
              font-weight: bold;
              margin-right: 4px;
            }

            .issue-date {
              color: #888;
              margin-right: 4px;
            }

            .arrow {
              font-size: 16px;
            }
          }
          @media (max-width: 425px) {
            .issue-status {
              width: 100%;
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .no-issues-tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      margin-top: 20px;
    }

    .no-issues-img-tab {
      width: 140px;
      height: 140px;
      margin-bottom: 8px;
    }
  }
}
.no-issues {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.no-issues img {
  width: 140px;
  height: 140px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 768px) {
  .ticket-list-container {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;
      border: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #d5d9d9;

      h2 {
        font-size: 20px;
        font-weight: 500;
      }
    }
    .ticketlist-main-content {
      padding: 0;
      border: 0;
      .tabs {
        margin-top: 40px;
        padding-bottom: 15px;
        border-bottom: 1px solid #d5d9d9;
      }
    }
  }
}

// .issue-container {
//     width: 100%;

//     .header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 16px 24px;
//       border: 1px solid #e5e5e5;
//       border-radius: 8px 8px 0 0;

//       h2 {
//         font-size: 20px;
//         font-weight: 500;
//       }
//     }

//     .issue-grid {
//         padding: 24px;
//       border: 1px solid #e6e6e6;
//       border-top: 0;
//       border-radius: 0 0 8px 8px;

//       .tabs {
//         display: flex;
//         gap: 20px;

//         .tab {
//           font-size: 14px;
//           font-weight: 500;
//           cursor: pointer;
//           position: relative;

//           &.active {
//             color: #1a8c38;

//             &::after {
//               content: '';
//               position: absolute;
//               left: 0;
//               right: 0;
//               bottom: -2px;
//               height: 2px;
//               background: #1a8c38;
//             }
//           }
//         }
//       }

//       .divider {
//         height: 1px;
//         background: #e5e5e5;
//         margin: 12px 0;
//       }

// .issue-list {
//   display: flex;
//   flex-direction: column;
//   gap: 16px;

//   .issue-card {
//     display: flex;
//     flex-direction: column;
//     padding: 16px;
//     border-bottom: 1px dashed #e5e5e5;
//     cursor: pointer;
//     transition: box-shadow 0.3s ease;

//     &:hover {
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     }

//     .issue-id-row {
//       font-size: 12px;
//       font-weight: 600;
//       margin-bottom: 10px;
//     }

//     .image-row {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .issue-image {
//         img {
//           width: 60px;
//           height: 60px;
//           border-radius: 8px;
//         }
//       }

//       .text-column {
//           display: flex;
//           flex-direction: column;
//           margin-left: 16px;
//           flex: 1;

//           .issue-category {
//             font-size: 12px;
//             font-weight: 400;
//             color: #777;
//             margin-bottom: 4px;
//             span {
//               color: #000;
//             }
//           }
//           .issue-issue {
//             font-size: 12px;
//             font-weight: 400;
//             color: #777;
//             span {
//               color: #000;
//             }
//           }
//         }

//       .issue-status {
//           display: flex;
//           align-items: center;
//           justify-content: flex-end;
//           font-size: 12px;
//           white-space: nowrap;

//           .status-text {
//             color: #1a8c38;
//             font-weight: bold;
//             margin-right: 4px;
//           }

//           .issue-date {
//             color: #888;
//             margin-right: 4px;
//           }

//           .arrow {
//             font-size: 16px;
//           }
//         }
//     }
//   }
// }
//   .no-issues-tab {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     font-size: 18px;
//     margin-top: 20px;
// }

// .no-issues-img-tab {
//     width: 140px;
//     height: 140px;
//     margin-bottom: 8px;
// }
