.custom-select {
    position: relative;
  
    .dropdown-button {
      width: 100%;
      text-align: initial;
      white-space: nowrap;
      display: flex !important;
      justify-content: space-between;
      cursor: pointer;
    }
  
    .dynamic-class {
      background-color: #EBEDEC;
    }
    
    .dropdown-button::after {
      content: "";
      display: inline-block;
      border-top: 4.5px solid gray;
      border-bottom: none;
      border-right: 4.5px solid transparent;
      border-left: 4.5px solid transparent;
      align-self: center;
      margin-left: 6px;
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  
    button.dropdown-button:disabled {
      opacity: 0.5;
      cursor: url('../../assets/icons/disabled-icon.jpg'), not-allowed;
    }
  
    .options-container {
      --options-container-height: 200px;
      position: absolute;
      top: 42px;
      width: 175%;
      max-height: 0;
      transition: max-height 0.2s;
      border-radius: 5px;
      // z-index: 1;
      z-index: 2;
      overflow: hidden;
  
      .dropdown-options {
        font-size: 14px;
        border: 1px solid rgb(176, 220, 192);
        background-color: #fff;
        border-radius: 5px;
        width: 100%;
        font-weight: 400;
        max-height: var(--options-container-height);
        overflow: auto;
        box-shadow: 1px 1px 2px 0px rgba(176, 220, 192, 0.2);
        scrollbar-width: 5px;
        padding: 0 !important;
  
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }
  
        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 2.5px;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          border-radius: 2.5px;
          background-color: rgb(176, 220, 192);
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
  
        .dropdown-option {
          padding: 4px 6px;
          cursor: pointer;
          border-bottom: 1px solid rgb(176, 220, 192);
        }
  
        .dropdown-option:hover,
        .dropdown-option:active {
          background-color: #cbf2d9;
        }
  
        .dropdown-option:last-child {
          border-bottom: none;
        }
      }
    }
  
  }
 
  .marginRight10 {
    margin-right: 10px;
  }

  .custom-select.active {
    .dropdown-button::after {
      transform: rotate(-180deg);
    }
  
    .options-container {
      max-height: var(--options-container-height);
    }
  }

  