.group-footer-container {
  font-size: var(--font-size-19xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  background-color: black;
  padding-bottom: 18px;
  margin-top: auto;

  @media (max-width: 650px) {
    padding-bottom: 110px;
  }

  .group-footer {
    max-width: 1400px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--padding-28xl);
    position: relative;
    gap: 39px;

    @media screen and (max-width: 1440px) {
      margin-inline: 20px;
    }

    @media screen and (max-width: 800px) {
      gap: var(--gap-lgi);
      padding-top: var(--padding-12xl);
      box-sizing: border-box;
    }

    .newsletter {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      gap: var(--gap-xl);

      @media screen and (max-width: 1095px) {
        flex-direction: column;
        align-items: unset;
      }

      .newsletter-heading {
        .sign-up-to1 {
          font-size: 38px;
          font-weight: 500;
          margin-bottom: 9px;
        }

        .newsletter-subtitle {
          font-size: 16px;
          font-weight: 300;
        }

        @media screen and (max-width: 768px) {
          .sign-up-to1 {
            font-size: 28px;
          }

          .newsletter-subtitle {
            font-size: 14px;
          }
        }
      }

      .email-button-container {
        .email-input {
          width: 391px;
          padding: 15px;
          color: #fff;
          border: 2px solid #fff;
          border-radius: 3px;
          margin-right: 23px;

          @media screen and (max-width: 580px) {
            width: 100%;
            margin-right: 0;
          }

          &::placeholder {
            color: inherit;
          }
        }

        .newsletter-button {
          color: #fff;
          font-size: 14px;
          border-radius: 50px;
          background-color: #53b175;
          font-weight: 600;
          padding: 20px 30px;

          @media screen and (max-width: 580px) {
            padding: 15px 20px;
            margin-top: 15px;
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: flex-start;
      width: 1398px;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 100%;
      font-size: var(--font-size-sm);
      color: var(--color-gray-500);
      color: rgba(255, 255, 255, 0.6);

      .footer-info {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        row-gap: calc(2 * var(--gap-xl));
        width: 100%;
        // display: none;

        @media screen and (max-width: 1125px) {
          flex-wrap: wrap;
        }

        @media screen and (max-width: 1024px) {
          grid-template-columns: 33.33% 33.33% 33.33%;
        }

        @media screen and (max-width: 768px) {
          grid-template-columns: 50% 50%;
        }

        @media screen and (max-width: 425px) {
          grid-template-columns: none;
        }

        .footer-column {
          position: relative;
          flex: 1 1 0;
          display: flex;
          flex-shrink: 0;
          z-index: 1;
          flex-direction: column;
          font-family: Poppins;

          .footer-head {
            font-weight: 600;
            margin-bottom: 25px;
          }

          .footer-titles {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            transition: color 0.3s ease, transform 0.2s ease;
            &:hover {
              color: rgba(83, 177, 117, 1);
            }
          }

          ul {
            color: #fff;

            li {
              margin-bottom: 8px;
              cursor: pointer;
              transition: color 0.3s ease, transform 0.2s ease;

              .phone-number,
              .email {
                color: rgba(83, 177, 117, 1);
              }
            }

            .liHover:hover {
                color: rgba(83, 177, 117, 1);
              }
          }
        }
      }

      hr {
        width: 100%;
        opacity: 0.2;
        margin-block: 36px 17px;

        // margin-block: 0px 17px;
      }

      .store-icons-parent {
        display: flex;
        // display: none;
        width: 100%;
        font-size: var(--font-size-xs);
        position: relative;
        align-items: center;
        margin-top: 10px;
        gap: 12px;

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
      }

      .social-icons-parent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: var(--font-size-xs);
        position: relative;
        align-items: center;

        @media (max-width: 1024px) {
          padding-top: 30px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        @media screen and (max-width: 450px) {
          flex-wrap: wrap;
        }

        .social-icons {
          display: flex;
          flex-direction: row;
          gap: 11px;

          .social-icon {
            width: 22px;
            height: 22px;
          }
        }

        .payment-cards {
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: 0;
          display: flex;
          flex-direction: row;
          gap: 10px;

          .payment-card {
            width: 34px;
          }

        }

        .copyright-20201 {}

      }
    }
  }
}