.ticket-container {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #e5e5e5;
    border-radius: 8px 8px 0 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    .back-to-orders {
      color: #00b207;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .main-content {
    padding: 24px;
    border: 1px solid #DCDFE4;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    .tell-us-text{
      font-weight: 400;
      font-size: 18px;
      color: #181725;
    }
          .main-title{
          margin-top: 30px;
          padding-bottom: 20px;
          font-size: 16px;
          font-weight: 700;
          color: #181725;
          border-bottom: 1px dashed #DCDFE4;
        }
          .issue-description {
            display: flex;
            gap: 20px;
            margin-top: 20px;
      
            .short-description,
            .long-description {
              flex: 1;
              display: flex;
              flex-direction: column;
      
              label {
                font-weight: bold;
                margin-bottom: 5px;
              }
      
              input,
              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #DCDFE4;
                border-radius: 5px;
              }
            }
          }
            .file-upload {
              margin-top: 20px;
              label {
                font-weight: bold;
                display: block;
                margin-bottom: 10px;
              }
            
              .upload-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                border: 2px dashed #DCDFE4;
                border-radius: 5px;
                text-align: center;
                position: relative;
                // margin-top: 20px;
                cursor: pointer;
                gap: 10px;
            
                input[type="file"] {
                  display: none;
                }
            
                img.upload-preview {
                  max-width: 100%; 
                  max-height: 150px; 
                  object-fit: contain;
                  border-radius: 5px;
                }
            
                .upload-icon {
                  font-size: 48px; 
                  color: #888;
                }
                .replace-button{
                  
                    // margin-top: 20px;
                    background-color: rgba(83, 177, 117, 1);
                    color: #fff;
                    padding: 10px 10px;
                    text-align: center;
                    border-radius: 26px;
                    display: inline-flex; 
                    justify-content: center;
                    align-items: center;
                    width: auto; 
                    max-width: 80%; 
                    min-width: 120px; 
                    cursor: pointer;
                    font-size: 14px; 
                    white-space: nowrap;
                  
                    &:disabled {
                      opacity: 0.6;
                      cursor: not-allowed;
                    }
                  }

                
            
                .upload-text {
                  // margin-top: 10px;
                  font-size: 14px;
                  color: #555;
                }
              }
            }
        .issue-categories {
          .issue-category {
            padding-bottom: 8px;
            border-bottom: 1px solid #ddd; 
            margin-bottom: 10px;
            margin-top: 20px;
        
            .category-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              padding: 8px;
              background-color: transparent; 
        
              .category-title {
                font-size: 15px;
                font-weight: 400;
              }
        
              .arrow-icon {
                font-size: 12px;
              }
            }
        
            .subcategory-list {
              padding: 8px;
              border-top: 1px solid #ddd;
              display: block;
              visibility: visible; 
            }
        
            .subcategory-item {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              width: 100%; 
        
              input[type="radio"] {
                margin-right: 8px;
              }
        
              label {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                line-height: 1.5; 
                display: inline-block;
              }
        
              &:not(:last-child) {
                border-bottom: 1px solid #ddd;
                padding-bottom: 5px;
                width: 100%;
              }
            }
          }
        }
      .submit-issue-btn {
      margin-top: 20px;
      background-color: rgba(83, 177, 117, 1);
      color: #fff;
      padding: 10px 60px;
      text-align: center;
      border-radius: 30px;
      display: inline-flex; 
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: auto; 
      max-width: 80%; 
      min-width: 120px; 
      cursor: pointer;
      font-size: 1rem; 
      white-space: nowrap;
    
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    }
}
@media only screen and (max-width: 768px) {
  .ticket-container {
    .header {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 0;
      border: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #D5D9D9;
      justify-content: space-between; 
      @media only screen and (max-width: 430px) {
        flex-direction: column-reverse;
        align-items: unset;
        gap: 0;
      }

      
      h2 {
        flex: 1;
        text-align: start; 
      }

      
    }
    .main-content {
      margin-top: 30px;
      padding: 0;
      border: 0;
      .issue-description {
        display: flex;
        flex-direction: column; 
        gap: 20px;

        .short-description,
        .long-description {
          width: 100%; 
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .ticket-container {
    .header {
      padding-bottom: 10px;
    }

    .main-content {
      margin-top: 20px;
      
    }
  }
}



  


// .content {
//     width: 100%;
//     height: 100%;
//     border: 1px solid #e6e6e6;
//     border-radius: 10px;
//     padding: 20px;

//     .header {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         border-bottom: 1px solid #e6e6e6;
//         padding-bottom: 14px;
    
    
    
//         .back-to-list {
//           color: green;
//           font-weight: bold;
//           text-decoration: none;
//         }
//       }
//       .main-title{
//         padding: 10px 0;
//         border-bottom: 1px dashed #e6e6e6;
//       }
   
//       .tell-us-problem{
//         padding: 20px 0;

//       }
//       .select-products{
//         padding-bottom: 5px;
//       }

//       .order-form {
        
//           .describe-issue{
//             padding: 10px 0;
//             border-bottom: 1px dashed #e6e6e6;
//           }
          
          
    
//         .issue-description {
//           display: flex;
//           gap: 20px;
//           margin-top: 20px;
    
//           .short-description,
//           .long-description {
//             flex: 1;
//             display: flex;
//             flex-direction: column;
    
//             label {
//               font-weight: bold;
//               margin-bottom: 5px;
//             }
    
//             input,
//             textarea {
//               width: 100%;
//               padding: 10px;
//               border: 1px solid #e6e6e6;
//               border-radius: 5px;
//             }
//           }
//         }
//         .upload-issue-photo{
//             margin-bottom: 20px;
//             padding-bottom: 10px;
//             border-bottom: 1px dashed #e6e6e6;

//         }
    
//         .file-upload {
//             margin-top: 20px;
//             label {
//               font-weight: bold;
//               display: block;
//               margin-bottom: 10px;
//             }
          
//             .upload-box {
//               display: flex;
//               flex-direction: column;
//               align-items: center;
//               justify-content: center;
//               padding: 20px;
//               border: 2px dashed #e6e6e6;
//               border-radius: 5px;
//               text-align: center;
//               position: relative;
//               margin-top: 20px;
//               cursor: pointer;
          
//               input[type="file"] {
//                 display: none;
//               }
          
//               img.upload-preview {
//                 max-width: 100%; 
//                 max-height: 150px; 
//                 object-fit: contain;
//                 border-radius: 5px;
//               }
          
//               .upload-icon {
//                 font-size: 48px; 
//                 color: #888;
//               }
          
//               .upload-text {
//                 margin-top: 10px;
//                 font-size: 14px;
//                 color: #555;
//               }
//             }
//           }
          
//         .select-appr-issue{
//             padding: 10px 0;
//             border-bottom: 1px dashed #e6e6e6;
//             margin-top: 10px;


//         }
//         .issue-categories {
//           .issue-category {
//             padding-bottom: 8px;
//             border-bottom: 1px solid #ddd; 
//             margin-bottom: 10px;
//             margin-top: 20px;
        
//             .category-header {
//               display: flex;
//               justify-content: space-between;
//               align-items: center;
//               cursor: pointer;
//               padding: 8px;
//               background-color: transparent; 
        
//               .category-title {
//                 font-size: 14px;
//                 font-weight: 500;
//               }
        
//               .arrow-icon {
//                 font-size: 12px;
//               }
//             }
        
//             .subcategory-list {
//               padding: 8px;
//               border-top: 1px solid #ddd;
//               display: block;
//               visibility: visible; 
//             }
        
//             .subcategory-item {
//               display: flex;
//               align-items: center;
//               margin-bottom: 8px;
//               width: 100%; 
        
//               input[type="radio"] {
//                 margin-right: 8px;
//               }
        
//               label {
//                 font-size: 14px;
//                 color: #000;
//                 font-weight: normal;
//                 line-height: 1.5; 
//                 display: inline-block;
//               }
        
//               &:not(:last-child) {
//                 border-bottom: 1px solid #ddd;
//                 padding-bottom: 5px;
//                 width: 100%;
//               }
//             }
//           }
//         }
        
//       }
//     }
//     .submit-issue-btn {
//       margin-top: 20px;
//       background-color: rgba(83, 177, 117, 1);
//       color: #fff;
//       padding: 10px 60px;
//       text-align: center;
//       border-radius: 30px;
//       display: inline-flex; 
//       justify-content: center;
//       align-items: center;
//       gap: 10px;
//       width: auto; 
//       max-width: 80%; 
//       min-width: 120px; 
//       cursor: pointer;
//       font-size: 1rem; 
//       white-space: nowrap;
    
//       &:disabled {
//         opacity: 0.6;
//         cursor: not-allowed;
//       }
//     }
    