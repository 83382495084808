.access-denied {
    display: flex;
    height: 500px;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .access-content {
        text-align: center;
        width: 250px;
        @media only screen and (max-width: 767px) {
            width: 60%;
        }
        @media only screen and (max-width: 425px) {
            width: 90%;
        }

        .img-container {
            margin-bottom: 2em;
            .clock-img {
                width: 200px;
                @media only screen and (max-width: 767px) {
                    width: 50%;
                }   
            }
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            color: #131E4A;
            @media only screen and (max-width: 1023px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 18px;
            }
            @media only screen and (max-width: 425px) {
                font-size: 16px;
            }
           
        }
    
        .sub-title {
            font-weight: 400;
            margin: 0.5em 0 1em;
        }

        .home-link{
            text-decoration: none;
            display: inline-block;
        }
    
        .offer-btn {
            padding: 0.7em 1em;
            background: #18A38A;
            border-radius: 8px;
            font-weight: 700;
            font-size: 1em;
            color: #FFFFFF;
            margin: auto;
        }

        .offer-btn:active {
            box-shadow: 0 2px #666;
            transform: translateY(2px);
        }
    }
}