.home-search-wrapper {
    display: flex;
    font-size: 14px;
    flex-shrink: 0;
    margin: auto;
    flex: 1 1 auto;

    &.active {
        box-shadow: 0 4px 10px rgba(0, 128, 0, 0.2);
        border-radius:20px
    }

    // visibility: hidden;
    .custom-select {
        #category-search {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .select-text {
                width: 110px;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;

                // @media (max-width: 375px) {
                //     width: 65px;
                // }
            }
        }

        .options-container {
            z-index: 3;
        }
    }

    .search-container {
        position: relative;
        display: flex;
        flex: auto;
        cursor: pointer;
        // &:hover {
        //     border: none; // No need for individual border if wrapper is highlighted
        // }

        .search-input {
            border: 1px solid #b0dcc0;
            border-left: none;
            padding: 10px;
            border-radius: 0 19px 19px 0;
            // width: 140px;
            // min-width: 140px;
            flex: auto;

            &::placeholder {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .search-lens {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 10px;
            background: #fff;
            object-fit: cover;
        }

        .search-dropdown {
            position: absolute;
            top: 40px;
            width: 100%;
            max-height: 0px;
            z-index: 2;
            overflow: hidden;

            &.active {
                max-height: 300px;
            }

            .search-list {
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid rgb(176, 220, 192);
                max-height: 300px;
                overflow-y: auto;

                .searching-container {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding: 10px;

                    .searching-text {
                        padding-block: 5px;

                        .small-loader {
                            // margin-top: 3px;
                        }
                    }
                }

                .no-items-text {
                    padding: 15px 10px;
                }

                .search-item {
                    padding: 5px 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #cbf2d9;
                    }
                }

            }
        }
    }

    // @media (max-width: 925px) {
    //     #category-search {
    //         display: none !important;
    //     }

    //     .search-input {
    //         border-left: 1px solid #b0dcc0;
    //         border-radius: 19px;
    //     }
    // }

    // @media screen and (max-width: 768px) {
    //     .search-input {
    //         display: none;
    //     }
    // }

    @media (max-width: 425px) {
        gap: 10px;

        .custom-select {
            display: none;
        }

        .search-container {
            .search-input {
                border-radius: 19px;
                // border-left: unset;
                border: 1px solid #b0dcc0;
            }
        }

    }
}