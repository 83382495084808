.deals-product-page {
    .deals-banner {
      padding: 50px 10px;
      display: flex;
      // display: none;
      justify-content: center;
      background-image: linear-gradient(75.04deg,
          #f4e8f3 0%,
          #f3eff6 52.07%,
          #eee0f9 102.02%);
          background-size: contain;
          background-repeat: no-repeat;
  
      .deals-banner-content {
        text-align: center;
  
        .banner-breadcrumb {
          font-size: 13px;
          padding-top: 7px;
          color: #53b175;
        }
  
      }
    }
  
    .product-list-section {
      margin-top: 42px;
      max-width: 1400px;
      margin-inline: auto;
      font-family: "inter";
      margin-bottom: 80px;
  
      .search-sort-row {
        margin-bottom: 10px;
      }
  
      .hracpl {
        border: 1px solid #D2D2D2;
        margin-bottom: 20px;
      }
  
      .filter-and-product-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
  
        .left-content {
          display: none; // uncomment this before code push
        }
  
        .right-content {
          width: 100%;
  
          .selected-filters-row {
            display: flex;
            display: none; // uncomment this before code push
            gap: 10px;
  
            .selected-filter {
              border: 1.5px solid #E2E2E2;
              border-radius: 5px;
              padding-block: 9px;
              padding-inline: 12px 30px;
              font-size: 12px;
              position: relative;
  
              &::after {
                content: "\00D7";
                position: absolute;
                right: 10px;
              }
            }
          }
  
          .cardsContainer {
            display: grid;
            gap: 16px;
            margin-top: 13px;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            flex-wrap: wrap;
          }
        }
      }
  
      
  
     
  
      @media (max-width: 1440px) {
        margin-inline: 20px;
      }
    }
  }
  
  // Modal overlay styles
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // Modal box styles
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    width: 500px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
  
    h2 {
      margin-top: 0;
    }
  }
  
  .modal p {
    font-size: 14px;
  }
  
  // Fade-in animation for modal
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .inputSearch {
    width: 100%;
    border: 1px solid black;
    padding: 10px 3px;
    border-radius: 10px;
  }
  
  .modal h3 {
    margin: 10px 0px;
  }
  
  .search-input {
    border: 1px solid #b0dcc0;
    padding: 10px;
    flex: auto;
    border-radius: 40px;
  
    &::placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .search-lens {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;
    background: #fff;
    object-fit: cover;
  }
  
  .search-wrapper {
    display: flex;
    position: relative;
    font-size: 12px;
    flex-shrink: 0;
    margin: auto;
    flex: 1 1 auto;
  
   
  }
  
  .currentLocation {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
  
    .currentIcon {
      margin-top: 20px;
      display: flex !important;
      gap: 5px;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  
  
  
 
  
  .trendingHeading {
    padding: 40px;
    background-color: #f8f8f8;
  }
  
  
  
  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  
    .small-spinner {
      width: 50px;
      height: 50px;
    }
  
  }
  
  .dropdownContainer {
    display: flex;
    gap: 16px;
    margin-top: 24px;
  }
  
  .catdropdown {
    padding: 8px;
    border-radius: 10px;
    background: #b0dcc0;
    width: 30%;
    color: #222;
    font-family: "Public Sans";
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    border: none;
  }
  
  .storedropdown {
    padding: 8px;
    font-family: "Public Sans";
    font-size: 20px;
    color: #222;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    background: #b0dcc0;
    border: none;
    width: 70%;
  }
  
  @media screen and (max-width: 768px) {
    .catdropdown {
      width: 100%;
    }
  
    .storedropdown {
      width: 100%;
    }
  }
  
  @media (max-width: 800px) {
    .dropdownContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .fullWidthSection {
      display: flex;
      flex-direction: row;
    }
  
    .leftSidebar {
      width: 50%;
    }
  }
  
  .heading {
    color: #222;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
    margin-bottom: 8px;
  }
  
  .underline {
    background: #0da487;
    width: 120px;
    height: 2px;
    margin-bottom: 16px;
  }
  
  .subheading {
    font-family: "Public Sans";
    font-size: 24px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
  }
  
  .checkbox {
    margin-right: 8px;
    border-radius: 8px;
    background: #53b175;
    accent-color: #0da487;
  }
  
  .dottedline {
    margin-top: 20px;
    border-bottom: 1px dashed rgba(74, 85, 104, 0.3);
    max-width: 213px;
    height: 1px;
  }
  
  .product {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }
  
  .prodName {
    color: #222;
    font-style: normal;
    font-weight: 600;
  }
  
  .prodWeight {
    color: #4a5568;
    font-style: normal;
    font-weight: 400;
  }
  
  .productImage {
    width: 60px;
    height: 50px;
    margin-right: 16px;
  }
  
  .rupee {
    font-family: "Public Sans";
    color: #53b175;
  }
  
  .strikethrough {
    text-decoration: line-through;
    color: #7c7c7c;
  }