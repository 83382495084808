.row-container {
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      gap: 10px;
      margin-bottom: 30px;
  
      .product-list-title {
        font-weight: 400;
        font-size: 1em;
      }
  
      .explore-btn {
        text-transform: uppercase;
        color: #000;
        text-decoration: underline;
        font-size: 16px;
        text-wrap: nowrap;
        cursor: pointer;
      }
  
      @media screen and (max-width: 768px) {
        font-size: 22px;
  
        .explore-btn {
          font-size: 12px;
        }
      }
  
      @media screen and (max-width: 650px) {
        font-size: 20px;
  
        .explore-btn {
          font-size: 10px;
        }
      }
    }
    .adv-cards-row {
        display: flex;

        gap: 40px;
      
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      
        .store-adv-card {
          position: relative;
          border-radius: 20px;
          width: 270px;
          flex-shrink: 0;
          background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
            url(../../assets/images/itemImageLoader.png);
          background-size: cover;
          background-size: cover;
          background-position: center;
          cursor: pointer;
      
          @media screen and (max-width: 768px) {
            width: 100%;
            height: 250px;
            background-size: contain;
            background-repeat: no-repeat;
          }
      
          .store-adv-content {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-weight: 700;
            font-family: "Poppins";
            color: #fff;
      
            .offer-button {
              font-size: 1rem;
              padding: 0.75em 1.3em;
              border-radius: 24px;
              background-color: #53b175;
              color: inherit;
              font-weight: inherit;
              margin-bottom: 38px;
            }
      
            .adv-store-name {
              position: relative;
              font-size: 20px;
               padding: 0 10px
            }
      
            .adv-product-link {
              display: inline-block;
              color: #000;
              font-size: 13px;
              margin-bottom: 3em;
              color: inherit;
            }
          }
        }
      }
  
    .cards-grid {
      display: flex;
      flex-wrap: wrap;
      // overflow-x: auto;
      gap: 20px;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      .deal-card {
        max-width: 244px;
      }
  
      .store-card {
        max-width: 200px;
        flex: 1;
        cursor: pointer;
  
        .store-img {
          width: 100%;
          min-width: 160px;
          max-width: 223px;
          aspect-ratio: 223 / 228;
          object-fit: cover;
          border-radius: 10px;
        }
  
        .store-name {
          text-align: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 2.6em;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
  
      @media (max-width: 1100px) {
        // if even - consider this limit
        .store-card:nth-last-child(1) {
          display: none;
        }
      }
  
      @media (max-width: 920px) {
        // if odd - doesnt cosider
        .store-card:nth-last-child(2) {
          display: none;
        }
      }
  
      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        row-gap: 20px;
  
        .store-card {
          display: block !important;
  
          .store-img {
            min-width: 120px;
          }
        }
      }
  
      @media (max-width: 425px) {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        row-gap: 20px;
  
        .store-card {
          display: block !important;
  
          .store-img {
            min-width: 120px;
          }
        }
      }
    }
  }


// .row-container-nearby-stores {
//     .title-container-nearby-stores {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       font-size: 40px;
//       gap: 10px;
  
//       .product-list-title-nearby-stores {
//         font-weight: 500;
//         font-size: 1em;
//       }
  
//       .explore-btn-nearby-stores {
//         text-transform: uppercase;
//         color: #000;
//         text-decoration: underline;
//         font-size: 16px;
//         text-wrap: nowrap;
//       }
  
//       @media screen and (max-width: 768px) {
//         font-size: 22px;
  
//         .explore-btn-nearby-stores {
//           font-size: 12px;
//         }
//       }
  
//       @media screen and (max-width: 650px) {
//         font-size: 20px;
  
//         .explore-btn-nearby-stores {
//           font-size: 10px;
//         }
//       }
//     }
  
//     .cards-grid-nearby-stores {
//       display: flex;
//       flex-wrap: wrap;
//       // overflow-x: auto;
//       gap: 20px;
  
//       &::-webkit-scrollbar {
//         display: none;
//       }
  
//       .deal-card-nearby-stores {
//         max-width: 244px;
//       }
  
//       .store-card-nearby-stores {
//         max-width: 200px;
//         flex: 1;
//         cursor: pointer;
  
//         .store-img-nearby-stores {
//           width: 100%;
//           min-width: 160px;
//           max-width: 223px;
//           aspect-ratio: 223 / 228;
//           object-fit: cover;
//           border-radius: 10px;
//         }
  
//         .store-name-nearby-stores {
//           text-align: center;
//           width: 100%;
//           overflow: hidden;
//           text-overflow: ellipsis;
//           height: 2.6em;
//           display: -webkit-box;
//           -webkit-box-orient: vertical;
//           -webkit-line-clamp: 2;
//         }
//       }
  
//       @media (max-width: 1100px) {
//         // if even - consider this limit
//         .store-card-nearby-stores:nth-last-child(1) {
//           display: none;
//         }
//       }
  
//       @media (max-width: 920px) {
//         // if odd - doesnt cosider
//         .store-card-nearby-stores:nth-last-child(2) {
//           display: none;
//         }
//       }
  
//       @media (max-width: 768px) {
//         display: grid;
//         grid-template-columns: auto auto auto;
//         gap: 10px;
//         row-gap: 20px;
  
//         .store-card-nearby-stores {
//           display: block !important;
  
//           .store-img-nearby-stores {
//             min-width: 120px;
//           }
//         }
//       }
  
//       @media (max-width: 425px) {
//         display: grid;
//         grid-template-columns: auto auto;
//         gap: 10px;
//         row-gap: 20px;
  
//         .store-card-nearby-stores {
//           display: block !important;
  
//           .store-img-nearby-stores {
//             min-width: 120px;
//           }
//         }
//       }
//     }
//   }