.error-screen {
  
    text-align: center;
    font-size: 16px;
    // position: absolute;
    top: 50%;
    // transform: translateY(-50%);
    width: 100%;
    margin-block: 80px;
  
    
  
  .error-screen img {
    width: 140px;
    height: 140px;
    margin-bottom: 8px;
  }
  .error-screen-add-address {
    color: #00b207;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
    }
  }