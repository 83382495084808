
.festival-offers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .row-container {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .festive-banner{
      position: relative;
      @media (max-width: 650px) {
        margin: 0;
      }
      
    .festival-main-banner{
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
      height: 350px;
      object-fit: cover;
      @media (max-width: 650px) {
        height: 330px;
        border-radius: 0;
        margin: 0;

      
        // margin-top: 10px;
      }

    }
  }
   
    // .row-container {
    //   margin-inline: 0px;
    // }
    
    .festive-content {
      display: flex;
      gap: 20px;
      width: 100%;
      // width: 100%;
      @media (max-width:1420px) {
        margin-inline: 0 20px;

      }
      


      .left-category{
        // background-color: #f3f1f1;
        // background-color: #f3f3f3;

        background-color: #effff4;
                // background-color: #f8f8f8;

        display: flex;
        border-radius: 0 10px 10px 0;

      .categories { 
        min-width: 250px;
        border-radius: 4px;
        align-self: center;
        align-content: center;

      
        

     
        .festive-category {
          padding: 20px;
          cursor: pointer;
          border-radius: 5px 0 0 5px;
          background-color: transparent;
          font-size: 16px;
          color: #000;
          font-weight: 500;
          margin-left: 20px;
          position: relative;
          text-align: center;
        
          &.active {
            background-color: #53b175;
            color: #fff;
        
            &::after {
              content: "";
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #53b175; /* Arrow effect */
            }
          }
        
          &:not(.active):hover {
            background-color: #ccf8da;
            color: #000000;
        
            &::after {
              content: "";
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #ccf8da; /* Arrow effect */
            }
          }
        }
        
      }
    }
  
      .right-festive-products {
        flex-direction: column; 
        margin: 0;
        display: flex;
        width: 100%;
        gap: 20px;
        overflow: hidden;
        .productheader{
          display: flex;
          justify-content: space-between;
          .shopFor{
              .shop-text{
                font-size: 18px;
                font-weight: 400;

              }
              .title-text{
                font-size: 24px;
                font-weight: 600;
                color: #07732e;

              }

            

          }
         
        .explore-btn {
          text-transform: uppercase;
          color: #000;
          text-decoration: underline;
          font-size: 16px;
          text-wrap: nowrap;
          cursor: pointer;
          align-self: center;
        }
      }
  
        .no-products {
          color: #888;
          font-size: 16px;
          text-align: center;
        }
        @media (max-width:1420px) {
          margin-right: 20px;
  
        }
      }
      .festive-content-mobile {
        display: none; /* Hide mobile layout by default */
      }
    }
    .festive-content-mobile {
      display: none; 
    }
    @media (max-width: 768px) {
      .festive-content {
        display: none;
      }
    
      .festive-content-mobile {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    
      .category-block {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    
      .category-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .explore-btn {
        text-transform: uppercase;
        color: #000;
        text-decoration: underline;
        font-size: 16px;
        cursor: pointer;
      }
    }

  }
  
  