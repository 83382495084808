.fullWidthSection {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: row;
}

.offersHeading {
  font-family: "Public Sans";
  margin-right: auto;
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

.offersGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the gap between items */
  justify-content: space-around;
}

.offerItem {
  width: 49.3%; /* Each item takes 50% of the container's width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.offerImage {
  width: 100%; /* Make the image fill the container */
  height: auto;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border-radius: 8px; /* Optional: for rounded corners */
}

.blogsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the gap between items */
  justify-content: center; /* Center items horizontally */
}

.blogItem {
  width: 23%; /* Each item takes 50% of the container's width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogImage {
  width: 100%; /* Make the image fill the container */
  height: auto;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  border-radius: 8px; /* Optional: for rounded corners */
}

.fullWidthComponent {
  margin-top: 20px;
}

.threeColumnsSection {
  display: flex;
  flex-direction: row;
  background-color: #ffffff; /* Section background color */
}

.column {
  flex: 1;
  padding: 20px;
}

@media (max-width: 800px) {
  .fullWidthSection {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .threeColumnsSection {
    flex-direction: column;
  }
  .column {
    margin-bottom: 10px;
  }
}

.threeColumnsSection {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
}

.column {
  flex: 1;
  padding: 20px;
  background-color: inherit;
}

.columnHeading {
  margin-bottom: 8px;
  color: #222;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.underlineWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.greenUnderline {
  background: #0da487;
  height: 3px;
  width: 60px;
  margin-right: 8px;
}

.greyUnderline {
  background: #cccccc;
  height: 2px;
  width: 100%;
  margin-left: -9px;
}

.tcproductCard {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.tcproductImage {
  width: 60px;
  height: 70px;
  object-fit: cover;
  margin-right: 16px;
}

.tcproductInfo {
  display: flex;
  flex-direction: column;
}

.tcproductTitle {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #222;
  font-style: normal;
}

.tcproductRating {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
  color: gold;
}

.tcreviewCount {
  margin-left: 4px;
  font-size: 12px;
  color: #999999;
}

.tcproductPrice {
  font-size: 14px;
  font-weight: 600;
  color: #53b175;
}

@media screen and (max-width: 768px) {
  .offersGrid {
    justify-content: flex-start;
  }
}
