.sticky-header {
  position: sticky;
  background-color: #fff;
  // box-shadow: 0 1px 5px 0 #9cbebc;
  z-index: 11;
  top: 0;
  width: 100%;

  .visit-showroom-strip {
    // display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    height: 43px;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
  }

  .header-menus-wrapper {
    width: 100%;

    .header-menus {
      display: flex;
      align-items: center;
      max-width: 1400px;
      padding-block: 20px 15px;
      flex-wrap: wrap;
      gap: 20px;
      margin: auto;

      .nav-left,
      .nav-right {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-shrink: 0;
      }

      .nav-left {
        .ham-button {
          display: none;

          @media screen and (max-width: 605px) {
            display: none;
          }
        }

        .logo-container {
          flex-basis: 120px;
          width: 120px;
          flex-shrink: 0;
          display: flex;

          // background-color: #dcdcdc;
          .logo-link {
            align-self: center;
            width: 100%;

            .logo {
              width: 100%;
              display: block;
            }
          }
        }

        .location-wrapper {
          display: flex;
          align-items: center;
          font-size: 12px;
          gap: 5px;
          flex-shrink: 0;
          cursor: pointer;

          .location-name {
            width: 70px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }


      .nav-right {
        .sell-on-ondc-button {
          padding: 8px 26px;
          font-weight: 600;
          border: 2px solid #53b175;
          border-radius: 20.5px;
          font-size: 14px;
          text-wrap: nowrap;
          cursor: pointer;
          a{
            text-decoration: none;
            color: #53B175;
          }
        }
        .ondc-logo{
          width: 76px;
          height: 40px;
          object-fit: contain;
        }

        .cart-wrapper {
          width: 25px;
          position: relative;
          margin-inline: 10px;
          flex-shrink: 0;
          cursor: pointer;

          .cart-icon {
            width: 100%;
          }

          .item-count {
            background-color: #53b175;
            display: inline-block;
            padding: 3px 8px;
            font-size: 10px;
            border-radius: 12.5px;
            color: #fff;
            position: absolute;
            top: -10px;
            right: -10px;
          }
        }

        .profile-wrapper {
          width: 30px;
          height: 30px;
          border-radius: 40%;
          overflow: hidden;
          flex-shrink: 0;

          .profile-initials {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: bolder;
            cursor: pointer;
            background-color: #53b175;
            border-radius: 50%;
            color: white;
          }

          .profile-pic {
            width: 100%;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 600px) {
          .sell-on-ondc-button{
            display: none;
          }
          .ondc-logo{
            display: none;
          }
        }

      }

      @media screen and (max-width: 1400px) {
        margin-inline: 20px;
      }
      @media screen and (max-width: 995px) {
        justify-content: space-between;
        .home-search-wrapper {
          width: 100%;
          order: 3;
        }
      }

      @media screen and (max-width: 768px) {
        margin-inline: 15px;
        gap: 10px;
        justify-content: space-between;

        .nav-left {
          .logo-container {
            flex-basis: 90px;
            width: 90px;
          }
        }

        .home-search-wrapper {
          width: 100%;
          order: 3;
        }

        .nav-right {
          // display: none;
        }
      }

      @media screen and (max-width: 425px) {
        .nav-left {
          // justify-content: space-between;
          width: 100%;
          .location-wrapper {
            .location-name {
              width: unset;
            }
          }
        }

        .nav-right {
          display: none;
        }
      }
    }

    // .header-menus-mobile {
    //   display: none;
    // }
  }

  .ham-button {
    div {
      width: 30px;
      background-color: #000;
      height: 1.8px;
      margin-block: 7px;
    }
  }

  .category-strip {
    background-color: rgba(26, 173, 163, 1);
    color: #fff;
    // display: none;

    .ham-menu {
      display: none;
    }

    .category-list {
      overflow-x:auto;
      white-space: nowrap;
      margin-inline: auto;
      max-width: 1400px;
      font-size: 14px;
      scrollbar-width: none;
      
      // scrollbar-height: 0;
      // scrollbar-width: 0;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #dcdcdc;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: rgb(125, 245, 237);
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-button {
        display: block;
      }

      li {
        display: inline-block;

        .tab {
          display: inline-block;
          padding: 10px 10px;
          color: #fff;
          text-decoration: unset;
          cursor: pointer;
          &:hover {
            background-color: rgb(28 , 196, 184);          
          }   
      }
      // .tab.active {
      //   color: #000 !important;
      //   background-image: linear-gradient(75.04deg, #f4e8f3 0%, #f3eff6 52.07%, #eee0f9 102.02%) ;
      //   }
      }
    }
  }

  .mobile-bottom-menu {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    height: 84px;
    border-radius: 15px 15px 0 0;
    display: none;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: 0 -3px 6px 0 rgba(203, 203, 203, 0.3);
    padding: 0px 22px 22px;

    @media (max-width: 425px) {
      display: flex;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 3px;
      flex-shrink: 0;
      flex-basis: 53px;
      text-decoration: none;
      color: #000;

      .menu-icon {
        height: 20px;
      }

      &:nth-child(1) .menu-icon {
        height: 22px;
      }

      .name {
        font-size: 12px;
        font-weight: 600;

        @media (max-width: 375px) {
          font-size: 10px;
        }
      }
    }

    .cart-sphere {
      width: 79px;
      height: 79px;
      flex-basis: 79px;
      border-radius: 50%;
      background-color: #53b175;
      outline: 6px solid #fff;
      box-shadow: 0 -10px 6px 0 rgba(203, 203, 203, 0.3);
      flex-shrink: 0;
      color: #fff;
      position: relative;

      .item-count {
        background-color: #ff0000;
        display: inline-block;
        padding: 3px 8px;
        font-size: 10px;
        border-radius: 12.5px;
        color: #fff;
        position: absolute;
        top: 10px;
        right: 15px;
      }

      @media (max-width: 375px) {
        width: 55px;
        height: 55px;
        flex-basis: 55px;
        box-shadow: none;

        .item-count {
          top: -3px;
          right: 3px;
        }
      }
    }
  }

  @media (max-width: 650px) {}
}

@media (max-width: 650px) {
  .sticky-header {
    width: 100% !important;

    .visit-showroom-strip {
      margin-bottom: 14px;
      font-size: 10px;
    }

    .header-menus-wrapper {
      // .header-menus {
      //   display: none;
      // }

      // .header-menus-mobile {
      //   display: flex;
      //   justify-content: space-between;

      //   .logo-container {
      //     flex-basis: 92px;
      //     width: 92px;
      //     flex-shrink: 0;
      //     display: flex;
      //     // background-color: #dcdcdc;

      //     .logo {
      //       width: 100%;
      //       display: block;
      //     }
      //   }

      //   .location-wrapper {
      //     display: flex;
      //     align-items: center;
      //     font-size: 12px;
      //     gap: 5px;
      //     flex-shrink: 0;

      //     .location-name {
      //       width: 70px;
      //       overflow: hidden;
      //       white-space: nowrap;
      //       text-overflow: ellipsis;
      //     }
      //   }
      // }
    }

    .ham-button {
      div {
        width: 22px;
        background-color: #000;
        height: 1.1px;
        margin-block: 5px;
      }
    }

    .category-strip {
      // padding: 5px 0 5px 15px;
      // font-size: 14px;

      // .ham-menu {
      //   display: flex;
      //   gap: 10px;
      //   align-items: center;

      //   .ham-button {
      //     div {
      //       background-color: #fff;
      //     }
      //   }
      // }

      .category-list {
        // display: none;
        scrollbar-width: none;
      }
    }
  }
}

// @media screen and (max-width: 975px) {
//   .sticky-header {
//     .header-menus-wrapper {
//       .header-menus {
//         justify-content: space-between;
//       }
//     }
//   }
// }

// @media screen and (max-width: 590px) {
//   .sticky-header {
//     .header-menus-wrapper {
//       .header-menus {
//         justify-content: space-between;

//         .nav-left {
//           gap: 8px;

//           .ham-button {
//             div {
//               width: 20px;
//               margin-block: 5px;
//             }
//           }

//           .logo-container {
//             flex-basis: 92px;
//             width: 92px;
//           }
//         }

//         .home-search-wrapper {
//           display: none;
//         }

//         .nav-right {
//           .sell-on-ondc-button {
//             position: absolute;
//             top: 175px;
//             right: 5px;
//             background-color: #fff;
//             padding: 4px 10px;
//             border: 2px solid #53b175;
//             border-radius: 16.5px;
//             font-size: 14px;
//             text-wrap: nowrap;
//             z-index: 10;
//           }

//           .profile-wrapper {
//             width: 30px;
//             height: 30px;
//           }
//         }
//       }
//     }
//   }
// }