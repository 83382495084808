.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.banner-image {
  width: 100%;
  max-width: 100%;
  /* Adjust the max width as needed */
  height: auto;
  display: block;
}

.custom-carousel .carousel-control-prev {
  left: -5%;
}

.custom-carousel .carousel-control-next {
  right: -5%;
}

.custom-carousel .carousel-control-prev-icon {
  background-image: none;
}

.custom-carousel .carousel-control-prev-icon::before {
  content: "\f053";
  /* FontAwesome icon for left chevron */
  font-family: "FontAwesome";
  color: black;
  /* Icon color to black */
  font-size: 20px;
  /* Adjust icon size as needed */
}

.custom-carousel .carousel-control-next-icon {
  background-image: none;
}

.custom-carousel .carousel-control-next-icon::before {
  content: "\f054";
  /* FontAwesome icon for right chevron */
  font-family: "FontAwesome";
  color: black;
  /* Adjust color and transparency as needed */
  font-size: 20px;
  /* Adjust icon size as needed */
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.contact-us {
  color: var(--color-white);
  text-decoration: none;
  position: relative;
  flex: 1 1 0;
  display: inline-block;
}

.contact-us .contact-underline {
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 1px;
}

.frame-item {
  height: 2px;
  width: 67px;
  position: relative;
  z-index: 2;
}

.vector-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-smi);
  margin-top: -10.7px;
}

.icon {
  width: 78.5px;
  height: 56.5px;
  position: relative;
  display: none;
}

.frame-child1,
.frame-inner,
.line-div {
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 36px;
  height: 1px;
}

.frame-child1,
.line-div {
  top: 11px;
}

.frame-child1 {
  top: 22px;
}

.line-parent {
  width: 35px;
  height: 23px;
  position: relative;
}

.frame-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 11px;
}

.location-marker {
  height: 46px;
  flex: 1;
  position: relative;
  background-color: #d9d9d9;
}

.frame-container,
.frame-div {
  display: flex;
  justify-content: flex-start;
}

.frame-div {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-13xl);
}

.frame-container {
  width: 212px;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.7px var(--padding-smi) 0 0;
  box-sizing: border-box;
}

.group-icon {
  height: 21.5px;
  width: 16.7px;
  position: relative;
  min-height: 22px;
}

.search-location {
  position: relative;
  display: inline-block;
  min-width: 92px;
}

.search-location-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-9xs);
}

.back-arrow-icon {
  width: 10px;
  height: 6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}

.back-arrow-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 5.4px;
}

.frame-wrapper2,
.group-parent {
  display: flex;
  justify-content: flex-start;
}

.group-parent {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-sm-1);
}

.frame-wrapper2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 14.6px 0 0;
}

.rectangle-div {
  height: 52px;
  width: 600px;
  position: relative;
  border-radius: var(--br-7xl-5);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkseagreen);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.back-arrow-icon1 {
  width: 10px;
  height: 6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 1;
}

.back-arrow-container,
.select-category-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.back-arrow-container {
  flex-direction: column;
  padding: 8.2px 0 0;
}

.search-business-category {
  position: relative;
  z-index: 1;
}

.frame-parent2,
.search-business-category-or-p-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.search-business-category-or-p-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
  min-width: 130px;
}

.frame-parent2 {
  width: 367px;
  flex-direction: row;
  gap: var(--gap-29xl);
  max-width: calc(100% - 35px);
}

.dropdown-icon {
  height: 14.6px;
  width: 15px;
  position: relative;
  z-index: 1;
}

.frame-wrapper3,
.rectangle-group {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.rectangle-group {
  align-self: stretch;
  border-radius: var(--br-7xl-5);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkseagreen);
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-smi) var(--padding-8xl) var(--padding-lgi)
    var(--padding-7xl);
  gap: var(--gap-xl);
}

.frame-wrapper3 {
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.7px 0 0;
}

.vector-icon {
  height: 50.6px;
  width: 1px;
  position: relative;
  z-index: 1;
  margin-left: -439px;
}

.frame-parent1 {
  width: 634px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.sell-on-ondc {
  position: relative;
  font-size: var(--font-size-sm);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-mediumseagreen-100);
  text-align: center;
  display: inline-block;
  min-width: 92px;
}

.frame-wrapper5,
.sell-on-ondc-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sell-on-ondc-wrapper {
  cursor: pointer;
  border: 2px solid var(--color-mediumseagreen-100);
  padding: var(--padding-8xs) var(--padding-5xl);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-31xl);
  flex-direction: row;
}

.sell-on-ondc-wrapper:hover {
  background-color: rgba(59, 150, 92, 0.09);
  border: 2px solid var(--color-seagreen-200);
  box-sizing: border-box;
}

.frame-wrapper5 {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-mid) 0 0;
}

.ellipse-div {
  position: absolute;
  top: 1px;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-mediumseagreen-100);
  width: 16px;
  height: 15.6px;
  z-index: 1;
}

.cart-count {
  text-decoration: none;
  flex: 1;
  position: relative;
  font-weight: 700;
  color: inherit;
  z-index: 2;
}

.jamshopping-cart-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.ellipse-parent {
  align-self: stretch;
  height: 16.6px;
  position: relative;
}

.frame-wrapper6 {
  width: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.4px 0 0;
  box-sizing: border-box;
}

.notification-icon {
  width: 36px;
  height: 35px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}

.notification-icon-wrapper {
  flex-direction: column;
  padding: 1.5px 0 0;
}

.frame-parent3,
.frame-wrapper4,
.notification-icon-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent3 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-9xl);
}

.frame-wrapper4 {
  width: 269px;
  flex-direction: column;
  padding: 6.3px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.frame-group,
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.frame-group {
  justify-content: flex-start;
  gap: var(--gap-29xl);
}

.frame-wrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  box-sizing: border-box;
}

.bottom-curve-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.ahr0chnfx19vbmrjlm1lzxnoby5vcm-icon {
  height: 352px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.ahr0chnfx19vbmrjlm1lzxnoby5vcm-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.container-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.frame-child2 {
  height: 71px;
  width: 342px;
  position: relative;
  background-color: var(--color-darkseagreen);
  display: none;
  max-width: 100%;
}

.appliances-for-your,
.up-to-55 {
  margin: 0;
}

.appliances-for-your-container {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.rectangle-container {
  flex: 1;
  background-color: var(--color-darkseagreen);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-2) var(--padding-36xl) var(--padding-sm)
    var(--padding-37xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 2;
}

.container-parent,
.offer-one-items-child {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
}

.container-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-126xl) 0 0;
}

.offer-one-items-child {
  height: 160px;
  width: 342px;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  flex-shrink: 0;
  display: none;
}

.asset-8-icon,
.fashion {
  position: relative;
  z-index: 2;
}

.asset-8-icon {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion {
  display: inline-block;
  min-width: 45px;
}

.asset-8-parent,
.fashion-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-7xl) 0 var(--padding-8xl);
}

.asset-8-parent {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-9-icon,
.fashion1 {
  position: relative;
  z-index: 2;
}

.asset-9-icon {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion1 {
  display: inline-block;
  min-width: 45px;
}

.asset-9-parent,
.fashion-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-container {
  flex-direction: row;
  padding: 0 var(--padding-8xl) 0 var(--padding-7xl);
}

.asset-9-parent {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-10-icon,
.fashion2 {
  position: relative;
  z-index: 2;
}

.asset-10-icon {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion2 {
  display: inline-block;
  min-width: 45px;
}

.asset-10-parent,
.fashion-frame,
.offer-one-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-frame {
  flex-direction: row;
  padding: 0 var(--padding-9xl) 0 var(--padding-6xl);
}

.asset-10-parent,
.offer-one-items {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.offer-one-items {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-12xl);
  gap: var(--gap-8xs);
  max-width: 100%;
  width: 100%;
  z-index: 1;
}

.shop-all,
.vector-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.shop-all {
  top: 6.8px;
  left: 26px;
  text-transform: uppercase;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
  z-index: 3;
}
.shop-all-button-one {
  position: absolute;
  top: 140.7px;
  left: 111px;
  width: 119.5px;
  height: 30.5px;
  text-align: center;
  font-size: var(--font-size-sm);
}
.offer-one-image {
  align-self: stretch;
  height: 171.2px;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
}

.frame-parent5 {
  flex: 1;
  flex-direction: column;
}

.appliance-container-one {
  flex-direction: row;
  justify-content: space-around;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
}

.first-appliance-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 19.6px;
  min-width: 223px;
  max-width: 100%;
}
.container-icon1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frame-child3 {
  height: 71px;
  width: 342px;
  position: relative;
  background-color: var(--color-darkseagreen);
  display: none;
  max-width: 100%;
}
.appliances-for-your1,
.up-to-551 {
  margin: 0;
}
.appliances-for-your-container1 {
  position: relative;
  font-weight: 500;
  z-index: 3;
}
.rectangle-parent1 {
  flex: 1;
  background-color: var(--color-darkseagreen);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-2) var(--padding-36xl) var(--padding-sm)
    var(--padding-37xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 2;
}
.offer-two-items-child,
.second-appliance-row {
  position: relative;
  max-width: 100%;
}
.second-appliance-row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-126xl) 0 0;
}
.offer-two-items-child {
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  flex-shrink: 0;
  display: none;
}

.asset-8-icon1,
.fashion3 {
  position: relative;
  z-index: 2;
}

.asset-8-icon1 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion3 {
  display: inline-block;
  min-width: 45px;
}

.asset-8-group,
.fashion-wrapper1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper1 {
  flex-direction: row;
  padding: 0 var(--padding-7xl) 0 var(--padding-8xl);
}

.asset-8-group {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-9-icon1,
.fashion4 {
  position: relative;
  z-index: 2;
}

.asset-9-icon1 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion4 {
  display: inline-block;
  min-width: 45px;
}

.asset-9-group,
.fashion-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper2 {
  flex-direction: row;
  padding: 0 var(--padding-8xl) 0 var(--padding-7xl);
}

.asset-9-group {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-10-icon1,
.fashion5 {
  position: relative;
  z-index: 2;
}

.asset-10-icon1 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion5 {
  display: inline-block;
  min-width: 45px;
}

.asset-10-group,
.fashion-wrapper3,
.offer-two-items {
  display: flex;
}

.fashion-wrapper3 {
  flex-direction: row;
  padding: 0 var(--padding-9xl) 0 var(--padding-6xl);
}

.asset-10-group,
.offer-two-items {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.offer-two-items {
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  justify-content: space-evenly;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-12xl);
  gap: var(--gap-8xs);
  width: 100%;
}

.asset-10-group,
.asset-9-group,
.asset-8-group {
  gap: 2px;
}
.asset-8-icon1,
.asset-9-icon1,
.asset-10-icon1 {
  width: 100%;
  height: 100%;
  gap: 2px;
}
.fashion-wrapper1,
.fashion-wrapper2,
.fashion-wrapper3 {
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: center;
}

.shop-all1,
.vector-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.shop-all1 {
  top: 6.8px;
  left: 26px;
  text-transform: uppercase;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
  z-index: 3;
}
.shop-all-button-two {
  position: absolute;
  top: 140.7px;
  left: 32.5%;
  width: 119.5px;
  height: 30.5px;
  text-align: center;
  font-size: var(--font-size-sm);
  border: 1px solid var(--color-whitesmoke-200);
  background: white;
  justify-content: center;
}
.offer-two-image {
  align-self: stretch;
  height: 171.2px;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
}
.first-appliance-row {
  display: grid;
  grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
  column-gap: 8px;
  max-width: 100%;
}

.container-icon2 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.frame-child4 {
  height: 71px;
  width: 342px;
  position: relative;
  background-color: var(--color-darkseagreen);
  display: none;
  max-width: 100%;
}

.appliances-for-your2,
.up-to-552 {
  margin: 0;
}

.appliances-for-your-container2 {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.rectangle-parent2 {
  background-color: var(--color-darkseagreen);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-2) var(--padding-36xl) var(--padding-sm)
    var(--padding-37xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 2;
}

.third-appliance-row {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
}

.third-appliance-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-126xl) 0 0;
}

.offer-three-items-child {
  height: 160px;
  width: 342px;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  flex-shrink: 0;
  display: none;
}

.asset-8-icon2,
.fashion6 {
  position: relative;
  z-index: 2;
}

.asset-8-icon2 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion6 {
  display: inline-block;
  min-width: 45px;
}

.asset-8-container,
.fashion-wrapper4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper4 {
  flex-direction: row;
  padding: 0 var(--padding-7xl) 0 var(--padding-8xl);
}

.asset-8-container {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-9-icon2,
.fashion7 {
  position: relative;
  z-index: 2;
}

.asset-9-icon2 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion7 {
  display: inline-block;
  min-width: 45px;
}

.asset-9-container,
.fashion-wrapper5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper5 {
  flex-direction: row;
  padding: 0 var(--padding-8xl) 0 var(--padding-7xl);
}

.asset-9-container {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-10-icon2,
.fashion8 {
  position: relative;
  z-index: 2;
}

.asset-10-icon2 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion8 {
  display: inline-block;
  min-width: 45px;
}

.asset-10-container,
.fashion-wrapper6,
.offer-three-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper6 {
  flex-direction: row;
  padding: 0 var(--padding-9xl) 0 var(--padding-6xl);
}

.asset-10-container,
.offer-three-items {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.offer-three-items {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-12xl);
  gap: var(--gap-8xs);
  max-width: 100%;
  width: 100%;
  z-index: 1;
}
.shop-all2,
.vector-icon3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.shop-all2 {
  top: 6.8px;
  left: 26px;
  text-transform: uppercase;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
  z-index: 3;
}
.shop-all-button-three {
  position: absolute;
  top: 140.7px;
  left: 111px;
  width: 119.5px;
  height: 30.5px;
  text-align: center;
  font-size: var(--font-size-sm);
}
.offer-three-image {
  align-self: stretch;
  height: 171.2px;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
}
.third-appliance-row-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.container-icon3 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.product-details-child {
  height: 71px;
  width: 342px;
  position: relative;
  background-color: var(--color-darkseagreen);
  display: none;
  max-width: 100%;
}

.appliances-for-your3,
.up-to-553 {
  margin: 0;
}

@media screen and (max-width: 1410px) {
  .first-appliance-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 20px;
  }
}

@media screen and (max-width: 743px) {
  .first-appliance-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  }

  .third-appliance-row-parent {
    width: 70%;
    margin: auto;
  }
}

.appliances-for-your-container3 {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.product-details {
  flex: 1;
  background-color: var(--color-darkseagreen);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-2) var(--padding-36xl) var(--padding-sm)
    var(--padding-37xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 2;
}

.items-row-child,
.products {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
}

.products {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-126xl) 0 0;
}

.items-row-child {
  height: 160px;
  width: 342px;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  flex-shrink: 0;
  display: none;
}

.asset-8-icon3,
.fashion9 {
  position: relative;
  z-index: 2;
}

.asset-8-icon3 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion9 {
  display: inline-block;
  min-width: 45px;
}

.asset-8-parent1,
.fashion-wrapper7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper7 {
  flex-direction: row;
  padding: 0 var(--padding-7xl) 0 var(--padding-8xl);
}

.asset-8-parent1 {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-9-icon3,
.fashion10 {
  position: relative;
  z-index: 2;
}

.asset-9-icon3 {
  width: 98px;
  height: 95.4px;
  object-fit: cover;
}

.fashion10 {
  display: inline-block;
  min-width: 45px;
}

.asset-9-parent1,
.fashion-wrapper8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper8 {
  flex-direction: row;
  padding: 0 var(--padding-8xl) 0 var(--padding-7xl);
}

.asset-9-parent1 {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}

.asset-10-icon3 {
  width: 98px;
  height: 95.4px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.fashion11 {
  position: relative;
  display: inline-block;
  min-width: 45px;
  z-index: 2;
}

.asset-10-parent1,
.fashion-wrapper9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper9 {
  flex-direction: row;
  padding: 0 var(--padding-9xl) 0 var(--padding-6xl);
}

.asset-10-parent1 {
  flex-direction: column;
  gap: var(--gap-9xs-9);
}
.items-row,
.shop-all3,
.vector-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.items-row {
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-lg) var(--padding-12xl);
  gap: var(--gap-8xs);
  max-width: 100%;
  z-index: 1;
}
.shop-all3,
.vector-icon4 {
  height: 100%;
  z-index: 2;
}
.shop-all3 {
  top: 6.8px;
  left: 26px;
  text-transform: uppercase;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
  z-index: 3;
}
.shop-link {
  position: absolute;
  top: 140.7px;
  left: 111px;
  width: 119.5px;
  height: 30.5px;
  text-align: center;
  font-size: var(--font-size-sm);
}

.items {
  align-self: stretch;
  height: 171.2px;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
}

.container,
.group-icon1 {
  flex-shrink: 0;
}

.container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.group-icon1 {
  height: 14.8px;
  width: 103.2px;
  position: relative;
  color: black;
  font-family: var(--font-poppins);
}

.separator {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
}

.container-group,
.first-appliance-row-parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.container-group {
  width: 342px;
  flex-direction: column;
  align-items: flex-end;
  gap: 38px;
}

.first-appliance-row-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 0 3.1px;
  box-sizing: border-box;
  gap: var(--gap-sm);
  text-align: center;
  font-size: var(--font-size-lg);
}

.asset-1-icon,
.fashion12 {
  position: relative;
  flex-shrink: 0;
}

.asset-1-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.fashion12 {
  display: inline-block;
  min-width: 78px;
}

.asset-1-parent,
.fashion-wrapper10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.fashion-wrapper10 {
  flex-direction: row;
  padding: 0 var(--padding-14xl) 0 var(--padding-15xl);
}

.asset-1-parent {
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-2-icon,
.food {
  position: relative;
  flex-shrink: 0;
}

.asset-2-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.food {
  flex: 1;
}

.asset-2-parent,
.food-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.food-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-28xl) 0 48px;
}

.asset-2-parent {
  width: 145.4px;
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-3-icon,
.electronics {
  position: relative;
  flex-shrink: 0;
}

.asset-3-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.electronics {
  display: inline-block;
  min-width: 110px;
}

.asset-3-parent,
.electronics-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.electronics-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-lg) 0 var(--padding-mid);
}

.asset-3-parent {
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-4-icon,
.grocery {
  position: relative;
  flex-shrink: 0;
}

.asset-4-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.grocery {
  display: inline-block;
  min-width: 80px;
}

.asset-4-parent,
.grocery-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.grocery-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-14xl) 0 32px;
}

.asset-4-parent {
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-5-icon,
.furniture {
  position: relative;
  flex-shrink: 0;
}

.asset-5-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.furniture {
  display: inline-block;
  min-width: 90px;
}

.asset-5-parent,
.furniture-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.furniture-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-8xl) 0 var(--padding-9xl);
}

.asset-5-parent {
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-6-icon,
.pharma {
  position: relative;
  flex-shrink: 0;
}

.asset-6-icon {
  width: 144.4px;
  height: 141.5px;
  object-fit: cover;
}

.pharma {
  display: inline-block;
  min-width: 76px;
}

.asset-6-parent,
.pharma-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.pharma-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-15xl);
}

.asset-6-parent {
  flex-direction: column;
  gap: var(--gap-2xl-4);
}

.asset-7-icon,
.jewellery {
  position: relative;
  flex-shrink: 0;
}

.asset-7-icon {
  width: 145.4px;
  height: 141.5px;
  object-fit: cover;
}

.jewellery {
  display: inline-block;
  min-width: 96px;
}

.asset-7-parent,
.jewellery-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.jewellery-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-6xl) 0 var(--padding-5xl);
}

.best-deals-near,
.group-icon2 {
  position: relative;
  max-width: 100%;
  flex-shrink: 0;
}

.best-deals-near {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}

.group-icon2 {
  align-self: stretch;
  height: 14.8px;
  overflow: hidden;
}

.deals-icon,
.deals-title {
  display: flex;
  align-items: flex-start;
}

.deals-icon {
  width: 103.2px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15.5px 0 0;
  box-sizing: border-box;
}

.deals-title {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.vector-icon5 {
  height: 170.7px;
  width: 244.6px;
  position: relative;
  object-fit: cover;
  display: none;
}
.deal-item-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.group-icon3,
.group-icon4 {
  position: absolute;
  top: 50.5px;
  left: 14.3px;
  width: 54.8px;
  height: 20.6px;
  z-index: 2;
}

.group-icon4 {
  top: 35.5px;
  left: 14.2px;
  width: 27.5px;
  height: 8.2px;
}

.deal-item-icon-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.frame-wrapper8,
.vector-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper8 {
  height: 87.1px;
  width: 89.5px;
  position: relative;
}

.vector-parent {
  align-self: stretch;
  padding: var(--padding-64xl-6) 0 0;
  background-image: url("../../../public/vector@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.div,
.lorem-ipsum {
  position: relative;
  display: inline-block;
}

.lorem-ipsum {
  min-width: 122px;
  flex-shrink: 0;
}

.div {
  min-width: 36px;
  white-space: nowrap;
}

.hour {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.buy-now {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.button-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.deal-item-button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 119.5px;
  height: 30.5px;
  position: relative;
}

.deal-item-price {
  align-self: stretch;
  gap: var(--gap-mid-5);
  flex-shrink: 0;

  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.deal-item-details,
.deal-item-price,
.frame-parent6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.deal-item-details {
  align-self: stretch;
  gap: var(--gap-3xs-3);
}

.frame-parent6 {
  flex: 1;
  gap: var(--gap-lgi);
  min-width: 241px;
  max-width: 245px;
}
.vector-icon6 {
  height: 170.7px;
  width: 244.6px;
  position: relative;
  object-fit: cover;
  display: none;
}
.vector-icon7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.group-icon5,
.group-icon6 {
  position: absolute;
  top: 50.5px;
  left: 14.2px;
  width: 54.9px;
  height: 20.6px;
  z-index: 2;
}

.group-icon6 {
  top: 35.5px;
  width: 27.4px;
  height: 8.2px;
}

.vector-container {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.frame-wrapper9,
.vector-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper9 {
  height: 87.1px;
  width: 89.5px;
  position: relative;
}

.vector-group {
  align-self: stretch;
  padding: var(--padding-64xl-6) 0 0;
  background-image: url("../../../public/vector@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.div1,
.lorem-ipsum1 {
  position: relative;
  display: inline-block;
}

.lorem-ipsum1 {
  min-width: 122px;
  flex-shrink: 0;
}

.div1 {
  min-width: 36px;
  white-space: nowrap;
}

.hour1 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container1 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.buy-now1 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}
.vector-icon8 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.buy-now-parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 119.5px;
  height: 30.5px;
  position: relative;
}

.frame-parent8 {
  align-self: stretch;
  gap: var(--gap-mid-5);
  flex-shrink: 0;

  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.frame-parent7,
.frame-parent8,
.lorem-ipsum-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.lorem-ipsum-parent {
  align-self: stretch;
  gap: var(--gap-3xs-3);
}

.frame-parent7 {
  flex: 1;
  gap: var(--gap-lgi);
  min-width: 241px;
  max-width: 245px;
}
.vector-icon9 {
  height: 170.7px;
  width: 244.6px;
  position: relative;
  object-fit: cover;
  display: none;
}
.vector-icon10 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.group-icon7,
.group-icon8 {
  position: absolute;
  top: 50.5px;
  left: 14.4px;
  width: 54.7px;
  height: 20.6px;
  z-index: 2;
}

.group-icon8 {
  top: 35.5px;
  left: 14.2px;
  width: 27.5px;
  height: 8.2px;
}

.vector-parent2 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.frame-wrapper10,
.vector-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper10 {
  height: 87.1px;
  width: 89.5px;
  position: relative;
}

.vector-parent1 {
  align-self: stretch;
  padding: var(--padding-64xl-6) 0 0;
  background-image: url("../../../public/vector@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.div2,
.lorem-ipsum2 {
  position: relative;
  display: inline-block;
}

.lorem-ipsum2 {
  min-width: 122px;
  flex-shrink: 0;
}

.div2 {
  min-width: 36px;
  white-space: nowrap;
}

.hour2 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container2 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.buy-now2 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-group {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 119.5px;
  height: 30.5px;
  position: relative;
}

.frame-parent10 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-mid-5);
  flex-shrink: 0;

  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.frame-parent10,
.frame-parent9,
.lorem-ipsum-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.lorem-ipsum-group {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs-3);
}

.frame-parent9 {
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-lgi);
  min-width: 241px;
  max-width: 245px;
}

.vector-icon12 {
  height: 170.7px;
  width: 244.6px;
  position: relative;
  object-fit: cover;
  display: none;
}

.vector-icon13 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.group-icon10,
.group-icon9 {
  position: absolute;
  top: 50.5px;
  left: 14.3px;
  width: 54.7px;
  height: 20.6px;
  z-index: 2;
}

.group-icon10 {
  top: 35.5px;
  left: 14.2px;
  width: 27.4px;
  height: 8.2px;
}

.vector-parent4 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.frame-wrapper11,
.vector-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper11 {
  height: 87.1px;
  width: 89.5px;
  position: relative;
}

.vector-parent3 {
  align-self: stretch;
  padding: var(--padding-64xl-6) 0 0;
  background-image: url("../../../public/vector@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.div3,
.lorem-ipsum3 {
  position: relative;
  display: inline-block;
}

.lorem-ipsum3 {
  min-width: 122px;
  flex-shrink: 0;
}

.div3 {
  min-width: 36px;
  white-space: nowrap;
}

.hour3 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container3 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.buy-now3 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon14 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 119.5px;
  height: 30.5px;
  position: relative;
}

.frame-parent12 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-mid-5);
  flex-shrink: 0;

  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.frame-parent11,
.frame-parent12,
.lorem-ipsum-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.lorem-ipsum-container {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs-3);
}

.frame-parent11 {
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-lgi);
  min-width: 241px;
  max-width: 245px;
}

.vector-icon15 {
  height: 170.7px;
  width: 244.6px;
  position: relative;
  object-fit: cover;
  display: none;
}

.vector-icon16 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.group-icon11,
.group-icon12 {
  position: absolute;
  top: 50.5px;
  left: 14.4px;
  width: 54.7px;
  height: 20.6px;
  z-index: 2;
}

.group-icon12 {
  top: 35.5px;
  left: 14.2px;
  width: 27.5px;
  height: 8.2px;
}

.featured-image-icons {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.featured-image-container {
  height: 87.1px;
  width: 89.5px;
  justify-content: flex-start;
  position: relative;
}

.featured-content,
.featured-image,
.featured-image-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.featured-image {
  flex: 1;
  justify-content: flex-start;
  padding: var(--padding-64xl-6) 0 0;
  background-image: url("../../../public/vector@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.featured-content {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-12xs) 0 0;
}

.div4,
.lorem-ipsum4 {
  position: relative;
  display: inline-block;
}

.lorem-ipsum4 {
  min-width: 122px;
  flex-shrink: 0;
}

.div4 {
  min-width: 36px;
  white-space: nowrap;
}

.hour4 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container4 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.parent3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.buy-now4 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.button-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.featured-button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 119.5px;
  height: 30.5px;
  position: relative;
}

.featured-price {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-mid-5);
  flex-shrink: 0;

  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.featured-details,
.featured-item,
.featured-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.featured-details {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs-3);
}

.featured-item {
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-lgi);
  min-width: 241px;
  max-width: 246px;
}

.deal-items,
.deal-items-row,
.deals-title-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.deal-items-row {
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 45.6px;
}

.deal-items,
.deals-title-parent {
  align-self: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
}

.deal-items {
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}

.deals-title-parent {
  flex-direction: column;
  padding: 0 0 36.4px;
  gap: 32.5px;
}

.group-icon13,
.top-store-near {
  position: relative;
  max-width: 100%;
  flex-shrink: 0;
}

.top-store-near {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
}

.group-icon13 {
  align-self: stretch;
  height: 14.8px;
  overflow: hidden;
}

.stores-icon,
.stores-title {
  display: flex;
  align-items: flex-start;
}

.stores-icon {
  width: 103.2px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-base-6) 0 0;
  box-sizing: border-box;
}

.stores-title {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}

.mask-group-icon {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-wrapper,
.mask-group-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.govindjee-jeeyo-namkin-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-4xs) 0 var(--padding-3xs);
}

.mask-group-parent {
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.mask-group-icon1 {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin1 {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-container,
.mask-group-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.govindjee-jeeyo-namkin-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-3xs) 0 var(--padding-4xs);
}

.mask-group-group {
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.mask-group-icon2 {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin2 {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-frame,
.mask-group-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.govindjee-jeeyo-namkin-frame {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-3xs) 0 var(--padding-4xs);
}

.mask-group-container {
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.mask-group-icon3 {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin3 {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-wrapper1,
.mask-group-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.govindjee-jeeyo-namkin-wrapper1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-4xs) 0 var(--padding-3xs);
}

.mask-group-parent1 {
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.mask-group-icon4 {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin4 {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-wrapper2,
.mask-group-parent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.govindjee-jeeyo-namkin-wrapper2 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-4xs) 0 var(--padding-3xs);
}

.mask-group-parent2 {
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.mask-group-icon5 {
  width: 223.7px;
  height: 228.2px;
  position: relative;
  object-fit: cover;
}

.govindjee-jeeyo-namkin5 {
  flex: 1;
  position: relative;
  line-height: 140%;
}

.govindjee-jeeyo-namkin-wrapper3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-4xs) 0 var(--padding-3xs);
}

.govindjee-jeeyo-namkin-wrapper3,
.mask-group-parent3,
.store-items-row {
  align-items: flex-start;
  justify-content: flex-start;
}

.mask-group-parent3 {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm-6);
}

.store-items-row {
  align-self: stretch;
  display: grid;
  flex-direction: row;
  gap: var(--gap-sm-1);
  grid-template-columns: repeat(6, minmax(168px, 1fr));
}

.store-link-child {
  height: 328px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.store-link {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs) 0 var(--padding-7xs);
  box-sizing: border-box;
  max-width: 100%;
}

.store-items,
.stores {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}

.store-items {
  display: flex;
  align-items: flex-end;
  gap: var(--gap-38xl);
  max-width: 100%;
  font-size: var(--font-size-base);
}

.stores {
  gap: 66.7px;
}
.frame-parent,
.offer-row-container,
.frame-wrapper7,
.stores {
  display: flex;
  max-width: 100%;
}

.frame-parent4 {
  flex-direction: column;
  gap: var(--gap-10xl);
  padding: 20px 20px 20px 20px;
}

.frame-wrapper7 {
  align-self: stretch;
  box-sizing: border-box;
}

.frame-wrapper7 {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  font-size: var(--font-size-21xl);
  color: var(--color-black);
}
.frame-parent {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 93px;
  gap: 16.3px;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray);
  font-family: var(--font-inter);
}
.vector-icon17,
.vector-icon18 {
  height: 393.1px;
  width: 1411px;
  position: absolute;
  margin: 0 !important;
  right: 20px;
  bottom: -290px;
}

.vector-icon17 {
  object-fit: cover;
}

.vector-icon18 {
  z-index: 1;
}

.food1,
.group-icon14 {
  position: relative;
  flex-shrink: 0;
}

.food1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 95px;
}

.group-icon14 {
  align-self: stretch;
  height: 14.8px;
  max-width: 100%;
  overflow: hidden;
}

.banner-item-category-icon {
  width: 103.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base-6) 0 0;
  box-sizing: border-box;
}

.banner-item-category {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.image-30-icon {
  width: 270px;
  height: 307.5px;
  position: relative;
  object-fit: cover;
  display: none;
  z-index: 0;
}

.image-31 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-300);
  z-index: 1;
}

.offer-details-child {
  height: 45px;
  width: 156px;
  position: relative;
  border-radius: var(--br-3xl-5);
  background-color: var(--color-mediumseagreen-100);
  display: none;
}

.upto-20-off {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 130%;
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
  min-width: 114px;
  z-index: 3;
}

.banner-item-offer,
.offer-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.offer-details {
  cursor: pointer;
  border: 0;
  padding: 10.4px var(--padding-2xl) var(--padding-mini-2);
  background-color: var(--color-mediumseagreen-100);
  border-radius: var(--br-3xl-5);
  justify-content: flex-start;
  white-space: nowrap;
  z-index: 2;
}

.frame-button:hover,
.offer-details:hover,
.vector-parent10:hover {
  background-color: var(--color-seagreen-200);
}

.banner-item-offer {
  justify-content: flex-end;
  padding: 0 var(--padding-19xl);
}

.food-store,
.name {
  margin: 0;
}

.food-store-name-container {
  position: relative;
  line-height: 130%;
  z-index: 2;
}

.banner-item-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-42xl) 0 var(--padding-43xl);
}

.see-all-products {
  align-self: stretch;
  height: 23.3px;
  position: relative;
  font-size: var(--font-size-smi);
  text-decoration: underline;
  line-height: 115%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 2;
}

.image-30-parent {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 68.4px var(--padding-lgi) var(--padding-22xl-9);
  box-sizing: border-box;
  position: relative;
  gap: 39.1px;
  background-image: url("../../../public/image-30@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.details-icon {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum5 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.frame-child5 {
  height: 13px;
  width: 73.2px;
  position: relative;
}

.reviews-4 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.banner-item-rating,
.frame-parent13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent13 {
  gap: var(--gap-3xs-2);
}

.banner-item-rating {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div5 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour5 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container5 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.buy-now6 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.buy-now5 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.banner-item-action,
.button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.banner-item-action {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.heart-icon {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum6 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.rating-icon-child {
  height: 13px;
  width: 73.2px;
  position: relative;
}

.reviews-41 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.rating-icon,
.rating1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.rating-icon {
  gap: var(--gap-3xs-2);
}

.rating1 {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div6 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour6 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container6 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.price-info,
.price1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.price1 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.price-info {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now7 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.button-content {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.banner-item-details,
.buy,
.product-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.buy {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.banner-item-details,
.product-info {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.banner-item-details {
  flex-direction: row;
  gap: var(--gap-mini);
  min-width: 361px;
  max-width: 100%;
}

.favorite-icon {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum7 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.description1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.reviews-42,
.stars-child {
  position: relative;
  flex-shrink: 0;
}

.stars-child {
  height: 13px;
  width: 73.2px;
}

.reviews-42 {
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.stars,
.stars-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.stars {
  gap: var(--gap-3xs-2);
}

.stars-wrapper {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div7 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour7 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container7 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.price-details,
.value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.price-details {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.value {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now8 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.add-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.heart,
.order {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.order {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.heart {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.wishlist-icon {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum8 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.product-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.reviews-43,
.stars-and-reviews-child {
  position: relative;
  flex-shrink: 0;
}

.stars-and-reviews-child {
  height: 13px;
  width: 73.2px;
}

.reviews-43 {
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.evaluation,
.stars-and-reviews {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.stars-and-reviews {
  gap: var(--gap-3xs-2);
}

.evaluation {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div8 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour8 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container8 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.cost-details,
.pricing {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.cost-details {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.pricing {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now9 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.button-elements {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.product,
.purchase {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.purchase {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.product {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.details {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-mini);
  min-width: 361px;
}

.banner-item,
.banner-item-category-parent,
.banner-item-content,
.details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.banner-item-content {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-mini);
}

.banner-item,
.banner-item-category-parent {
  align-self: stretch;
}

.banner-item {
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}

.banner-item-category-parent {
  flex-direction: column;
  gap: var(--gap-21xl-9);
}

.electronics1,
.group-icon15 {
  position: relative;
  flex-shrink: 0;
}

.electronics1 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.group-icon15 {
  align-self: stretch;
  height: 14.8px;
  max-width: 100%;
  overflow: hidden;
}

.group-wrapper {
  width: 103.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base-6) 0 0;
  box-sizing: border-box;
}

.electronics-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.image-30-icon1 {
  width: 270px;
  height: 307.5px;
  position: relative;
  object-fit: cover;
  display: none;
  z-index: 0;
}

.image-311 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-300);
  z-index: 1;
}

.frame-child6 {
  height: 45px;
  width: 156px;
  position: relative;
  border-radius: var(--br-3xl-5);
  background-color: var(--color-mediumseagreen-100);
  display: none;
}

.upto-20-off1 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 130%;
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
  min-width: 114px;
  z-index: 3;
}

.frame-button,
.frame-wrapper13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.frame-button {
  cursor: pointer;
  border: 0;
  padding: 12.7px var(--padding-2xl) 11.9px;
  background-color: var(--color-mediumseagreen-100);
  border-radius: var(--br-3xl-5);
  justify-content: flex-start;
  white-space: nowrap;
  z-index: 2;
}

.frame-wrapper13 {
  justify-content: flex-end;
  padding: 0 var(--padding-19xl);
}

.food-store1,
.name1 {
  margin: 0;
}

.food-store-name-container1 {
  position: relative;
  line-height: 130%;
  z-index: 2;
}

.food-store-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-42xl) 0 var(--padding-43xl);
}

.see-all-products1 {
  align-self: stretch;
  height: 23.3px;
  position: relative;
  font-size: var(--font-size-smi);
  text-decoration: underline;
  line-height: 115%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 2;
}

.image-30-group {
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 66.1px var(--padding-lgi) var(--padding-22xl-9);
  box-sizing: border-box;
  position: relative;
  gap: 40.3px;
  background-image: url("../../../public/image-30@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.vector-icon19 {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum9 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.lorem-ipsum-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.frame-child7 {
  height: 13px;
  width: 73.2px;
  position: relative;
}

.reviews-44 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.frame-parent17,
.frame-wrapper14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent17 {
  gap: var(--gap-3xs-2);
}

.frame-wrapper14 {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div9 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour9 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container9 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.frame-wrapper15,
.parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.parent4 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-wrapper15 {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now10 {
  position: absolute;
  top: 6.9px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon20 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-parent1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.frame-wrapper16,
.vector-parent5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper16 {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.vector-parent5 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.vector-icon21 {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum10 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.lorem-ipsum-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.frame-child8 {
  height: 13px;
  width: 73.2px;
  position: relative;
}

.reviews-45 {
  position: relative;
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.frame-parent18,
.frame-wrapper17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent18 {
  gap: var(--gap-3xs-2);
}

.frame-wrapper17 {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div10 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour10 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container10 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.frame-wrapper18,
.parent5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.parent5 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-wrapper18 {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now11 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon22 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-parent2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.frame-parent16,
.frame-wrapper19,
.vector-parent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper19 {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.frame-parent16,
.vector-parent6 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.frame-parent16 {
  flex-direction: row;
  gap: var(--gap-mini);
  min-width: 361px;
  max-width: 100%;
}

.vector-icon23 {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum11 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.lorem-ipsum-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.frame-child9,
.reviews-46 {
  position: relative;
  flex-shrink: 0;
}

.frame-child9 {
  height: 13px;
  width: 73.2px;
}

.reviews-46 {
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.frame-parent20,
.frame-wrapper20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent20 {
  gap: var(--gap-3xs-2);
}

.frame-wrapper20 {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div11 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour11 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container11 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.frame-wrapper21,
.parent6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.parent6 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-wrapper21 {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now12 {
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon24 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-parent3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.frame-wrapper22,
.vector-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper22 {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.vector-parent7 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.vector-icon25 {
  align-self: stretch;
  height: 182.9px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.lorem-ipsum12 {
  position: relative;
  display: inline-block;
  min-width: 122px;
}

.lorem-ipsum-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}

.frame-child10,
.reviews-47 {
  position: relative;
  flex-shrink: 0;
}

.frame-child10 {
  height: 13px;
  width: 73.2px;
}

.reviews-47 {
  line-height: 14px;
  display: inline-block;
  min-width: 70px;
}

.frame-parent21,
.frame-wrapper23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent21 {
  gap: var(--gap-3xs-2);
}

.frame-wrapper23 {
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-5);
  font-family: var(--font-poppins);
}

.div12 {
  position: relative;
  display: inline-block;
  min-width: 36px;
  white-space: nowrap;
}

.hour12 {
  color: var(--color-mediumseagreen-200);
}

.delivery-in-1-container12 {
  position: relative;
  text-align: right;
  color: var(--color-dimgray);
}

.frame-wrapper24,
.parent7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.parent7 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-wrapper24 {
  width: 247.3px;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-seagreen-100);
}

.buy-now13 {
  text-decoration: none;
  position: absolute;
  top: 6.8px;
  left: 26px;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  color: var(--color-gray-200);
  text-align: center;
  display: inline-block;
  width: 68px;
  height: 16.5px;
  min-width: 68px;
}

.vector-icon26 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buy-now-parent4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 30.5px;
  flex: 1;
  position: relative;
}

.frame-wrapper25,
.vector-parent8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-wrapper25 {
  width: 123.5px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}

.vector-parent8 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-4);
  min-width: 175px;
}

.frame-parent19 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-mini);
  min-width: 361px;
}

.frame-parent14,
.frame-parent15,
.frame-parent19,
.frame-wrapper12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.frame-parent15 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-mini);
}

.frame-parent14,
.frame-wrapper12 {
  align-self: stretch;
}

.frame-wrapper12 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}

.frame-parent14 {
  flex-direction: column;
  gap: var(--gap-21xl-9);
}

.banner,
.banner-content,
.banner-items-row {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.banner-items-row {
  width: 1431px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  gap: 55.4px;
}

.banner,
.banner-content {
  flex-direction: row;
}

.banner-content {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 200.4px;
}

.banner {
  width: 1686px;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 0 0;
  position: relative;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-black);
  font-family: var(--font-inter);
}

.frame-child11 {
  position: absolute;
  width: 47.42%;
  top: 21.99%;
  right: 26.52%;
  left: 26.05%;
  max-width: 100%;
  overflow: hidden;
  height: 0;
}

.msi-gs-series {
  margin: 0;
  color: var(--color-white);
}

.msi-ge-series,
.msi-gl-series,
.msi-gt-series {
  margin: 0;
}

.msi-gs-series-container1 {
  width: 100%;
}

.msi-gs-series-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 180%;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.group-div {
  width: 233px;
  height: 122.8px;
  position: relative;
  display: none;
}

.frame-child12 {
  position: absolute;
  width: 64.55%;
  top: 21.99%;
  right: 17.51%;
  left: 17.94%;
  max-width: 100%;
  overflow: hidden;
  height: 0;
}

.msi-infinute-series {
  margin: 0;
  color: var(--color-white);
}

.msi-nightblade,
.msi-triden {
  margin: 0;
}

.msi-infinute-series-container1 {
  width: 100%;
}

.msi-infinute-series-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 180%;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.vector-parent9 {
  width: 233px;
  height: 122.8px;
  position: relative;
  display: none;
}

.ondc {
  color: var(--color-mediumseagreen-100);
}

.lorem-ipsum-is-simply {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  z-index: 2;
}

.frame-parent23 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm-6);
  flex-shrink: 0;
}

.vector-icon27 {
  height: 52.6px;
  width: 216.5px;
  position: relative;
  border-radius: var(--br-xl);
  display: none;
}

.frame-wrapper26,
.vector-parent10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.vector-parent10 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base-6) var(--padding-19xl);
  background-color: var(--color-mediumseagreen-100);
  border-radius: var(--br-xl);
  justify-content: flex-start;
  white-space: nowrap;
  z-index: 2;
}

.frame-wrapper26 {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-9xl);
}

.frame-parent22 {
  width: 999px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26.3px;
  max-width: 100%;
}

.v1-website-flow-child {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-8xl) 190.7px var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.copyright-2020,
.frame-child13 {
  position: absolute;
  height: 100%;
  top: 0;
}

.frame-child13 {
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
}

.copyright-2020 {
  width: 15.16%;
  left: 84.84%;
  line-height: 132.5%;
  font-weight: 500;
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.ant-designfacebook-filled-icon,
.ant-designinstagram-filled-icon {
  position: absolute;
  height: 39.29%;
  width: 1.57%;
  top: 30.36%;
  right: 98.43%;
  bottom: 30.36%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.ant-designinstagram-filled-icon {
  right: 96.07%;
  left: 2.36%;
}

.copyright-2020-shop-pty-ltd-parent {
  position: absolute;
  height: 10.77%;
  width: 72.81%;
  top: 89.23%;
  right: 13.59%;
  bottom: 0;
  left: 13.59%;
  text-align: right;
  font-size: var(--font-size-xs);
}

.sign-up-to {
  position: absolute;
  width: 36.14%;
  top: 0;
  left: 0;
  font-size: var(--font-size-19xl);
  line-height: 132.5%;
  font-weight: 500;
  text-align: left;
  display: inline-block;
}

.frame-child14 {
  position: absolute;
  height: 62.5%;
  width: 27.93%;
  top: 31.25%;
  right: 12.43%;
  bottom: 6.25%;
  left: 59.64%;
  border-radius: var(--br-9xs);
  background-color: var(--color-black);
  border: 2px solid var(--color-white);
  box-sizing: border-box;
}

.be-the-first,
.your-email {
  position: absolute;
  font-weight: 300;
}

.be-the-first {
  top: 73.75%;
  left: 0.14%;
  font-size: var(--font-size-base);
  line-height: 132.5%;
}

.your-email {
  height: 62.5%;
  width: 7%;
  top: 31.25%;
  left: 59.64%;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-child15,
.subscribe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.frame-child15 {
  right: 0;
  bottom: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-3);
}

.subscribe {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle-parent4 {
  position: absolute;
  height: 62.5%;
  width: 10.79%;
  top: 31.25%;
  right: 0;
  bottom: 6.25%;
  left: 89.21%;
}

.sign-up-to-our-newsletter-parent {
  position: absolute;
  height: 15.38%;
  width: 72.92%;
  top: 9.04%;
  right: 13.59%;
  bottom: 75.58%;
  left: 13.49%;
  text-align: center;
}

.information1 {
  line-height: 100%;
}

.information {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.blank-line1 {
  line-height: 100%;
}

.blank-line {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-white);
}

.about-us1 {
  line-height: 140%;
}

.about-us {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.about-zip1 {
  line-height: 140%;
}

.about-zip {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.privacy-policy1 {
  line-height: 140%;
}

.privacy-policy {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.search1 {
  line-height: 140%;
}

.search {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.terms1 {
  line-height: 140%;
}

.terms {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.orders-and-returns1 {
  line-height: 140%;
}

.orders-and-returns {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.contact-us2 {
  line-height: 140%;
}

.contact-us1 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.advanced-search1 {
  line-height: 140%;
}

.advanced-search {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.newsletter-subscription1 {
  line-height: 140%;
}

.newsletter-subscription {
  margin: 0;
}

.information-about-us-container1 {
  width: 100%;
}

.information-about-us-container {
  position: absolute;
  width: 8.75%;
  top: 33.08%;
  left: 13.59%;
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
}

.category1 {
  line-height: 100%;
}

.category {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.blank-line3 {
  line-height: 100%;
}

.blank-line2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-white);
}

.electronics3 {
  line-height: 140%;
}

.electronics2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.food3 {
  line-height: 140%;
}

.food2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.cosmetic1 {
  line-height: 140%;
}

.cosmetic {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.tablets-and-pads1 {
  line-height: 140%;
}

.tablets-and-pads {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.netbooks1 {
  line-height: 140%;
}

.netbooks {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.infinity-gaming-notebooks1 {
  line-height: 140%;
}

.infinity-gaming-notebooks {
  margin: 0;
}

.category-electronics-food-container1 {
  width: 100%;
}

.category-electronics-food-container {
  position: absolute;
  width: 9.79%;
  top: 32.5%;
  left: 53.59%;
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
}

.address1 {
  line-height: 100%;
}

.address {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-gray-500);
}

.blank-line5 {
  line-height: 100%;
}

.blank-line4 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.address-1234-street-adress-ci1 {
  line-height: 140%;
}

.address-1234-street-adress-ci {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.span {
  color: var(--color-cornflowerblue);
}

.phones-00-1234-56781 {
  line-height: 140%;
}

.phones-00-1234-5678 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.we-are-open-monday-thursday1 {
  line-height: 140%;
}

.we-are-open-monday-thursday {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.friday-900-am-600-pm1 {
  line-height: 140%;
}

.friday-900-am-600-pm {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.saturday-1100-am-500-pm1 {
  line-height: 140%;
}

.saturday-1100-am-500-pm {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.shopemailcom {
  color: var(--color-cornflowerblue);
}

.e-mail-shopemailcom1 {
  line-height: 140%;
}

.e-mail-shopemailcom {
  margin: 0;
}

.address-address-1234-container1 {
  width: 100%;
}

.address-address-1234-container {
  position: absolute;
  width: 15.68%;
  top: 32.31%;
  left: 67.45%;
  display: flex;
  align-items: center;
}

.useful-links1 {
  line-height: 100%;
}

.useful-links {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.blank-line7 {
  line-height: 100%;
}

.blank-line6 {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-white);
}

.option-link-011 {
  line-height: 132.5%;
}

.option-link-01 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-013 {
  line-height: 132.5%;
}

.option-link-012 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-015 {
  line-height: 132.5%;
}

.option-link-014 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-017 {
  line-height: 132.5%;
}

.option-link-016 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-019 {
  line-height: 132.5%;
}

.option-link-018 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0111 {
  line-height: 132.5%;
}

.option-link-0110 {
  margin: 0;
}

.useful-links-option-container1 {
  width: 100%;
}

.useful-links-option-container {
  position: absolute;
  width: 7.08%;
  top: 31.92%;
  left: 42.45%;
  display: flex;
  align-items: center;
  color: var(--color-gray-500);
}

.links1 {
  line-height: 100%;
}

.links {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

.blank-line9 {
  line-height: 100%;
}

.blank-line8 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0112,
.option-link-0113,
.option-link-0114,
.option-link-0115,
.option-link-0116,
.option-link-0117,
.option-link-0118,
.option-link-0119,
.option-link-0120 {
  margin-block-start: 0;
  margin-block-end: 4px;
  line-height: 140%;
}

.option-link-0121 {
  margin: 0;
  line-height: 140%;
}

.links-option-link-container1 {
  width: 100%;
}

.links-option-link-container {
  position: absolute;
  width: 11.98%;
  top: 33.08%;
  left: 26.41%;
  display: flex;
  align-items: center;
  font-size: var(--font-size-smi);
}

.rectangle-parent3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.payment-methodslightpaypal-icon,
.payment-methodslightvisa-icon {
  position: absolute;
  height: 100%;
  width: 16%;
  top: 0;
  right: 62.85%;
  bottom: 0;
  left: 21.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.payment-methodslightpaypal-icon {
  width: 16.39%;
  right: 83.61%;
  left: 0;
}

.payment-methodslightamerican-icon,
.payment-methodslightdiscover-icon,
.payment-methodslightmaestro-icon {
  position: absolute;
  height: 100%;
  width: 16%;
  top: 0;
  right: 42.05%;
  bottom: 0;
  left: 41.95%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.payment-methodslightamerican-icon,
.payment-methodslightdiscover-icon {
  width: 16.39%;
  right: 20.81%;
  left: 62.81%;
}

.payment-methodslightamerican-icon {
  width: 16%;
  right: 0.05%;
  left: 83.95%;
}

.instance-child,
.payment-methodslightvisa-parent {
  position: absolute;
  height: 4.23%;
  width: 10.84%;
  top: 92.5%;
  right: 44.58%;
  bottom: 3.27%;
  left: 44.58%;
}

.instance-child {
  height: 0.19%;
  width: 72.81%;
  top: 89.04%;
  right: 13.59%;
  bottom: 10.77%;
  left: 13.59%;
  background-color: var(--color-white);
  opacity: 0.2;
}

.frame-parent24 {
  position: absolute;
  top: 830px;
  left: 0;
  width: 100%;
  height: 520px;
  color: var(--color-white);
}

.image-33-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-wrapper {
  position: absolute;
  top: 0;
  left: 261px;
  background-color: var(--color-white);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.image-33-icon1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-container {
  position: absolute;
  top: 0;
  left: 460px;
  background-color: var(--color-white);
  width: 202px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-6xl);
  box-sizing: border-box;
}

.image-33-icon2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-frame {
  position: absolute;
  top: 0;
  left: 661px;
  background-color: var(--color-white);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.image-33-icon3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-wrapper1 {
  position: absolute;
  top: 0;
  left: 860px;
  background-color: var(--color-white);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.image-33-icon4 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-wrapper2 {
  position: absolute;
  top: 0;
  left: 1059px;
  background-color: var(--color-white);
  width: 201px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.image-33-icon5 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.image-33-wrapper3 {
  position: absolute;
  top: 0;
  left: 1259px;
  background-color: var(--color-white);
  width: 201px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.image-33-icon6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.component-16-child,
.image-33-wrapper4 {
  position: absolute;
  top: 0;
  background-color: var(--color-white);
}

.image-33-wrapper4 {
  left: 1459px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
}

.component-16-child {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
}

.account-icon,
.saving-icon,
.support-icon {
  width: 65px;
  height: 65px;
  position: relative;
}

.account-icon,
.saving-icon {
  display: none;
}

.product-support,
.up-to-3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-support {
  height: 24px;
  line-height: 132.5%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}

.up-to-3 {
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-sm);
  line-height: 140%;
  opacity: 0.7;
}

.product-support-parent,
.support-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.product-support-parent {
  align-self: stretch;
  flex: 1;
  gap: var(--gap-smi);
}

.support-parent {
  position: absolute;
  width: 25.12%;
  top: calc(50% - 83px);
  right: 74.88%;
  left: 0;
  gap: var(--gap-5xl);
}

.support-icon1 {
  display: none;
}

.account-icon1,
.saving-icon1,
.support-icon1 {
  width: 65px;
  height: 65px;
  position: relative;
}

.saving-icon1 {
  display: none;
}

.personal-account,
.with-big-discounts {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal-account {
  height: 24px;
  line-height: 132.5%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}

.with-big-discounts {
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-sm);
  line-height: 140%;
  opacity: 0.7;
}

.personal-account-parent,
.support-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.personal-account-parent {
  align-self: stretch;
  flex: 1;
  gap: var(--gap-smi);
}

.support-group {
  position: absolute;
  width: 25.12%;
  top: calc(50% - 83px);
  right: 37.44%;
  left: 37.44%;
  gap: var(--gap-5xl);
}

.account-icon2,
.support-icon2 {
  display: none;
}

.account-icon2,
.saving-icon2,
.support-icon2 {
  width: 65px;
  height: 65px;
  position: relative;
}

.amazing-savings,
.up-to-70 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amazing-savings {
  height: 24px;
  line-height: 132.5%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}

.up-to-70 {
  align-self: stretch;
  flex: 1;
  font-size: var(--font-size-sm);
  line-height: 140%;
  opacity: 0.7;
}

.amazing-savings-parent,
.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.amazing-savings-parent {
  align-self: stretch;
  flex: 1;
  gap: var(--gap-smi);
}

.support-container {
  position: absolute;
  width: 25.12%;
  top: calc(50% - 83px);
  right: 0;
  left: 74.88%;
  gap: var(--gap-5xl);
}

.instance-group {
  position: absolute;
  width: 54.95%;
  top: calc(50% - 75.3px);
  right: 22.5%;
  left: 22.55%;
  height: 166px;
}

.component-16,
.instance-item {
  position: absolute;
  width: 100%;
  left: 0;
}

.component-16 {
  top: 533px;
  height: 273px;
  text-align: center;
  font-size: var(--font-size-lg);
}

.instance-item {
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-1);
}

.my-first-order {
  position: absolute;
  height: 53.17%;
  width: 92.19%;
  top: 0;
  left: 7.81%;
  font-size: var(--font-size-lg);
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.div13 {
  position: absolute;
  height: 33.66%;
  width: 4.99%;
  top: 0;
  left: 0;
  font-size: var(--font-size-77xl);
}

.div13,
.leave-us-a,
.tama-brown {
  display: flex;
  align-items: center;
}

.tama-brown {
  position: absolute;
  height: 10.24%;
  width: 100%;
  top: 53.9%;
  left: 0;
  text-align: right;
}

.leave-us-a {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  justify-content: center;
}

.leave-us-a-review-wrapper {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% - 461px);
  border-radius: var(--br-31xl);
  border: 2px solid var(--color-3);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px var(--padding-5xl);
}

.frame-child16 {
  position: absolute;
  height: 32.43%;
  width: 8.46%;
  top: 32.43%;
  right: 0;
  bottom: 35.14%;
  left: 91.54%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.instance-container {
  position: absolute;
  height: 18.05%;
  top: 81.95%;
  bottom: 0;
  left: calc(50% - 461px);
  width: 100%;
  text-align: center;
  color: var(--color-3);
}

.my-first-order-arrived-today-i-parent {
  position: absolute;
  height: 66.13%;
  width: 79.48%;
  top: 15.81%;
  right: 10.26%;
  bottom: 18.06%;
  left: 10.26%;
}

.rectangle-parent5 {
  position: absolute;
  top: 223px;
  left: 380px;
  width: 1160px;
  height: 310px;
}

.instance-parent {
  align-self: stretch;
  height: 1350px;
  position: relative;
  display: none;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
}

.frame-input {
  margin: 0;
  align-self: stretch;
  height: 38.9px;
}

.instant-delivery {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.instant-delivery-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-4xl);
}

.lorem-ipsum-dolor {
  margin: 0;
  position: relative;
}

.sed-diam-nonummy {
  position: relative;
}

.sed-diam-nonummy-nibh-euis-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-4xl);
}

.lorem-ipsum-dolor-sit-amet-co-parent {
  align-self: stretch;
  gap: var(--gap-12xs-2);
  font-size: var(--font-size-base);
  color: var(--color-darkgray-200);
}

.frame-parent27,
.frame-parent28,
.lorem-ipsum-dolor-sit-amet-co-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent28 {
  align-self: stretch;
  gap: var(--gap-base-9);
}

.frame-parent27 {
  flex: 1;
  gap: var(--gap-8xl-6);
  min-width: 320px;
  max-width: 100%;
}

.frame-input1 {
  margin: 0;
  align-self: stretch;
  height: 38.9px;
}

.verified-by-ondc {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.verified-by-ondc-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
}

.lorem-ipsum-dolor1 {
  margin: 0;
  position: relative;
}

.sed-diam-nonummy1 {
  position: relative;
}

.sed-diam-nonummy-nibh-euis-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-4xl);
}

.lorem-ipsum-dolor-sit-amet-co-group {
  align-self: stretch;
  gap: var(--gap-12xs-2);
  font-size: var(--font-size-base);
  color: var(--color-darkgray-200);
}

.frame-parent29,
.frame-parent30,
.lorem-ipsum-dolor-sit-amet-co-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent30 {
  align-self: stretch;
  gap: var(--gap-base-9);
}

.frame-parent29 {
  flex: 1;
  gap: var(--gap-8xl-6);
  min-width: 320px;
  max-width: 100%;
}

.frame-input2 {
  margin: 0;
  align-self: stretch;
  height: 38.9px;
}

.assurance {
  position: relative;
}

.assurance-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
}

.lorem-ipsum-dolor2 {
  margin: 0;
  position: relative;
}

.sed-diam-nonummy2 {
  position: relative;
}

.sed-diam-nonummy-nibh-euis-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-4xl);
}

.lorem-ipsum-dolor-sit-amet-co-container {
  align-self: stretch;
  gap: var(--gap-12xs-2);
  font-size: var(--font-size-base);
  color: var(--color-darkgray-200);
}

.frame-parent31,
.frame-parent32,
.lorem-ipsum-dolor-sit-amet-co-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent32 {
  align-self: stretch;
  gap: var(--gap-base-9);
}

.frame-parent31 {
  flex: 1;
  gap: var(--gap-8xl-6);
  min-width: 320px;
  max-width: 100%;
}

.frame-parent26,
.image-33-icon7 {
  align-self: stretch;
  max-width: 100%;
}

.frame-parent26 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 63.5px;
}

.image-33-icon7 {
  height: 79.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper5 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 200px;
  flex-shrink: 0;
}

.image-33-icon8 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper6 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-6xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 202px;
  flex-shrink: 0;

  z-index: 1;
  margin-left: -1px;
}

.image-33-icon9 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper7 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 200px;
  flex-shrink: 0;

  z-index: 2;
  margin-left: -1px;
}

.image-33-icon10 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper8 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 200px;
  flex-shrink: 0;

  z-index: 3;
  margin-left: -1px;
}

.image-33-icon11 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper9 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 201px;
  flex-shrink: 0;

  z-index: 4;
  margin-left: -1px;
}

.image-33-icon12 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper10 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 201px;
  flex-shrink: 0;

  z-index: 5;
  margin-left: -1px;
}

.image-33-icon13 {
  align-self: stretch;
  height: 79.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.image-33-wrapper11 {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl) var(--padding-5xl);
  box-sizing: border-box;
  min-width: 198px;
  max-width: 200px;
  flex-shrink: 0;

  z-index: 6;
  margin-left: -1px;
}

.instance-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  row-gap: 20px;
}

.frame-child17 {
  width: 1160px;
  height: 310px;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  display: none;
  max-width: 100%;
}

.h1 {
  margin: 0;
  width: 46px;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.my-first-order1,
.tama-brown1 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.my-first-order1 {
  margin: 0;
  white-space: pre-wrap;
}

.tama-brown1 {
  font-size: var(--font-size-sm);
}

.my-first-order-arrived-today-i-group,
.parent8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.my-first-order-arrived-today-i-group {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 552px;
  font-size: var(--font-size-lg);
}

.parent8 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 26px;
}

.leave-us-a1 {
  height: 21px;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leave-us-a-review-container {
  width: 182px;
  border-radius: var(--br-31xl);
  border: 2px solid var(--color-3);
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-7xl);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-3);
}

.frame-child18 {
  width: 78px;
  height: 12px;
  position: relative;
  z-index: 1;
}

.rectangle-parent6 {
  width: 1160px;
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 49px 119px 69px;
  box-sizing: border-box;
  gap: 36px;
  max-width: 100%;
  z-index: 2;
}

.frame-wrapper28 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}

.frame-parent33,
.frame-wrapper27,
.frame-wrapper28 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.frame-parent33 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 73.5px;
}

.frame-wrapper27 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-9xs) 0 var(--padding-7xs);
  box-sizing: border-box;
  font-size: var(--font-size-77xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

.frame-parent25 {
  width: 1408px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 114.8px;
  max-width: 100%;
}

.frame-section {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-200);
  font-family: var(--font-inter);
}

.component-16-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
}

.support-icon3 {
  height: 65px;
  width: 65px;
  position: relative;
}

.support-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.account-icon3,
.saving-icon3 {
  width: 65px;
  height: 65px;
  position: relative;
  display: none;
}

.product-support1 {
  position: relative;
  line-height: 24px;
  font-weight: 700;
}

.account-names {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-wrap: nowrap;
  padding: 0 57px;
}

.up-to-31 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 140%;
  color: var(--color-gray-400);
}

.account-types,
.frame-parent34 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-types {
  align-self: stretch;
  gap: var(--gap-smi);
}

.frame-parent34 {
  width: 265px;
  gap: var(--gap-5xl);
}

.support-icon4 {
  display: none;
}

.account-icon4,
.support-icon4 {
  height: 65px;
  width: 65px;
  position: relative;
}

.account-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.saving-icon4 {
  width: 65px;
  height: 65px;
  position: relative;
  display: none;
}

.personal-account1 {
  position: relative;
  line-height: 24px;
  font-weight: 700;
}

.personal-account-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 50px;
}

.with-big-discounts1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 140%;
  color: var(--color-gray-400);
}

.frame-parent35,
.support-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent35 {
  align-self: stretch;
  gap: var(--gap-smi);
}

.support-parent1 {
  width: 265px;
  gap: var(--gap-5xl);
}

.account-icon5,
.support-icon5 {
  display: none;
}

.account-icon5,
.saving-icon5,
.support-icon5 {
  height: 65px;
  width: 65px;
  position: relative;
}

.saving-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.amazing-savings1 {
  position: relative;
  line-height: 24px;
  font-weight: 700;
}

.amazing-savings-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 51px;
}

.up-to-701 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 140%;
  color: var(--color-gray-400);
}

.frame-parent36,
.support-parent2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-parent36 {
  align-self: stretch;
  gap: var(--gap-smi);
}

.support-parent2 {
  width: 265px;
  gap: var(--gap-5xl);
}

.component-16-wrapper,
.component-161,
.instance-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.instance-parent2 {
  width: 1055px;
  justify-content: space-between;
  gap: var(--gap-xl);
  z-index: 1;
}

.component-16-wrapper,
.component-161 {
  box-sizing: border-box;
}

.component-161 {
  flex: 1;
  justify-content: center;
  padding: 61.2px var(--padding-xl) 45.8px var(--padding-2xl);
  position: relative;
}

.component-16-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xl) var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

.newsletter-background {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
}

.be-the-first1 {
  position: relative;
  line-height: 132.5%;
  text-wrap: nowrap;
}

.be-the-first1 {
  font-weight: 300;
}

.your-email1 {
  height: 50px;
  width: 98px;
  position: absolute;
  margin: 0 !important;
  right: 467px;
  bottom: 5px;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.subscribe-background {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-mediumseagreen-100);
}

.subscribe1 {
  height: 50px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.subscribe-background-parent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 151px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.about-us3 {
  line-height: 140%;
}

.about-us2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.about-zip3 {
  line-height: 140%;
}

.about-zip2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.privacy-policy3 {
  line-height: 140%;
}

.privacy-policy2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.search3 {
  line-height: 140%;
}

.search2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.terms3 {
  line-height: 140%;
}

.terms2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.orders-and-returns3 {
  line-height: 140%;
}

.contact-us4 {
  line-height: 140%;
}

.contact-us3 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.advanced-search3 {
  line-height: 140%;
}

.advanced-search2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.newsletter-subscription3 {
  line-height: 140%;
}

.newsletter-subscription2 {
  margin: 0;
}

.links3 {
  line-height: 100%;
}

.links2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

.blank-line13 {
  line-height: 100%;
}

.blank-line12 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0122,
.option-link-0123,
.option-link-0124,
.option-link-0125,
.option-link-0126,
.option-link-0127,
.option-link-0128,
.option-link-0129,
.option-link-0130 {
  margin-block-start: 0;
  margin-block-end: 4px;
  line-height: 140%;
}

.option-link-0131 {
  margin: 0;
  line-height: 140%;
}

.useful-links4 {
  line-height: 100%;
}

.useful-links3 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.blank-line15 {
  line-height: 100%;
}

.blank-line14 {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-white);
}

.option-link-0133 {
  line-height: 132.5%;
}

.option-link-0132 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0135 {
  line-height: 132.5%;
}

.option-link-0134 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0137 {
  line-height: 132.5%;
}

.option-link-0136 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0139 {
  line-height: 132.5%;
}

.option-link-0138 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0141 {
  line-height: 132.5%;
}

.option-link-0140 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.option-link-0143 {
  line-height: 132.5%;
}

.option-link-0142 {
  margin: 0;
}

.useful-links-option-container2 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.search-location-wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.no-border {
  border: none;
  background: transparent;
}

.no-border:focus {
  outline: none;
}

.group-parent {
  display: flex;
  align-items: center;
}

.rectangle-group {
  display: flex;
  align-items: center;
  width: 100%;
  /* Ensure full width */
}

.frame-parent2 {
  display: flex;
  align-items: center;
  width: 100%;
  /* Ensure full width */
  padding-right: 80px;
}

.select-category:focus {
  outline: none;
}

.search-business-category {
  padding: 5px;
  border: none;
  background: transparent;
  width: 50%;
  /* Adjust width to take half the space */
}

.search-business-category:focus {
  outline: none;
}

.category3 {
  line-height: 100%;
}

.category2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.blank-line17 {
  line-height: 100%;
}

.blank-line16 {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-white);
}

.electronics5 {
  line-height: 140%;
}

.electronics4 {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.food5 {
  line-height: 140%;
}

.food4 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.cosmetic3 {
  line-height: 140%;
}

.cosmetic2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.tablets-and-pads3 {
  line-height: 140%;
}

.tablets-and-pads2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.netbooks3 {
  line-height: 140%;
}

.netbooks2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

@media screen and (max-width: 542px) {
  .be-the-first1 {
    text-wrap: wrap;
  }
}

.infinity-gaming-notebooks3 {
  line-height: 140%;
}

.infinity-gaming-notebooks2 {
  margin: 0;
}

.category-electronics-food-container2 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.product-categories {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.address3 {
  line-height: 100%;
}

.address2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  color: var(--color-gray-500);
}

.blank-line19 {
  line-height: 100%;
}

.blank-line18 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.address-1234-street-adress-ci3 {
  line-height: 140%;
}

.address-1234-street-adress-ci2 {
  margin-block-start: 0;
  margin-block-end: 4px;
  font-size: var(--font-size-smi);
}

.span1 {
  color: var(--color-mediumseagreen-100);
}

.phones-00-1234-56783 {
  line-height: 140%;
}

.phones-00-1234-56782 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.we-are-open-monday-thursday3 {
  line-height: 140%;
}

.we-are-open-monday-thursday2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.friday-900-am-600-pm3 {
  line-height: 140%;
}

.friday-900-am-600-pm2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.saturday-1100-am-500-pm3 {
  line-height: 140%;
}

.saturday-1100-am-500-pm2 {
  margin-block-start: 0;
  margin-block-end: 4px;
}

.shopemailcom1 {
  color: var(--color-mediumseagreen-100);
}

.e-mail-shopemailcom3 {
  line-height: 140%;
}

.e-mail-shopemailcom2 {
  margin: 0;
}

.address-address-1234-container2 {
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-white);
}

@media screen and (max-width: 675px) {
  .contact {
    margin-bottom: 40px;
  }
}

.payment-methodslightpaypal-icon1 {
  align-self: stretch;
  width: 34.1px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 22px;
}

.payment-methodslightamerican-icon1,
.payment-methodslightdiscover-icon1,
.payment-methodslightmaestro-icon1,
.payment-methodslightvisa-icon1 {
  align-self: stretch;
  width: 33.3px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 22px;
}

.payment-methodslightamerican-icon1,
.payment-methodslightdiscover-icon1 {
  width: 34.1px;
}

.payment-methodslightamerican-icon1 {
  width: 33.3px;
}

.v1-website-flow-item {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.v1-website-flow {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 1.8px;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-darkgray-100);
  font-family: var(--font-poppins);
}

@media screen and (max-width: 1350px) {
  .frame-group {
    flex-wrap: wrap;
  }
  .frame-parent {
    padding-bottom: 39px;
    box-sizing: border-box;
  }
  .banner-item-details,
  .details {
    flex: 1;
  }

  .banner-item-content {
    flex-wrap: wrap;
  }

  .frame-parent16,
  .frame-parent19 {
    flex: 1;
  }

  .frame-parent15 {
    flex-wrap: wrap;
  }

  .rectangle-parent6 {
    padding-left: var(--padding-40xl);
    padding-right: var(--padding-40xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1125px) {
  .store-items-row {
    justify-content: center;
    grid-template-columns: repeat(3, minmax(168px, 291px));
  }

  .banner-content {
    padding-bottom: 130px;
    box-sizing: border-box;
  }

  .my-first-order-arrived-today-i-group {
    min-width: 100%;
  }

  .instance-parent2 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .frame-group {
    gap: var(--gap-5xl);
  }

  .frame-parent5 {
    flex: 1;
  }
  .shop-by-category {
    font-size: var(--font-size-13xl);
  }
  .offer-row {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .best-deals-near {
    font-size: var(--font-size-13xl);
  }

  .deals-title {
    flex-wrap: wrap;
  }

  .deal-items-row {
    gap: var(--gap-4xl);
  }

  .deals-title-parent {
    gap: var(--gap-base);
  }

  .top-store-near {
    font-size: var(--font-size-13xl);
  }

  .stores-title {
    flex-wrap: wrap;
  }

  .store-items-row {
    grid-template-columns: repeat(2, minmax(168px, 291px));
  }

  .store-items {
    gap: var(--gap-9xl);
  }

  .stores {
    gap: 33px;
  }
  .frame-parent {
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
  .food1 {
    font-size: var(--font-size-13xl);
  }

  .banner-item-details,
  .details {
    flex-wrap: wrap;
  }

  .banner-item-category-parent {
    gap: var(--gap-xl);
  }

  .electronics1 {
    font-size: var(--font-size-13xl);
  }

  .frame-parent16,
  .frame-parent19 {
    flex-wrap: wrap;
  }

  .frame-parent14 {
    gap: var(--gap-xl);
  }

  .banner-items-row {
    gap: var(--gap-9xl);
  }

  .banner-content {
    padding-bottom: 84px;
    box-sizing: border-box;
  }

  .sell-on-ondc-container1 {
    font-size: var(--font-size-13xl);
  }

  .sign-up-to {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }

  .div13 {
    font-size: var(--font-size-29xl);
  }

  .frame-parent26 {
    gap: var(--gap-13xl);
  }

  .h1 {
    font-size: var(--font-size-29xl);
  }

  .rectangle-parent6 {
    gap: var(--gap-lg);
    padding-left: var(--padding-10xl);
    padding-right: var(--padding-10xl);
    box-sizing: border-box;
  }

  .frame-parent33 {
    gap: 37px;
  }

  .frame-parent25 {
    gap: var(--gap-38xl);
  }

  .email-box {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 450px) {
  .visit-our-showroom-in-1234-str-parent {
    display: none;
  }

  .frame-parent2 {
    flex-wrap: wrap;
    gap: var(--gap-5xl);
  }

  .rectangle-container {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .shop-by-category {
    font-size: var(--font-size-5xl);
  }

  .product-details,
  .rectangle-parent1,
  .rectangle-parent2 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .container-group {
    gap: var(--gap-lgi);
  }

  .electronics,
  .fashion12,
  .food,
  .furniture,
  .grocery,
  .jewellery,
  .pharma {
    font-size: var(--font-size-base);
  }

  .best-deals-near {
    font-size: var(--font-size-5xl);
  }

  .lorem-ipsum,
  .lorem-ipsum1,
  .lorem-ipsum2,
  .lorem-ipsum3,
  .lorem-ipsum4 {
    font-size: var(--font-size-base);
  }

  .top-store-near {
    font-size: var(--font-size-5xl);
  }

  .store-items-row {
    grid-template-columns: minmax(168px, 1fr);
  }

  .stores {
    gap: var(--gap-mid);
  }

  .food1 {
    font-size: var(--font-size-5xl);
  }

  .banner-item-category {
    flex-wrap: wrap;
  }

  .food-store-name-container {
    font-size: var(--font-size-base);
    line-height: 21px;
  }

  .image-30-parent {
    gap: var(--gap-xl);
  }

  .lorem-ipsum5,
  .lorem-ipsum6 {
    font-size: var(--font-size-base);
  }

  .banner-item-details {
    min-width: 100%;
  }

  .lorem-ipsum7,
  .lorem-ipsum8 {
    font-size: var(--font-size-base);
  }

  .details {
    min-width: 100%;
  }

  .electronics1 {
    font-size: var(--font-size-5xl);
  }

  .electronics-parent {
    flex-wrap: wrap;
  }

  .food-store-name-container1 {
    font-size: var(--font-size-base);
    line-height: 21px;
  }

  .image-30-group {
    gap: var(--gap-xl);
  }

  .lorem-ipsum10,
  .lorem-ipsum9 {
    font-size: var(--font-size-base);
  }

  .frame-parent16 {
    min-width: 100%;
  }

  .lorem-ipsum11,
  .lorem-ipsum12 {
    font-size: var(--font-size-base);
  }

  .frame-parent19 {
    min-width: 100%;
  }

  .sell-on-ondc-container1 {
    font-size: var(--font-size-5xl);
  }

  .v1-website-flow-child {
    padding-bottom: 124px;
    box-sizing: border-box;
  }

  .sign-up-to {
    font-size: var(--font-size-4xl);
    line-height: 30px;
  }

  .div13 {
    font-size: var(--font-size-10xl);
  }

  .assurance,
  .instant-delivery,
  .verified-by-ondc {
    font-size: var(--font-size-lgi);
  }

  .frame-parent26 {
    gap: var(--gap-base);
  }

  .h1 {
    font-size: var(--font-size-10xl);
  }

  .frame-parent33 {
    gap: var(--gap-lg);
  }

  .frame-parent25 {
    gap: var(--gap-10xl);
  }

  .account-names,
  .amazing-savings-wrapper,
  .personal-account-wrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
.frame-parent {
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 93px;
  gap: 16.3px;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray);
  font-family: var(--font-inter);
}
@media screen and (max-width: 1350px) {
  .frame-parent {
    padding-bottom: 39px;
  }
}
@media screen and (max-width: 800px) {
  .frame-parent {
    padding-bottom: var(--padding-6xl);
  }
}

.newsletter-background {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
}

.component-16-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  box-sizing: border-box;
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xl) var(--padding-12xs);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-poppins);
}

@media screen and (max-width: 1125px) {
  .component-16-wrapper {
    flex-direction: column;
    padding: var(--padding-6xl) var(--padding-12xs);
  }

  .instance-parent2 {
    flex-wrap: wrap;
  }
}

.be-the-first1 {
  font-weight: 300;
  position: relative;
  line-height: 132.5%;
}

.component-161 {
  flex: 1;
  margin: auto;
  justify-content: center;
  padding: 61.2px var(--padding-xl) 45.8px var(--padding-2xl);
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  font-family: var(--font-poppins);
}

.your-email1 {
  height: 50px;
  width: 98px;
  position: absolute;
  margin: 0 !important;
  right: 467px;
  bottom: 5px;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.component-16-item {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
}

.instance-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  width: 1055px;
  justify-content: space-between;
  gap: 60px;
  z-index: 1;
}

@media screen and (max-width: 1125px) {
  .instance-parent2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.frame-parent34,
.frame-parent35,
.frame-parent36 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 265;
  gap: var(--gap-5xl);
  text-align: center;
  /* Center text within each section */
}

.support-wrapper,
.saving-wrapper,
.account-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.support-icon3,
.support-icon4,
.support-icon5,
.account-icon3,
.account-icon4,
.account-icon5,
.saving-icon3,
.saving-icon4,
.saving-icon5 {
  height: 65px;
  width: 65px;
  position: relative;
}
.account-types,
.frame-parent35,
.frame-parent36 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: var(--gap-smi);
  text-align: center;
  /* Center text within each section */
}

.account-names,
.personal-account-wrapper,
.amazing-savings-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 57px;
  text-align: center;
  /* Center text within each section */
}

.product-support1,
.personal-account1,
.amazing-savings1 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 132.5%; /* 23.85px */
  position: relative;
  line-height: 24px;
}

.up-to-31,
.with-big-discounts1,
.up-to-701 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 140%;
  color: var(--color-gray-400);
}

.root {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .account-names,
  .amazing-savings-wrapper,
  .personal-account-wrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
