$headline-font-size: 32px;
$content-font-size: 20px;
$primary-color: #0056d2;
$secondary-color: #f5f5f5;
$text-color: #333;
$border-radius: 8px;
$padding: 16px;
$gap: 24px;

$ondc-primary-1: #195a9a;
$ondc-primary-2: #0389e9;
$ondc-primary-3: #e9f6ff;

$ondc-secondary-1: #04836d;
$ondc-secondary-2: #1cbfa2;
$ondc-secondary-3: #1fd6b6;

$ondc-accent-1: #e32028;
$ondc-accent-2: #f05a60;
$ondc-accent-3: #ffb1b1;

$ondc-tagline: #2a2a2d;
$ondc-text-900: #61636e;
$ondc-text-700: #898c99;
$ondc-text-500: #a6a9b9;
$ondc-text-300: #bdc0d0;
$ondc-text-100: #d9dced;
$ondc-button-text: #fff;

$z-index-button: 1;


// @font-face {
//   font-family: Montserrat;
//   font-weight: 500;
//   src: url(../fonts/ondc/Montserrat-Medium.ttf);
// }
// @font-face {
//   font-family: Montserrat;
//   font-weight: 700;
//   src: url(../fonts/ondc/Montserrat-Bold.ttf);
// }
// @font-face {
//   font-family: Raleway;
//   font-weight: 500;
//   src: url(../fonts/ondc/Raleway-Medium.ttf);
// }
// @font-face {
//   font-family: Raleway;
//   font-weight: 600;
//   src: url(../fonts/ondc/Raleway-SemiBold.ttf);
// }
// @font-face {
//   font-family: Raleway;
//   font-weight: 700;
//   src: url(../fonts/ondc/Raleway-Bold.ttf);
// }
// @font-face {
//   font-family: Lato;
//   font-weight: 400;
//   src: url(../fonts/ondc/Lato-Regular.ttf);
// }
// @font-face {
//   font-family: Lato;
//   font-weight: 600;
//   src: url(../fonts/ondc/Lato-Bold.ttf);
// }
// @font-face {
//   font-family: "Open Sans";
//   font-weight: 400;
//   src: url(../fonts/ondc/OpenSans-Regular.ttf);
// }
// @font-face {
//   font-family: "Open Sans";
//   font-weight: 600;
//   src: url(../fonts/ondc/OpenSans-SemiBold.ttf);
// }

/* Common CSS starts*/

// html {
//     scroll-behavior: smooth;
//     scroll-padding-top: 100px;
  
//     @media only screen and (max-width: 768px) {
//       scroll-padding-top: 60px;
//     }
//   }
  
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     //   font-family: Lato;
//   }
  
.about-us {
      scroll-padding-top: 100px;
  section {
    max-width: 1440px;
    margin: auto;
    margin-bottom: 80px;
    padding-inline: 80px;
  
    @media only screen and (max-width: 768px) {
        section {
            margin-bottom: 50px;
          }
    }
  }
  
  .headline {
    color: $ondc-primary-1;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 25px;
  
    @media only screen and (max-width: 768px) {
        .headline {
          font-size: 26px;
          margin-bottom: 25px;
        }
      }
  }
  
  @media only screen and (max-width: 768px) {
    .content .desc-img {
      margin-bottom: 15px;
    }
  }

  .content-text {
    color: $ondc-text-900;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    word-spacing: 1px;
  
    @media only screen and (max-width: 768px) {
      line-height: 20px;
      font-size: 16px;
    }
  }
  
  .ondc-button {
    background-color: $ondc-secondary-3;
    color: $ondc-button-text;
    padding: 0.39em 1.22em;
    font-size: 18px;
    font-weight: 500;
    border: 0;
    border-radius: 1.22em;
    display: flex;
    align-items: center;
    z-index: $z-index-button;
  
    &:hover {
      background-color: $primary-color;
    }
  }

  .ondc-button:hover {
      background-color: $primary-color;
  }
  
  .sell-link {
    display: inline-block;
    text-decoration: none;
  }

  .sell-link:active .ondc-button {
    background-color: $ondc-secondary-3;
  }
  
  .easypay-logo {
    width: 120px;
  
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }

/* HERO SECTION STARTS */
#hero {
  position: relative;
  padding: 0;
  // margin-top: 100px;

  #hero-img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    #hero-img {
      display: none;
    }
  }

  #hero-content {
    padding-inline: 1.5em;
    position: absolute;
    top: 35.45%;
    width: 747px;

    .tagline {
      font-size: 40px;
      font-weight: 500 !important;
      margin-bottom: 18px;
      color: $ondc-tagline;

      .tagline span:nth-child(1) {
        font-size: 48px;
        font-weight: 700;
        color: $ondc-secondary-1;
      }

      @media only screen and (max-width: 1200px) {
        .tagline span:nth-child(1) {
          font-size: 40px;
        }
      }
      @media only screen and (max-width: 1024px) {
        .tagline span:nth-child(1) {
          font-size: 32px;
        }
      }
      @media only screen and (max-width: 768px) {
        .tagline span:nth-child(1) {
          font-size: 38px;
        }
      }
      .tagline span:nth-child(2) {
        font-weight: 700;
        color: $ondc-primary-2;
      }
      .ondc-button {
        padding-right: 0.39em;
      }
      .ondc-button img {
        width: 22px;
        margin-left: 13px;
      }
    }
    @media only screen and (max-width: 1200px) {
      .tagline {
        font-size: 32px;
      }
    }
    @media only screen and (max-width: 1024px) {
      .tagline {
        font-size: 26px;
      }
    }
    @media only screen and (max-width: 768px) {
      .tagline {
        font-size: 32px;
      }
    }
    @media only screen and (max-width: 1200px) {
      .tagline {
        font-size: 32px;
      }
    }
    @media only screen and (max-width: 1024px) {
      .tagline {
        font-size: 26px;
      }
    }
    @media only screen and (max-width: 768px) {
      .tagline {
        font-size: 32px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    #hero-content {
      width: 647px;
      font-size: 1.2em;
    }
  }
  @media only screen and (max-width: 1024px) {
    #hero-content {
      width: 467px;
    }
  }
  @media only screen and (max-width: 768px) {
    #hero-content {
      top: unset;
      bottom: 30px;
      width: 603px;
      font-size: 1.2em;
    }
  }
  @media only screen and (max-width: 603px) {
    #hero-content {
      top: unset;
      bottom: 30px;
      width: 350px;
    }
  }
  @media only screen and (max-width: 425px) {
    #hero-content {
      top: unset;
      bottom: 30px;
      // width: 320px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #hero {
    background-image: linear-gradient($ondc-accent-3, transparent);
    height: 404px;
  }
}

/* HERO SECTION ENDS */

/* ABOUT ONDC SECTION STARTS*/
#about-ondc {
  padding-inline: 10px;
  .content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 25px;
    border: none;
  }

  @media only screen and (max-width: 768px) {
    .content {
      display: block;
    }
  }

  .desc-img {
    width: 230px;
  }

  @media only screen and (max-width: 768px) {
    .desc-img {
      width: 150px;
      display: block;
      margin-inline: auto;
    }
  }
}

/* ABOUT ONDC SECTION ENDS*/

/* Easypay ONDC SECTION STARTS*/
#easypay-ondc {
  margin-top: 300px;
  padding-inline: 10px;
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    border: none;
    
    .headline {
      color: $ondc-primary-1;
      font-weight: 700;
      font-size: 32px;
    }
    
      @media only screen and (max-width: 768px) {
          .headline {
            font-size: 26px;
            margin-bottom: 25px;
            line-height: 35px;
          }
        }

    .img-container {
      flex-basis: 48%;

      .desc-img {
        width: 374px;
        display: block;
        margin-inline: auto;
      }

      @media only screen and (max-width: 1024px) {
        .desc-img {
          width: 300px;
        }
      }

      @media only screen and (max-width: 768px) {
        .desc-img {
          width: 225px;
        }
      }
    }

    // @media only screen and (max-width: 768px) {
    //   .headline {
    //     display: none;
    //   }
    // }
    .tag {
      font-size: 0.58em;
      @media only screen and (max-width: 768px) {
        .tag {
          font-size: 0.1em;
        }
      }
    }
    .content-text {
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      display: block;
    }
  }
}

/* Easypay ONDC SECTION ENDS*/

/* SELLER WORK SECTION STARTS*/
#seller-work {
  margin-top: 300px;
  padding-inline: 10px;
  display: block;

  .headline {
    color: $ondc-primary-1;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 250px;
  }
  
    @media only screen and (max-width: 768px) {
        .headline {
          font-size: 26px;
          margin-top: 600px;
          margin-bottom: 12px;
        }
      }

  .content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    border: none;

    .gif-container {
      .desc-img {
        width: 654px;
      }

      @media only screen and (max-width: 1225px) {
        .desc-img {
          width: 450px;
        }
      }

      @media only screen and (max-width: 950px) {
        .desc-img {
          width: 470px;
          display: block;
          margin-inline: auto;
          margin-block: 45px 15px;
        }
      }

      @media only screen and (max-width: 530px) {
        .desc-img {
          width: 100%;
        }
      }

      .desc-img:nth-child(1) {
        display: none;
      }
    }

    .gif-container:hover {
      .desc-img:nth-child(2) {
        display: none;
      }

      .desc-img:nth-child(1) {
        display: block;
      }
    }

    .content-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .work {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2em;
        flex-direction: row;

        img {
          width: 24px;
        }

        @media only screen and (max-width: 768px) {
          img {
            width: 20px;
          }
        }

        p {
          margin: 0;
          font-size: 16px;
          margin-left: 10px;
        }
      }

      @media only screen and (max-width: 1225px) {
        .work {
          margin-bottom: 0.88em;
        }
      }
      @media only screen and (max-width: 950px) {
        .work {
          /* margin-bottom: 0.9em; */
        }
      }
      @media only screen and (max-width: 768px) {
        .work {
          gap: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .content {
      display: block;
    }
  }

  .sell-link {
    margin-top: 180px;
  }
  @media only screen and (max-width: 768px) {
    .sell-link {
      margin-top: 450px;
    }
  }
}
/* SELLER WORK SECTION ENDS*/

/* BENEFITs SECTION STARTS*/

#benefits {
  padding-block: 45px 50px;
  padding-inline: 80px;
  background-color: $ondc-primary-3;

  .headline {
    text-align: center;
    font-weight: 600;
    margin-bottom: 0.94em;
    color: $ondc-primary-2;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2%;

    .card {
      border: 0.5px solid $ondc-primary-2;
      padding: 1em 1.1em;
      background-color: #fff;
      border-radius: 10px;
      width: 32%;

      .head {
        font-size: 22px;
        $head-line-height: 1.45em;
        line-height: $head-line-height;
        font-weight: 600;
        margin-bottom: 8px;
      }

      @media only screen and (max-width: 1394px) {
        .head {
          height: calc(2 * 1.45em);
        }
      }
      @media only screen and (max-width: 900px) {
        .head {
          height: unset;
        }
      }
      @media only screen and (max-width: 768px) {
        .head {
          font-size: 18px;
        }
      }

      .content-text {
        line-height: 28px;
      }

      @media only screen and (max-width: 768px) {
        .content-text {
          line-height: 20px;
        }
      }
    }


    @media only screen and (max-width: 900px) {
      .card {
        width: 100%;
        margin-bottom: 1.25em;
      }
      .card:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card-container {
      flex-direction: column;
    }
  }
}
/* BENEFITs SECTION ENDS*/

/* SELL ON ONDC SECTION STARTS*/

#sell-on-ondc {
  .img-steps-container {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .sell-main-img {
      max-width: 400px;
      border-radius: 30px;
    }

    @media only screen and (max-width: 1024px) {
      .sell-main-img {
        width: 250px;
        border-radius: 20px;
      }
    }
    @media only screen and (max-width: 768px) {
      .sell-main-img {
        display: none;
      }
    }

    .steps-container {
      display: flex;
      flex-direction: column;
      gap: 35px;

      .step-container {
        display: flex;
        align-items: center;
        gap: 15px;

        .sell-icon {
          width: 70px;
        }

        @media only screen and (max-width: 1024px) {
          .sell-icon {
            width: 50px;
          }
        }

        .step-row {
          display: flex;
          align-items: flex-end;

          .number {
            font-size: 70px;
            font-weight: 700;
            color: $ondc-text-100;
            line-height: 0.8em;
          }

          @media only screen and (max-width: 768px) {
            .number {
              font-size: 65px;
            }
          }

          .step-info {
            .step-headline {
              font-size: 20px;
              font-weight: 700;
              display: block;
            }

            @media only screen and (max-width: 768px) {
              .step-headline {
                font-size: 16px;
                margin-bottom: 1px;
              }
            }

            .step-details {
              color: $ondc-text-900;
              display: block;
            }

            @media only screen and (max-width: 768px) {
              .step-details {
                font-size: 14px;
                line-height: 1.2;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 1024px) {
        .step-container {
          gap: 5px;
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      .steps-container {
        gap: 30px;
      }
    }
    @media only screen and (max-width: 768px) {
      .steps-container {
        gap: 25px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .img-steps-container {
      gap: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .img-steps-container {
      flex-direction: column;
      margin-top: 30px;
    }
  }
}

/* SELL ON ONDC SECTION ENDS*/

/* SELLER MANAGEMENT SOLUTION ADVERTISEMENT SECTION STARTS*/
#sms-adv {
//   background-image: url(/ondc/images/solution-back.jpg);
  background-image: url(../../../public/ondc/images/solution-back.jpg);
  background-size: cover;
  padding: 50px 0;

  #adv-background {
    background-color: rgba(255, 255, 255, 0.84);
    padding-inline: 80px;
    padding-block: 45px 50px;
    text-align: center;

    .headline {
      margin-bottom: 30px;
      line-height: 42px;
      color: $ondc-tagline;
    }

    @media only screen and (max-width: 768px) {
      .headline {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }

    .content-text {
      font-size: 22px;
      line-height: 1.6em;
      margin-bottom: 0.9em;
    }

    @media only screen and (max-width: 768px) {
      .content-text {
        font-size: 18px;
      }
    }

    .ondc-button {
      margin: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    #adv-background {
      // padding-block: 25px 30px;
      padding-block: 25px 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #sms-adv {
    padding: 40px 0;
  }
}

/* SELLER MANAGEMENT SOLUTION ADVERTISEMENT SECTION ENDS*/

/* SELLER MANAGEMENT SOLUTION SECTION STARTS*/
#sms .image-benefits-container {
    display: flex;
    align-items: center;
    margin-top: 70px;

  .sms-img {
    width: 300px;
    display: block;
    margin-inline: auto;
  }

  @media only screen and (max-width: 768px) {
    .sms-img {
      // width: 200px;
      margin-bottom: 30px;
    }
  }
  
  .benefits-container {
    margin-left: 95px;
    list-style-image: url(../../../public/ondc/icons/bullet-triangle.png);
  
  .benefit {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.42em;
    margin-bottom: 1.6em;
    padding-left: 1em;
    color: $ondc-secondary-1;
  }

  @media only screen and (max-width: 768px) {
    .benefit {
      font-size: 16px;
    }
  }
  
  .benefit:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .benefits-container {
    margin-left: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .benefits-container {
    margin-left: 25px;
  }
}

  }

  @media only screen and (max-width: 768px) {
    #sms .image-benefits-container {
      flex-direction: column;
      margin-top: 30px;
    }
}
  /* SELLER MANAGEMENT SOLUTION SECTION ENDS*/
  
/* INVITATION SECTION STARTS*/
#invitation {
  padding-inline: 10;
    // margin-bottom: 20px;
  }
  /* INVITATION SECTION ENDS*/

/* SELLERS ONBOARDING IMAGE */

#sellers-img-container {
  display: flex;
  flex-wrap: wrap;
  padding-inline: 10;
}

#sellers-img-container .sellers-img {
  width: 100%;
}

}