.order-details {
  width: 100%;
  font-size: 14px;

  .order-header {
    border: 1px solid #e6e6e6;
    border-radius: 10px 10px 0 0;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    @media (max-width: 768px) {
      border: 0;
      padding: 0;
      margin-bottom: 42px;
    }

    @media (max-width: 425px) {
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
    }

    .order-header-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .header-title {
        font-size: 20px;
        font-weight: 500;
      }

      .order-head {
        font-size: 20px;
        font-size: 14px;
        color: #4d4d4d;
        font-weight: 400;
        flex-shrink: 0;

        p {
          display: inline-block;

          &::before {
            content: '\2022';
            margin-inline: 8px;
          }
        }

        p:first-child::before {
          margin-left: 0;
        }

        @media (max-width: 425px) {
          p::before {
            content: none;
          }

          p:nth-child(2)::before {
            content: "|";
            margin-inline: 8px;

          }
        }
      }
    }

    .order-header-right.back-to-list {
      color: #6FAE79;
      font-size: 16px;
      font-weight: 500;
      flex-shrink: 0;
      cursor: pointer;
    }

  }

  .order-body {
    border: 1px solid #e6e6e6;
    border-top: 0;
    border-radius: 0 0 10px 10px;
    padding: 24px;

    @media (max-width: 768px) {
      padding: 0;
      border: 0;
    }

    .order-status-message {
      color: #34a853;
      font-size: 16px;
      font-weight: 500;
    }

    .order-status-delivered {

      .delivered-label {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .delivered-date {
        color: #34a853;
      }
    }

    .order-track-cancel {
      .cancel-status {
        color: red;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 500;
      }

      .cancel-date {
        margin-bottom: 5px;
        font-size: 14px;
      }

      .cancel-reason {
        font-size: 14px;
      }
    }

    .order-track {

      .progress {
        max-width: 440px;
        margin: auto;
        margin-top: 30px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 20px;

        .step-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 10px;
          position: relative;

          span {
            font-weight: 700;
            font-size: xx-small;
          }

          .step {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #4caf50;
            border-style: dashed;
            color: #6fae79;
            font-weight: bold;
            font-size: 16px;
            background-color: #fff;
            margin-bottom: 5px;
            z-index: 1;

            &.completed {
              background-color: #4caf50;
              color: white;
            }
          }
        }

        .line-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-left: -40px;
          margin-right: -40px;
          position: relative;
          top: -3px;
        }

        .lines {
          height: 4px;
          width: 100px;
          background-color: #ccc;
          margin-left: 5px;
          z-index: 0;

          &.completed {
            background-color: #4caf50;
          }
        }
      }

      .statuses {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;

        .status {
          text-align: center;
          color: #666;
          flex: 1 1 20%;
          margin-bottom: 15px;

          &.current {
            font-weight: bold;

            .icon {
              color: #4caf50;
            }
          }

          .icon {
            font-size: 24px;
            margin-bottom: 5px;
            color: #ccc;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

    .order-items {
      // display: none;
      margin-top: 40px;

      table {
        width: 100%;
        border-collapse: collapse;

        .product_img {
          display: flex;
          align-items: center;

          .product-qty {
            font-weight: 700;
          }

          @media screen and (min-width: 480px) {
            div {
              .product-qty {
                display: none;
              }
            }
          }

          @media screen and (max-width: 768px) {
            div {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          }
        }

        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          text-align: left;
        }

        td {
          font-size: 14px;
        }

        th {
          font-size: 14px;
          background: #f2f2f2;
          font-weight: bold;
          color: #333;
        }

        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        @media screen and (max-width: 480px) {
          thead {
            display: none;
          }

          td {
            padding: 10px;
            border-bottom: 0px;
            // text-align: left;
          }
        }

        @media screen and (max-width: 480px) {
          .item-price-mob {
            display: none;
          }
        }
      }
    }

    .order-info {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      gap: 24px;

      .address-section {
        display: flex;
        width: 66.66%;

        .address-box {
          width: 50%;
          border: 1px solid #e6e6e6;
          font-size: 14px;

          .address-box-title {
            border-bottom: 1px solid #e6e6e6;
            padding: 18px 20px;
            color: #999999;
            font-weight: 500;
          }

          .address-box-content {
            padding: 14px 10px 20px 20px;

            .add-name {
              margin: 0 0 10px 0;
              font-size: 16px;
            }

            .ord-address {
              color: #666;
              font-size: 14px;
            }

            .contact-div {
              padding: 24px 0px;

              p {
                font-size: 14px;
                color: #999999;
              }

              .ord-label {
                font-size: 12px;
                margin: 5px 0;
              }
            }
          }
        }

        .address-box:nth-child(1) {
          border-radius: 4px 0px 0px 4px;
        }

        .address-box:nth-child(2) {
          border-radius: 0px 4px 4px 0;
          border-left: 0;
        }
      }

      .order-summary {
        width: 30.75%;

        // display: none;
        .order-summary-sub {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #e6e6e6;
          border-bottom: 0;
          border-radius: 4px 4px 0 0;
          flex-wrap: wrap;
          padding: 18px 20px;
          overflow: hidden;
          gap: 15px;

          .div span {
            word-wrap: break-word;
            white-space: normal;
          }

          .order-payment {
            padding-bottom: 3px;
            color: #999999;
          }

          .div:first-child {
            margin-bottom: 10px;
          }
        }

        .price-summary {
          padding: 6px 20px;
          border: 1px solid #e6e6e6;
          border-radius: 0 0 4px 4px;

          .price-details {
            border-bottom: 1px solid #ddd;
            padding-block: 12px;
          }

          .total {
            font-weight: bold;
            color: green;
            font-size: 16px;
            margin-top: 5px;
            border-bottom: 0;
          }
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;

        .address-section {
          width: 100%;
        }

        .order-summary {
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        .address-section {
          width: 100%;
          flex-direction: column;
          gap: 24px;

          .address-box {
            width: 100%;
          }

          .address-box:nth-child(2) {
            border: 1px solid #e6e6e6;
          }
        }
      }
    }

    .store-info {
      margin-block: 40px 60px;

      .store-info-label {
        font-weight: 600;
        font-size: 16px;
        color: #333;
        margin-bottom: 7px;
      }

      .store-name {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .store-address {
        color: #333;
      }
    }

    .order-actions {
      display: flex;
      margin-top: 20px;
      gap: 20px;
      flex-wrap: wrap;

      @media (max-width: 425px) {
        flex-direction: column;
        gap: 10px;
      }

      button {
        white-space: nowrap;
        ;
      }

      .help-btn {
        background-color: #f2f2f2;
        border: none;
        padding: 14px 50px;
        color: red;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
      }

      .cancel-btn {
        background-color: #6fae79;
        border: none;
        padding: 14px 50px;
        color: white;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}


@media (max-width: 768px) {
  .order-details {
    width: 100%;

    .order-info {
      flex-direction: column;

      .address-section,
      .order-summary {
        width: 100%;
        text-align: left;
        margin-top: 10px;
      }
    }

    .order-track .status-bar {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .order-track {
    .progress {
      .step-container {
        span {
          font-weight: 700;
          font-size: xx-small;
        }

        .step {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #4caf50;
          border-style: dashed;
          color: #4caf50;
          font-weight: bold;
          font-size: 16px;
          background-color: #fff;
          margin-bottom: 5px;
          z-index: 1;

          &.completed {
            background-color: #6fae79;
            color: white;
          }
        }
      }

      .line-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: -40px;
        margin-right: -40px;
        position: relative;
        top: -3px;
      }

      .lines {
        height: 4px;
        width: 100px;
        background-color: #ccc;
        margin-left: 5px;
        z-index: 0;

        &.completed {
          background-color: #6fae79;
        }
      }
    }

    .statuses {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;

      .status {
        text-align: center;
        color: #666;
        flex: 1 1 20%;
        margin-bottom: 15px;

        &.current {
          font-weight: bold;

          .icon {
            color: #4caf50;
          }
        }

        .icon {
          font-size: 24px;
          margin-bottom: 5px;
          color: #ccc;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .order-details {
    .order-info {
      flex-direction: column;

      .address-section,
      .order-summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
        margin-top: 18px;
        gap: 18px;
        border-radius: 8px;
      }
    }

    .order-actions {
      margin-top: 55px;
    }

    .order-header {
      border-bottom: 0 !important;
    }
  }
}

@media (max-width:430px) {

  .order-details .order-track .progress {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
  }

}