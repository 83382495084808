.store-item-card {
  width: 240px;
  cursor: pointer;
  padding: 10px;
  border: 1.5px solid #e2e2e2;
  border-radius: 10px;
  flex-shrink: 0;

  .product-img-container {
    width: 100%;
    height: 134px;
    border-radius: 0px;
    position: relative;
    overflow: hidden;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .deal-img {
      border-radius: 20px;
      width: auto;
      height: 100%;
      object-fit: contain;
      object-position: left;
      display: block;
    }

    .deal-discount {
      position: absolute;
      bottom: 0;
      left: 0;
      // --length: calc(0.37 * 100%);
      // width: var(--length);
      background-color: #53b175;
      border-radius: 0 100% 0 0;
      padding: 15px;
      color: #fff;

      span {
        font-size: 12px;
      }

      h2 {
        font-size: 16px;
        margin-top: 10px;
        line-height: 0.78em;
      }
    }
  }

  .product-content {
    font-size: 16px;
    overflow: visible;
    position: relative;

    .product-desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      font-size: small;
      margin-bottom: 10px;
      height: 2.6em;

      &:hover::after {
        content: attr(data-full-text);
        position: absolute;
        top: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 8px;
        border-radius: 5px;
        z-index: 2000000;
        width: 100%;
        max-width: 200px;
        word-wrap: break-word;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .product-name {
      font-weight: 500;
      overflow: hidden;
      font-size: 14px;
      text-overflow: ellipsis;
      height: 2.6em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 5px;
    }

    .star-review {
      display: flex;
      gap: 9px;
      align-items: center;

      .five-stars {
        .star {
          display: inline-block;
          width: 13px;
          height: 13px;
          background-image: url(../../assets/images/homepage/dull-star.png);
          background-size: cover;
        }

        .shining {
          background-image: url(../../assets/images/homepage/shining-star.png);
        }
      }

      .review-sum {
        font-family: "Poppins";
        font-size: 0.6em;
        color: #a2a6b0;
      }
    }

    .price-container-home {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .price-home {
        color: #21a57f;
        font-size: 1em;
      }

      .max-price-home {
        color: #676767;
        font-size: 0.8em;
        text-decoration: line-through;
      }
    }

    .buy-button {
      border: 1.5px solid #23ae75;
      padding: 7px 26px;
      text-transform: uppercase;
      font-size: 0.7em;
      border-radius: 5px;
    }
    .addButton {
      margin-top: 12px;
      border-radius: 17px;
      color: #ffffff;
      cursor: pointer;

      .quantityControls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #64b882;
        background: rgba(83, 177, 117, 0.1);
        height: 40px;
        border-radius: 17px;
        color: black;
        align-items: center;
        padding: 2px;

        .increment,
        .decrement {
          padding: 0 20px;
          border: 1px solid rgba(226, 226, 226, 1);
          background-color: #fff;
          height: 100%;
          border-radius: 17px;
          cursor: pointer;

          &:hover {
            border: 1px solid #64b882;
          }

          &:active {
            background-color: #53b175;
            color: #fff;
          }
        }
      }

      .addonlyButton {
        border: none;
        color: #ffffff;
        background: #53b175;
        border-radius: 17px;
        width: 100%;
        height: 40px;
        cursor: pointer;
      }
      .addonlyButton:hover {
        background: #19af50;
      }
    }
  }
}
