.category-brand-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  .category-title {
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 22px;
    }
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }

  .category-scroll-wrapper,
  .brand-scroll-wrapper {
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    .scroll-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.5);
      color: rgba(200, 200, 200, 0.7);
      box-shadow: 1px 0px 10px 1px #b8b8b8;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }

    .left {
      left: 0px;
    }

    .right {
      right: 0px;
    }
    .category-banner {
      width: 230px;
      border-radius: 10px;
    }
    .top-deal-banner {
      width: 370px;
      border-radius: 10px;
    }
  }

  .category-scroll {
    display: flex;
    transition: transform 0.5s ease;
    overflow-x: auto;
    overflow-x: scroll;
    gap: 1rem;
    scroll-behavior: smooth;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: nowrap;
    margin-bottom: 20px;
    img {
      cursor: pointer;
    }
  }
  .brand-scroll {
    display: flex;
    justify-content: center;
    transition: transform 0.5s ease;
    overflow-x: auto;
    overflow-x: scroll;
    gap: 1rem;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: nowrap;
    animation: scrollLoop 25s linear infinite;
    width: max-content;
  }
  .brand-scroll:hover {
    animation-play-state: paused;
  }
  .brand-scroll img {
    width: 130px;
    height: auto;
    border-radius: 20px;
    border: 1px solid #ccc;
  }
  .paused {
    animation-play-state: paused;
    cursor: pointer;
  }

  @keyframes scrollLoop {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50.33%);
    }
  }

  .category-scroll::-webkit-scrollbar {
    display: none;
  }
}
