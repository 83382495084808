.profile {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .profile-page {
    flex: 1;
    position: relative;
  }
}
