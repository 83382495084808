.search-sugg-item {
    display: flex;
    gap: 10px;

    .item-img {
        width: 30px;
        height: 40px;
        object-fit: contain;
        flex-shrink: 0;
    }

    .item-info {
        .item-name-row {
            display: flex;
            gap: 5px;
            margin-bottom: 3px;

            .item-name {
                font-size: 14px;
                font-weight: 500;
            }

            .item-distance {

            }
        }

        .item-profile {
            color: #666;
        }

    }
}