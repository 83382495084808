.main-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  color: #000000;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .header-left {
      font-size: 20px;
      font-weight: 500;
      line-height: 18px;
    }
    .header-middle {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      background: #53b175;
      padding: 3px 5px;
      border-radius: 5px;
    }
    .header-right a {
      color: #00b207;
      text-decoration: none;
      font-weight: 500;
      cursor: pointer;
    }
  }
  @media (max-width: 430px) {
    .header {
      flex-wrap: wrap-reverse;
      justify-content: unset;
      .header-right {
        width: 100%;
        font-weight: 400;
      }
    }
  }

  .divider {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    transform: translateX(-20px);
    margin-top: 20px;
  }

  .issue-details {
    .issue-info {
      font-size: 14px;
      margin-bottom: 20px;

      .issue-label {
        font-weight: 400;
        color: #666;
        margin-bottom: 5px;
        font-size: inherit;
        white-space: nowrap;
      }

      .issue-desc {
        color: #000;
        font-weight: 400;
        font-size: inherit;
        a.sub-link {
          color: #00b207 !important;
        }
      }
    }
  }

  .success-message {
    // border-radius: 5px;
    margin-bottom: 20px;
    // position: relative;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   width: 100%;
    //   bottom: -12px;
    //   border-bottom: 1px dashed #dcdfe4;
    // }

    .title-container {
      display: flex;
      justify-content: flex-start;
      margin: 27px 0;
      .success-title {
        font-weight: 700;
        font-size: 14px;
        color: #1a1a1a;
      }
      .icon-green {
        width: 32px;
        color: #53b175;
      }
    }

    .success-info {
      font-weight: 400;
      font-size: 14px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #dcdfe4;
    }
  }
  .id-date-container {
    .issue-id {
      color: #1a1a1a;
      font-weight: 700;
      font-size: 14px;
    }

    .date {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 700;
      margin-bottom: 20px;
      color: #1a1a1a;
    }

    .issue-head-label {
      // color: #6f6f6f;
      font-weight: 400;
      color: #666;
    }
  }

  .reason-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .reason-details {
      flex: 1;
      .issue-info {
        .issue-short {
          font-weight: 500;
          margin-bottom: 3px;
        }
      }
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        margin-top: 20px;
      }
      .sub-title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 2px;
      }
    }
    .issue-photo {
      flex: 1;
      // width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
      .issues-pic-container {
        display: flex;
        gap: 4px;
        .issues-pic {
          width: 110px;
          background-color: #4caf50;
          object-fit: cover;

          background: url("") center/cover no-repeat;

          &:empty {
          }
        }
      }
    }
  }

  .product-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 45px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #1a1a1a;
      margin-top: 30px;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 8px;
        border-bottom: 1px dashed #dcdfe4;
      }
    }

    .product-item {
      display: flex;
      gap: 20px;
      align-items: center;
      border-bottom: 1px dashed #dcdfe4;

      &:last-child {
        border-bottom: none;
      }

      img {
        border-radius: 5px;
        width: 80px;
        height: 80px;
        object-fit: contain;
      }

      .product-container {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .product-qty {
          display: flex;
          flex-direction: column;

          .qty-name {
            font-weight: 700;
            font-size: 14px;
            color: #1a1a1a;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            word-break: break-word;
          }
          .qty {
            font-weight: 400;
            font-size: 12px;
            color: #666;
          }

          p {
            margin: 0;
          }
        }

        .price-value {
          font-weight: bold;
          margin-left: auto;
          white-space: nowrap;
        }
      }
    }
  }

  .resolver-details {
    margin-bottom: 40px;
    h3 {
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px dashed #dcdfe4;
      padding-bottom: 20px;
      color: #1a1a1a;
      margin-bottom: 30px;
    }

    .issue-info {
      flex: 1 1 calc(30% - 13.3333px);
      margin-bottom: 0;
    }

    .issue-info:nth-child(3) {
      flex: 1 1 calc(40% - 13.3333px);
    }


    .resolver-contact, .resolver-action {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .issue-info {
        margin-bottom: 0;
      }
    }

    .issue-long {
      margin-block: 20px;
    }

    .resolver-contact {
      @media (max-width: 768px) {
      .issue-info:nth-child(3) {
        flex-basis: 100%;
      }
    }

    }
  }

  .feedback {
    h3 {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
      color: #1a1a1a;
      padding-bottom: 10px;
      border-bottom: 1px dashed #dcdfe4;
    }

    .feedback-buttons {
      display: flex;
      gap: 25px;
      button {
        background: none;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
        margin-right: 10px;

        &.thumbs-up {
          color: #4caf50;
        }

        &.thumbs-down {
          color: #f44336;
        }
      }
      .thumbs-down img {
        transform: rotate(180deg);
      }
    }
    .thank-you {
      color: #1a1a1a;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .main-container {
    padding: 0;
    border: 0;

    .header {
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #d5d9d9;

      .header-left {
        font-size: 18px;
      }
    }
    .divider {
      display: none;
    }

    .reason-container {
      flex-direction: column;
      gap: 0;
    }

    .reason-details,
    .issue-photo {
      // flex: none;
      // width: 100%;
    }

    // .reason-container {
    //   display: flex;
    //   gap: 30px;
    //   flex-wrap: wrap;

    //   .reason-details {
    //     max-width: 100%;
    //     .title {
    //       font-size: 14px;
    //       font-weight: 400;
    //       color: #666;
    //       margin-top: 20px;
    //     }
    //     .sub-title {
    //       font-size: 14px;
    //       font-weight: 400;
    //       margin-bottom: 2px;
    //     }
    //   }
    //   .issue-photo {
    //     // width: 50%;
    //     display: flex;
    //     flex-direction: column;
    //     .title {
    //       font-size: 14px;
    //       font-weight: 400;
    //       color: #666;
    //     }
    //     .issues-pic-container {
    //       display: flex;
    //       gap: 4px;
    //       .issues-pic {
    //         width: 110px;
    //         background-color: #4caf50;
    //         object-fit: cover;

    //         background: url("") center/cover no-repeat;

    //         &:empty {
    //         }
    //       }
    //     }
    //   }
    // }

    // .product-list .product-item {
    //   justify-content: flex-start;
    //   flex-direction: column;
    //   margin-right: auto;
    // }
  }

  .feedback-buttons {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 430px) {
  .header-left {
    font-size: 16px;
  }

  .resolver-details {
    .resolver-contact,
    .resolver-action {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
    }
  }

  .feedback-buttons button {
    font-size: 1em;
  }
}
