.card {
  padding: 15px;
  border: 1.5px solid #e2e2e2;
  border-radius: 10px;
  // max-width: 250px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .cardHeader {
    display: flex;
    justify-content: space-between;

    .vegIcon {
      width: 18.2px;
      height: 18.27px;
      visibility: hidden;
    }

    .discount {
      padding: 5px 15px;
      border-radius: 28px;
      background: #53b175;
      color: #fff;
      font-size: 11.2px;
      position: relative;
      z-index: 1;
      margin-left: 10px;
      font-weight: 400;
    }
  }

  .cardproductImage-container {
    width: 130px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    margin-block: 16px 22px;
    margin-top: -5px;
    cursor: pointer;
    overflow: hidden;

    .cardproductImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .productDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    .rating {
      display: flex;
      align-items: center !important;

      .stars {
        color: gold;
      }

      .inStock {
        color: #53b175;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 500;
      }

      .outOfStock {
        color: #ea2020;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .productFlex {
      margin-top: 3px;
      display: flex;
      cursor: pointer;
    }

    .productName {
      font-size: 16px;
      line-height: 1.3em;
      font-weight: 700;
      margin-top: 12px;
      height: 2.6em;
      display: -webkit-box; /* Fallback for older browsers */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }

    .productWeight {
      font-size: 14px;
      color: #888;
      margin-top: 4px;
      // margin-left: 10px;
    }

    .productWeights {
      font-size: 14px;
      color: #888;
      margin-top: 4px;
      margin-left: 10px;
      height: 1.3em;
      display: -webkit-box; /* Fallback for older browsers */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .price {
      margin-top: 8px;
      font-size: 14px;

      .rupee {
        color: #53b175;
        font-weight: bold;
        margin-right: 8px;
      }

      .strikethrough {
        text-decoration: line-through;
        color: #7c7c7c;
        margin-right: 8px;
      }

      .discountPercentage {
        color: white;
        background-color: #53b175;
        border-radius: 10px;
        padding: 3px 15px;
        width: 35px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .shopIcon {
      width: 18.2px;
      height: 18.27px;
    }

    .addButton {
      margin-top: 12px;
      border-radius: 17px;
      color: #ffffff;
      cursor: pointer;

      .quantityControls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #64b882;
        background: rgba(83, 177, 117, 0.1);
        height: 40px;
        border-radius: 17px;
        color: black;
        align-items: center;
        padding: 2px;

        .increment,
        .decrement {
          padding: 0 20px;
          border: 1px solid rgba(226, 226, 226, 1);
          background-color: #fff;
          height: 100%;
          border-radius: 17px;
          cursor: pointer;

          &:hover {
            border: 1px solid #64b882;
          }

          &:active {
            background-color: #53b175;
            color: #fff;
          }
        }
      }

      .addonlyButton {
        border: none;
        color: #ffffff;
        background: #53b175;
        border-radius: 17px;
        width: 100%;
        height: 40px;
        cursor: pointer;
      }
      .addonlyButton:disabled {
        background-color: rgba(83, 177, 117, 0.7);
      }
      .addonlyButton:not(:disabled):hover {
        background: #19af50;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    min-width: 0;
  }
}
