.login-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form-container {
    width: 350px;
    padding: 20px;
    margin: 20px 30px 80px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 56px 0px rgba(0, 38, 3, 0.0784313725);
    text-align: center;

    .login-title {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: bold;

      @media (max-width: 360px) {
        font-size: 28px;
        
      }
    }

    .login-form {
      display: flex;
      flex-direction: column;

      div {
       width: 100%;
       justify-content: space-between;
      }

      .login-input {
        margin-bottom: 12px;
        padding: 13px;
        font-size: 16px;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
      }

      .otp {
        height: 2.5rem;
        background-color: #E4F6EA;
        outline: none; 
        outline-color: #00987C;
        border-radius: 0.5rem;
        border-width: 2px;
        border-style: solid;
        border-color: #cbd5e1;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 500;

        @media (max-width: 590px) {
          
          
        }
      }

      .otp:focus {
        outline: none;
        background-color: #E4F6EA;
      }

      .error-message-login {
        font-size: 14px;
        text-align: left;
        color: red;
        margin-bottom: 5px;
      }

      .password-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        .password-input {
          width: 100%;
          padding-right: 40px;
          /* Space for the icon */
        }

        .password-icon {
          position: absolute;
          right: 10px;
          top: 14px;
          cursor: pointer;

          .image {
            height: 20px;
            width: 20px;
          }
        }
      }

      .login-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;

        .label-with-checkbox {
          display: flex;
          align-items: center;

          .checkbox-input {
            height: 20px;
            width: 20px;
          }

          .remember {
            margin-left: 6px;
            color: #666666;
          }
        }

        .forgot-password {
          color: #666666;
          text-decoration: none;
        }
      }

      .login-button {
        padding: 13px;
        background: #53B175;
        ;
        color: #fff;
        font-size: 16px;
        border: none;
        border-radius: 24px;
        cursor: pointer;
      }

      .login-button-verify {
        margin-top: 12px;
      }

      .time-running {
        color: #53B175;
        margin-top: 12px;
        font-weight: 700;
      }

      .time-disable {
        opacity: 0.5;
        color: rgb(107 114 128);
      }

      .time-enable {
        cursor: pointer;
      }

      .register-text {
        margin-top: 20px;
        font-size: 14px;

        a {
          color: #1A1A1A;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
}

.blackScreen {
  background-color: black;
  color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normalScreen {
  background-color: white;
  color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}