.offer-row-container {
  padding: 20px;
  max-width: 1400px;
  margin-inline: auto;
  margin-bottom: 80px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .offer-row {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 15px;

    @media (max-width: 768px) {
      grid-template-columns: auto auto;
      row-gap: 7vw;
    }

    @media (max-width: 425px) {
      grid-template-columns: none;
    }

    .offer-card {
      border: 1px solid #ededed;
      position: relative;
      font-size: 1.2vw;

      @media (min-width: 1440px) {
        font-size: 16px;
      }

      @media (max-width: 768px) {
        font-size: 2.2vw;
      }

      @media (max-width: 425px) {
        font-size: 4vw;
      }

      .offer-card-top {
        position: relative;

        .offer-main-img {
          width: 100%;
        }

        .offer-text-container {
          background-color: var(--color-darkseagreen);
          position: absolute;
          bottom: 0;
          width: 100%;
          box-sizing: border-box;
          text-align: center;
          padding: 0.7em;
          font-weight: 500;

          p {
            margin: 0;
          }
        }
      }

      .offer-card-bottom {
        display: grid;
        // display: none;
        grid-template-columns: auto auto auto;
        padding: var(--padding-xs) var(--padding-lg) var(--padding-12xl);
        gap: var(--gap-8xs);

        .sub-offer {
          .sub-offer-img {
            width: 100%;
          }

          .sub-offer-text {
            text-align: center;
          }
        }
      }

      .shop-all-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        font-size: calc(0.65 * 100%);
        font-size: 1.1vw;
        padding: 0.5em;
        font-family: "inter";
        border: 1px solid #ededed;
        background-color: #fff;
        color: #000;

        @media (min-width: 1440px) {
          font-size: 14px;
        }

        @media (max-width: 768px) {
          font-size: 1.8vw;
        }

        @media (max-width: 425px) {
          font-size: 3.5vw;
        }
      }
    }
  }
}
