.breadcrumb {
  color: #232b4a;
  padding: 10px 0 25px;
  border-radius: 8px;
  span:first-child {
    // text-decoration: underline;
  }
  .rightArrow {
    
  }
  span {
    cursor: pointer;
  }

  .product-name {
    color: #3a4980;
    cursor: text;
  }
}
