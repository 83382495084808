.filter-container {
  width: 260px;
  border: 1.5px solid #e2e2e2;
  border-radius: 10px;

  .filter-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    border-bottom: 1.5px solid #e2e2e2;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .clear-btn {
      font-size: 10px;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .filter-content {
    padding: 20px;

    .title {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
      border-bottom: 1.5px solid #e2e2e2;
    }

    .check-filter-container {
      margin-bottom: 30px;
      
      .check-filters {
        margin-top: 20px;
        .check-filter {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 9px;
          .custom-check {
  
          }
  
          label {
  
          }
        }
      }
    }

    .range-filter-container {
      .price-range {
        margin-top: 25px;
      }
    }
  }
  
}