.slider-container {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  min-width: 0;

  .slider-view {
    overflow-x: auto;
    // padding: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    // .slider-items {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;

    // .slider-item {
    //   width: 130px;
    //   flex-shrink: 0;

    //   .category-icon {
    //     width: 100%;
    //   }

    //   .shop-by-category-labels {
    //     p {
    //       color: #010101;
    //       font-family: Inter;
    //       font-size: 16px;
    //       font-style: normal;
    //       font-weight: 400;
    //       line-height: normal;
    //       text-align: center;
    //       margin-top: 10px;
    //     }
    //   }

    //   .shop-by-category {
    //     margin: 0;
    //     position: relative;
    //     font-size: var(--font-size-21xl);
    //     font-weight: 400;
    //     font-family: inherit;
    //     text-align: left;
    //     display: inline-block;
    //     max-width: 100%;
    //   }

    //   @media screen and (max-width: 800px) {
    //     .shop-by-category {
    //       font-size: var(--font-size-13xl);
    //     }
    //   }
    // }
    // }
  }

  .slide-for,
  .slide-back {
    position: absolute;
    height: 45px;
    width: 45px;
    font-size: 24px;
    border-radius: 50%;
    background-color: #fff;
    // background-color: rgba(220, 220, 220, 0.5);
    padding: 0 7px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    // color: #0b0b0b;
    color: rgba(200, 200, 200, 0.7);
    // text-shadow: 0px 0px 3px #7f7f7f;
    box-shadow: 1px 0px 10px 1px #b8b8b8;
    display: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover .slide-for,
  &:hover .slide-back {
    // display: block;
  }

  .slide-for:hover,
  .slide-back:hover {
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px #b8b8b8;
  }

  .slide-for {
    right: 0px;
  }

  .slide-back {
    left: 0px;
  }

  &:hover .slide-for,
  &:hover .slide-back {
    // display: block;
  }

  .hover-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    cursor: pointer;
  }

  .hover-zone-left {
    // left: -60px;
  }

  .hover-zone-right {
    // right: -60px;
  }

  .hover-zone:hover ~ .slide-back,
  .hover-zone:hover ~ .slide-for {
    display: block;
  }

  .slide-for.hidden,
  .slide-back.hidden {
    display: none;
  }
}
