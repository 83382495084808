.deals-container {
  .row-container {
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
      gap: 10px;
      margin-bottom: 30px;

      .product-list-title {
        font-weight: 400;
        font-size: 1em;
      }

      .explore-btn {
        text-transform: uppercase;
        color: #000;
        text-decoration: underline;
        font-size: 16px;
        text-wrap: nowrap;
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        font-size: 22px;

        .explore-btn {
          font-size: 12px;
        }
      }

      @media screen and (max-width: 650px) {
        font-size: 20px;

        .explore-btn {
          font-size: 10px;
        }
      }
    }
  }
}
