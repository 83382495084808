.single-product {
  margin-top: 5px;

  .main-content {
    display: flex;
    gap: 50px;
    margin-bottom: 37px;
    .left {
      width: 40%;
      .image-slider {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
  
        .main-img {
          // position: sticky;
          top: 200px;
          max-height: 350px;
          width: 100%;
          object-fit: contain;
          border-radius: 10px;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px;
          cursor: pointer;
          z-index: 1;
          height: 60px;
          width: 60px;
          font-size: 24px;
          border-radius: 50%;
          opacity: 0;
          visibility: hidden;
  
          &.left {
            left: 10px;
          }
  
          &.right {
            right: 10px;
          }
  
          &:hover {
            background: rgba(0, 0, 0, 0.7);
          }
        }
        .nav-button.left {
          left: 10px;
        }
  
        .nav-button.right {
          right: 10px;
        }
        &:hover .nav-button {
          opacity: 1;
          visibility: visible;
        }
        .current-index {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 14px;
        }
      }
      .loader-img{
        max-height: 350px;
        width: 100%;
        object-fit: contain;

      }
    }

    .right {
      width: 65%;
      .head-container {
        display: flex;
        justify-content: space-between;

        .product-title-container {
          .title {
            font-size: 24px;
            margin-bottom: 5px;
          }

          .sub-title {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(185, 187, 191, 1);
          }
          .product-desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            font-size: 15px;
            margin: 10px 0;
            &:hover::after {
              content: attr(data-full-text);
              position: absolute;
              top: 100%;
              left: 0;
              background-color: rgba(0, 0, 0, 0.8);
              color: #fff;
              padding: 8px;
              border-radius: 5px;
              z-index: 2000000;
              width: 100%;
              max-width: 200px;
              word-wrap: break-word;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }
          }
        }

        .head-btns {
          display: flex;
          align-self: flex-start;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;

          .head-btn {
            padding: 10px 16px;
            background-color: rgba(237, 240, 248, 1);
            border-radius: 10px;

            img {
              width: 15px;
              display: block;
            }
          }

          .offer {
            color: rgba(255, 79, 79, 1);
            background-color: rgba(255, 79, 79, 0.1);
            text-wrap: nowrap;
          }
        }
      }

      .product-line {
        color: rgba(228, 228, 228, 1);
        margin-block: 21px;
      }

      .price-review {
        display: flex;
        gap: 40px;
        align-items: center;

        .price-container {
          .price {
            font-size: 34px;
            color: rgba(83, 177, 117, 1);
            font-weight: 700;
          }

          .mrp {
            font-size: 21px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            .mrp-rupees {
              text-decoration: line-through;
            }
          }
        }

        .review-container {
          .review-tabs {
            display: flex;
            gap: 12px;
            margin-bottom: 12px;

            .review-tab {
              font-weight: 600;
              border-radius: 27px;
              padding: 7px 10px;
              display: flex;
              align-items: center;
              gap: 9px;

              img {
                display: block;
              }

              span {
                font-size: 14px;
              }
            }

            .review-tab:nth-child(1) {
              background-color: rgba(251, 243, 234, 1);
              color: rgba(212, 141, 59, 1);

              img {
                width: 12.5px;
              }
            }

            .review-tab:nth-child(2) {
              background-color: rgba(237, 240, 248, 1);
              color: rgba(58, 73, 128, 1);

              img {
                width: 13px;
              }
            }
          }

          .recommend-text {
            span {
              font-weight: 700;
              color: rgba(26, 164, 136, 1);
            }
          }
        }

        .show-versions {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .change-product-details {
            border: 1px solid #dee2e6;
            padding: 6px 24px;
            border-radius: 50rem;
            // margin: 0 8px 8px 0;
            cursor: pointer;
            font-size: 0.9rem;
          }
          .active-product {
            background-color: rgba(83, 177, 117, 1);
            color: #fff;
          }
        }
      }

      .offer-headline {
        font-weight: 600;
        font-size: 16px;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 14px;
      }

      .timer-card {
        display: flex;
        justify-content: space-between;

        .timer-container {
          .timer {
            padding: 17px 14px;
            background-color: rgba(248, 248, 248, 1);
            display: flex;
            align-items: center;
            gap: 22px;
            border-radius: 8px;

            .number-column {
              text-align: center;
              font-weight: 400;

              .number {
                font-size: 20px;
              }

              .text {
                font-size: 14px;
              }
            }

            .time-dots {
            }
          }
        }

        .cards-container {
          .cards {
            padding: 17px 30px 17px 21px;
            background-color: rgba(248, 248, 248, 1);
            border-radius: 8px;
            display: flex;
            gap: 10px;
            align-items: center;

            .card-container {
              padding: 8px 12px;
              width: 72px;
              height: 43px;
              background-color: #fff;
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
              display: flex;
              align-items: center;
              border-radius: 3px;

              .payment-card {
                width: 100%;
              }
            }
          }
        }
      }

      .weight-container {
        .weights {
          display: flex;
          gap: 13px;

          .weight {
            font-weight: 400;
            font-size: 14px;
            border: 1px solid rgba(236, 236, 236, 1);
            border-radius: 4px;
            padding: 6px 12px;
            color: rgba(74, 85, 104, 1);
          }

          .selected-weight {
            background-color: rgba(83, 177, 117, 1);
            border-color: rgba(83, 177, 117, 1);
            color: #fff;
          }
        }
      }

      .cart-buttons {
        font-size: 1.375rem;
        display: flex;
        margin-bottom: 30px;
        flex-wrap: wrap;
        gap: 15px;

        .out-of-stock {
          // color: #53B175;
          color: #D32F2F;
          color: #808080;
          font-size: 16px;
          font-weight: 500;
        }

        .quantity-buttons {
          background-color: rgba(249, 249, 249, 1);
          display: flex;
          align-items: baseline;
          border-radius: 30px;
          font-weight: 700;
          color: #3a4980;

          .selected-quantity{
            padding-inline: 10px;
          }

          .cart-button {
            font-size: 24px;
            padding: 15px 30px;
            cursor: pointer;
            border-radius: 30px;
            font-weight: bold;

            &:hover {
              background-color: rgba(83, 177, 117, 0.1);
            }
            &:active {
              background-color: rgb(186 224 200);
              color: #fff;
            }
            .cart-button-img {
              width: 20px;
            }
          }
        }

        .add-to-cart-button {
          background-color: rgba(83, 177, 117, 1);
          padding: 15px 90px;
          color: #fff;
          border-radius: 30px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
    .go-to-cart-button {
      background-color: rgba(83, 177, 117, 1);
      padding: 15px 46px;
      color: #fff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      gap: 10px;
      // margin-left: 15px;
      cursor: pointer;
      // font-weight: 700;
      // color: #3a4980;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
        @media screen and (max-width: 320px) {
          .add-to-cart-button {
            padding: 15px 60px;
          }
        }
      }

      .delivery-details {
        border: 1px solid rgba(228, 228, 228, 1);
        border-radius: 17px;
        padding: 17px;

        .delivery-card {
          display: flex;
          gap: 14px;

          .free-delivery-img-container {
            width: 24px;

            .free-delivery-img {
              margin-top: 5px;
              width: 100%;
            }
          }

          .delivery-info {
            .delivery-title {
              font-weight: 700;
              font-size: 1rem;
              color: rgba(29, 54, 77, 1);
              margin-bottom: 5px;
            }

            .info {
              font-weight: 400;
              font-size: 14px;
              color: rgba(114, 108, 108, 1);
            }
          }
        }

        .product-line {
          margin-block: 21px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
      .left {
        width: 100%;
        margin-bottom: 30px;
        .image-slider {
          .main-img {
            position: sticky;
            top: 200px;
            width: 100%;
            border-radius: 10px;
            padding-bottom: 5px;
            max-height: 300px;
          }
        }
        
      }
      .right {
        width: 100%;
      }
    }
  }

  .tabs-details {
    margin-bottom: 30px;
    
    .tab-container {
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      margin-bottom: 18px;

      .tabs {
        .tab {
          display: inline-block;
          padding: 15px 30px;
          background-color: rgba(248, 248, 248, 1);
          border-top: 3px solid rgba(248, 248, 248, 1);
          cursor: pointer;
        }

        .active {
          background-color: #fff;
          border-top: 3px solid rgba(26, 164, 136, 1);
        }
      }
    }

    .details-container {
      font-size: 15px;
      min-height: 200px;

      .details-table {
        max-width: 500px;
        width: 100%;
        background-color: rgba(249, 249, 249, 1);
        padding: 15px;

        tr {
          display: table-row;
          td {
            padding: 4px;
          }

          td:nth-child(1) {
            width: 50%;
          }

          td:nth-child(2) {
            font-weight: 500;
          }
        }
      }
      
    }
  }
  .tabs-details-mobile {
    display: none; 
  }
  @media screen and (max-width: 768px) {
    .tabs-details {
      display: none;
    }
  
    .tabs-details-mobile {
      display: block;
      margin-bottom: 20px;
  
      .tab-container-mobile {
        border-bottom: 1px solid #ececec;
  
        .tab-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          cursor: pointer;
          font-size: 20px;
          margin-bottom: 20px;
          font-weight: 600;
  
          &:hover {
            // background-color: #f0f0f0;
          }
  
          &.active {
            background-color: #fff;
            border-bottom: 1px solid rgba(26, 164, 136, 1);
          }
  
          .arrow-mobile {
            font-size: 18px;
            color: rgba(26, 164, 136, 1);
          }
        }
  
        .details-container-mobile {
          background-color: #fff;
          overflow: hidden;
          // max-height: 1000px; 
          transition: max-height 0.4s ease-in-out, padding 0.4s ease;
  
          &.hidden {
            max-height: 0;
            padding: 0;
          }
  
          .details-table-mobile {
            width: 100%;
            // padding: 10px 0;
            // gap: 20px;
  
            tr {
              // margin-bottom: 10px;

              td {
                font-size: 15px;
                height: 31px;
                // padding: 20px  0;
                // margin-bottom: 20px;
                padding: 0 !important;
                &:nth-child(1) {
                  width: 185px;
                  max-width: 185px;
                  color: #777777;
                  font-weight: 400;
                }
                &:nth-child(2) {
                  color: #222222;
                  font-weight: 500;
                }
              }
            }
          }
  
          div {
            padding: 20px 0;
            font-size: 14px;
            margin-bottom: 30;
            line-height: 1.6;
          }
        }
      }
    } 
  }

  .similar-products {
    .similar-products-title {
      font-size: 30px;
      font-weight: 700;
      color: rgba(52, 64, 84, 1);
      margin-bottom: 30px;
    }

    .product-row {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 20px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .info-item {
    font-size: 16px;
    line-height: 24px;
  }

  .info-item .text-bold {
    font-weight: 500;
  }

  .category {
    font-size: 14px;
    line-height: 20px;
    color: #6b6b6b;
  }

  @media (min-width: 768px) {
    .category {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .seller-buttons {
    display: flex;
    gap: 10px;
    /* Adjust the gap between buttons */
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .seller-button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    border: 2px solid black;
  }

  .seller-button.selected {
    background-color: #007bff;
    color: #fff;
  }
}
