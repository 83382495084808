/* Slideshow container */
.slideshow-container {
  // width: 100%;
  position: relative;
  margin-bottom: 80px;

  @media (max-width: 650px) {
    margin: 0 0 80px 0;
  }

  .mySlides {
    display: none;

    .carousel-img {
      vertical-align: middle;
      width: 100%;
      object-fit: cover;
      height: 350px;
      border-radius: 20px;

      @media (max-width: 650px) {
        height: 300px;
        border-radius: 0;
        // margin-top: 10px;
      }
    }
  }

  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:active,
  .next:active {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media only screen and (max-width: 768px) {

    .prev,
    .next{
      // display: none;
    }
  }

  /* On smaller screens, decrease text size */
  @media only screen and (max-width: 300px) {

    .prev,
    .next,
    .text {
      font-size: 11px;
    }
  }

  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    display: none;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    display: none;
  }
}

.carousel-dots {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
  @media (max-width:370px) {
    width: 100%;
    
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    @media (max-width: 650px) {
      height: 10px;
      width: 10px;

    }
  }

  .active,
  .dot:hover {
    background-color: #717171;
  }
}