.HeaderLinears {
  width: 100%;
  height: 100px;
  // background: linear-gradient(180deg, #53b175 0%, #b3dec3 100%);
  background-image: linear-gradient(75.04deg, #f4e8f3 0%, #f3eff6 52.07%, #eee0f9 102.02%);
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: #000;
  }
}
.headerTag {
  padding: 20px 0 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-block: 0;

  .headerText {
    cursor: pointer;
  }
}

.headerChildDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .headerTag {
    padding-right:  20px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-block: 0;
    justify-content: space-between;
    margin-bottom: 30px;
  
  //   .cart-navigation{
  
  //     display: flex;
  //     align-items: center;
  
  
  //   .headerText {
  //     cursor: pointer;
  //   }
  // }
    // .clear-cart{
    //   padding: 8px 26px;
    //   border-radius: 20.5px;

    //   // border: 2px solid #e2e2e2;
    //   // color: #fff;
    //   // font-weight: 600;
    //   // background-color: #000000;

    //   // color: #fff;
    //   // font-weight: 600;
    //   // background-color: #000000;

    //   // color: #fff;
    //   // font-weight: 400;
    //   // background-color: #53b175;

    //   color: #000;
    //   font-weight: 400;
    //   background-color: #effff4;
    //   // background-color: #000000;
    //   //       background-color: #000000;



    //     font-size: 16px;
    //     text-wrap: nowrap;
    //     cursor: pointer;
    // }
  }

  .empty-cart-container {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    height: 210px;

    .empty-cart-text {
      font-weight: bolder;
      font-size: x-large;
      margin: auto;
    }
  }
}

.itemClass {
  display: flex;
  align-items: center;
  gap: 20px;
}

.priceKg {
  font-size: 13px;
  padding: 7px 0;
}

.countDiv {
  width: 100px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  position: relative;

  .out-of-stock {
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 50%);
    color: #D32F2F;
    font-size: 13px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    text-align: center;
  }

  .sign-button {
    padding: 9px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .increment-btn:disabled > .vectorIcon {
    cursor: not-allowed!important;
  }
}

.tableTd {
  padding: 0 50px;
  text-align: center;
}

.vectorIcon {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.vectorIcons {
  width: 13px;
  height: 13px;
  cursor: pointer;
  padding-left: 30px;
}

.flexChild {
  display: flex;
  align-items: center;
}

.itemClass1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.discount1 {
  width: 70px;
  padding: 4px;
  text-align: center;
  border-radius: 28px;
  background: #53b175;
  color: #fff;
  font-size: 11.2px;
  position: relative;
  z-index: 1;
  font-weight: 400;
}

.mobile-cart {
  display: none;
}

.responsiveTableWrapper {
  width: 100% !important;
}

.responsiveTables {
  width: 100%;
}

.responsiveTable {
  width: 100% !important;
  padding: 0px 20px 0px 0px;
}

.responsiveTable th,
.responsiveTable td {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
  text-align: left;
}

.buttonMainDiv {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 0px;
  margin: 20px 0px;
}

.buttonMainDivs {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.ownText {
  font-weight: 800;
}

.ownTexts {
  margin-left: 8px;
}

.ContinueButton {
  border: 1px solid #a2a6b0;
  padding: 10px 20px;
  border-radius: 24px;
  margin: 0px 10px 10px 0px;
  text-decoration: none;
  color: #000000;
  font-size: 13.333px;
  cursor: pointer;
}

.ClearButton {
  background-color: #000000;
  color: white;
  border: 1px solid #a2a6b0;
  padding: 10px 20px;
  margin: 0px 10px 10px 0px;
  border-radius: 24px;
  cursor: pointer;
}

.conClearButton {
  display: flex;
  flex-wrap: wrap;
}

.Proceed-button {
  background-color: rgba(83, 177, 117, 1);
  padding: 12px 66px;
  color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.Check-button {
  background-color: #ffb800;
  padding: 12px 50px;
  color: #232c65;
  border-radius: 30px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .pyImage {
    width: 60px;
  }
}

.address-button {
  padding: 12px 20px;
  color: #a2a6b0;
  border-radius: 30px;
  display: flex;
  border: 1px solid #a2a6b0;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.zipImage {
  width: 60px;
}

.zipImgMain {
  margin-top: 20px;
  font-size: 11px;
  color: #272560;
  align-items: center;
  display: none;
}

.itemsDetails {
  width: 150px;

  .storeDetails {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    .storeImage {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }

    .storeName {
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.summryDiv {
  width: 35%;
  // min-width: 320px;
  // background-color: #f5f7ff;
  align-self: flex-start;
  padding: 20px;
  border-radius: 10px;
  background-color: #effff4;
  box-shadow: 0px 0px 5px 1px rgba(221, 221, 221, 0.5);

  .summryTitle {
    padding: 0px 0px 20px;
  }

  .summryChild {
    display: flex;
    font-size: 14px;
    padding: 10px 0px;
    justify-content: space-between;
  }

  .childColor {
    color: gray;
  }
}

@media screen and (max-width: 992px) {
  .headerChildDiv {
    display: flex;
    flex-wrap: wrap;
  }

  .summryDiv {
    width: 100%;
  }

  .responsiveTableWrapper {
    width: 100%;
    overflow-x: auto;
  }

  .responsiveTable {
    min-width: 600px;
  }

  .mobile-cart {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .headerChildDiv {
    display: flex;
    flex-wrap: wrap;
  }

  .ContinueButton {
    border: 1px solid #a2a6b0;
    padding: 10px 13px;
    margin: 0px 10px 10px 0px;
  }

  .ClearButton {
    background-color: #000000;
    color: white;
    border: 1px solid #a2a6b0;
    padding: 10px 10px;
    margin: 0px 10px 10px 0px;
  }

  .buttonMainDiv {
    padding: 0px;
  }

  .zipImage {
    width: 34px;
  }

  .zipImgMain {
    margin-top: 20px;
    font-size: 12px;
    align-items: center;
    color: #272560;
    display: flex;
  }

  .itemImage {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  .responsiveTableWrapper {
    width: 100% !important;
  }

  .discount1 {
    font-size: 9px;
  }

  .tableTd {
    padding: 10px;
  }

  .mobile-cart {
    display: none;
  }

  .summryDiv {
    margin-top: 20px;
  }
}

.similar-products {
  margin-top: 30px;

  .similar-products-title {
    font-size: 30px;
    font-weight: 700;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 30px;
  }

  .product-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (max-width: 680px) {
  .responsiveTables {
    display: none;
  }

  .headerChildDiv {
    .empty-cart-container {
      div {
        width: 280px !important;
        height: 280px !important;
      }
    }
  }


  .mobile-cart {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #fff;
    overflow-x: hidden;
    width: 100%;
  }
  .clear-cart{
    padding: 8px 20px;
      font-weight: 400;
      border: 2px solid #e2e2e2;
      border-radius: 20.5px;
      font-size: 14px;
      color: #e7e7e7;
      text-wrap: nowrap;
      cursor: pointer;
      margin-left: auto;
    
  }

  .itemClass-mobile {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    gap: 15px;
    padding: 16px 0;
    max-width: 100%;
  }

  .itemClass1-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .itemImage-mob {
      width: 85px;
      height: 85px;
      padding: 5px;
      object-fit: contain;
    }
  }

  .itemsDetails-mob {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    max-width: 100%;

    .row1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      max-width: 100%;

      .productInfo-mob {
        flex: 1;

        .itemName-mob {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          word-break: break-word;

        }

        .priceKg-mob {
          font-size: 14px;
          color: #888;
          margin: 4px 0 0;
        }
      }

      .vectorIcon-mob {
        background: none;
        border: none;
        font-size: 20px;
        color: #dc3545;
        cursor: pointer;
      }
    }

    .row2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      max-width: 100%;
      flex-wrap: wrap;

      .countDiv {
        width: 100px;
        border: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: space-between;

        .sign-button {
          padding: 9px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .priceRow {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 5px;
        gap: 10px;

        .discountedPrice-mob {
          font-size: 16px;
          font-weight: bold;
          color: #28a745;
        }

        .strikethrough-mob {
          font-size: 14px;
          color: #888;
          text-decoration: line-through;
        }
      }
    }
  }
  
}

@media only screen and (max-width: 340px) {
  .Proceed-button {
    padding: 12px 50px;
  }
}