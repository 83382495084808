.category-item {
    width: 130px;
    flex-shrink: 0;
    padding: 10px;
    

    .category-icon {
      width: 130px;
      height: 130px;
      object-fit: contain;   
      // border-radius: 50%;
      // box-shadow: 0 0 10px 1px #e7e7e7; 
      transform: scale(1);
      transition: transform 0.2s;
      cursor: pointer;
      
      &:hover{
        // box-shadow: 0 0 10px 2px #bebebe; 
        transform: scale(1.1);
      }
    }

    .shop-by-category-labels {
      padding-bottom: 5px;
      p {
        color: #010101;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-top: 10px;
      }
    }

    .shop-by-category {
      margin: 0;
      position: relative;
      font-size: var(--font-size-21xl);
      font-weight: 400;
      font-family: inherit;
      text-align: left;
      display: inline-block;
      max-width: 100%;
    }

    @media screen and (max-width: 800px) {
      .shop-by-category {
        font-size: var(--font-size-13xl);
      }
    }
  }