.dashboard {
  width: 100%;

  .profile-section {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;

    .profile-card {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .profile-card-content {}
    }

    .profile-card p {
      font-size: 14px;
      padding-bottom: 10px;
    }

    .profile-card,
    .billing-address {
      width: 50%;
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #e6e6e6;

      h5 {
        font-size: 14px;
        color: #999999;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .address-content{
      .bill-name{
        color: #1A1A1A;
        font-weight: 500;
        font-size: 18px;
        word-break: break-all;
        margin-bottom: 10px;
      }

      .bill-address{
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        overflow-wrap: break-word;

      }
      .bill-mobile{
        font-size: 14px;
        font-weight: 400;
        color: #1A1A1A;
        margin-bottom: 20px;
      }
    }

      &:nth-child(1) {
        font-weight: bold;
        color: #1a1a1a;
      }

      // margin-bottom: 10px;
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 30px;
        padding-bottom: 30px;

        // padding: 0 20px;
        &> :nth-child(1) {
          color: #666666;
          font-weight: 500;
        }

        &> :nth-child(2) {
          font-size: 18px;
          font-weight: 500;
        }

        &> :nth-child(3) {
          color: #666666;
          font-size: 14px;
        }
      }

      h4 {
        margin: 10px 0;
        word-break: break-all;
      }
      
      

      .profile-image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .profile-text {
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        width: 80px;
        height: 80px;
        background-color: #53b175;
        border-radius: 50%;
        font-size: larger;
        font-weight: bolder;
        color: white;
      }

      .edit-profile,
      .edit-address {
        color: #34a853;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        margin-top: 21px;
      }
    }
  }
  .order-history {
    .history-table-wrapper {
      max-height: unset;
    }
  }

  .loading-text {
    text-align: center;
    margin-block: 30px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    margin-left: 0;

    .profile-section {
      flex-direction: column;
      gap: 29px;

      .profile-card,
      .billing-address {
        width: 100%;
      }
    }

  }
}

@media (max-width: 768px) {
  .address-container {
    padding: 10px;

    .header h2 {
      font-size: 20px;
    }

    .address-card {
      padding: 15px;
    }

    .actions .remove-btn,
    .actions .edit-btn {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 576px) {
  .address-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .headerMenus {
    padding: 20px;
    display: flex;
    flex-wrap: wrap !important;
    width: 100%;
  }

  .summryDiv {
    width: 100% !important;
  }

  .summryDivs {
    margin-top: 30px;
  }

  .headerChild {
    padding: 0px !important;
  }

  .HomeNavigate {
    position: absolute;
    top: 185px;
    z-index: 10;
  }

  .discount2 {
    font-size: 9px;
  }
}