.order-placed {
    text-align: center;
    margin-block: 50px;
    padding-inline: 20px;
    
    .graffiti {
        width: 200px;
        margin-bottom: 30px;
    }
    .text {
        font-weight: 500;
        margin-bottom: 25px;
    }
    
    .sub-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }
}