.mobile-order-card {
    background-color: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0px 0px 8px 1px #0000001a;
    min-width: 250px;

    // &:last-child {
    //   box-shadow: 0px 0px 8px 1px red;
    // }

    .order-detail {
      display: flex;
      gap: 7px;
      align-items: center;
      background-color: #f8f9fc;
      border-top-right-radius: 20px 20px;
      border-top-left-radius: 20px 20px;
      padding: 20px;

      .order-product {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 40%;
        gap: 7px;
        flex-grow: 1;
      }

      .order-id {
        font-size: 12px !important;
        font-weight: 400 !important;
        color: #7e8392 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .order-status {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        border-radius: 4px;
        border: 0px solid #fff;
        background-color: #e6e9ef;
        padding: 2px 4px;
      }

      .total-status {
        font-size: 14px;
        font-weight: normal;
        color: #333;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 4px;

        @media screen and (max-width: 435px) {
          align-items: flex-start;
        }

        span {
          display: inline-block;
          background-color: #e0e0e0;
          border-radius: 12px;
          padding: 4px 8px;
          font-size: 12px;
          color: #333;
        }

        &.processing {
          span {
            background-color: #f0f9f9;
            color: #4caf50;
          }
        }
      }
    }

    .delivery-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 10px 0;
      padding: 20px;

      .order-date {
        font-weight: 500;
        font-size: 14px;
      }

      .date-view {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        flex-wrap: wrap;

        .view-details {
          color: #4caf50;
          cursor: pointer;
          font-weight: bold;
          margin-left: 16px;
          font-size: 14px;

          .arrow-icon {
            color: #9197a6;
            padding-left: 5px;
            white-space: nowrap;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }