.issue-product-details {
    width: 100%;
  
    .issue-product-row {
      display: flex;
      align-items: center;
      padding: 15px 0;
      gap: 10px;
      border-top: 1px dashed #e6e6e6;
  
      input[type="checkbox"] {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        border: 1.5px solid #979797; 
        border-radius: 4px; 
        background-color: transparent;
        display: inline-block;
        -webkit-appearance: none; 
        appearance: none;
        cursor: pointer;
  
        &:checked {
          background-color: #00B207; 
          // border-color: #00B207; 
          position: relative;
  
          &::after {
            content: "";
            display: block;
            width: 3px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            top: -1px;
            left: 3px;
          }
        }
      }
  
  
      .issue-product-image {
        width: 50px;
        height: 50px;
        border-radius: 8px;
        object-fit: contain;
      }
  
      .issue-product-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-start;
        padding: 0;
        background-color: transparent;
  
        .issue-product-name {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 4px;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
  
        .issue-product-quantity {
          font-size: 12px;
          color: #666;
        }
      }
  
      .issue-product-price {
        font-weight: bold;
        font-size: 14px;
        white-space: nowrap;
        color: #000;
      }
    }
    .product-row:first-child {
      border-top: 0;
    }
  }
