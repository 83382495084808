.address-container {
  width: 100%;

  .add-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #e5e5e5;
    border-radius: 8px 8px 0 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    .add-address {
      color: #4caf50;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .add-address-content {
    padding: 24px;
    border: 1px solid #e6e6e6;
    border-top: 0;
    border-radius: 0 0 8px 8px;

    .google-content {
      .google-fields {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 25px;

        .g-search-wrapper {
          position: relative;
          flex: 1;

          .g-search-input {
            border: 1px solid #b0dcc0;
            padding: 19px 50px 20px 30px;
            border-radius: 27px;
            width: 100%;
            transition: border-radius 0.3s, border 0.3s;
        
            &.with-suggestions {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              border-bottom: 0;
            }
          }

          .g-search-lens {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
          .suggestions-dropdown{
            border: 1px solid #ddd;
            background: #fff;
            position: absolute;
            width: 100%;
            // max-height: 150px;
            overflow-y: auto;
            // border-radius: 8px;
            z-index: 10;
            // top: 39px;
            padding-bottom: 10px;
            border-bottom-left-radius: 27px;
            border-bottom-right-radius: 27px
          }
          
          .suggestion-item {
            padding: 10px;
            cursor: pointer;
            text-align: start;
          }
          
          .suggestion-item:hover {
            background-color: #f0f0f0;
          }
        }

        .g-current-location-btn {
          display: flex;
          gap: 10px;
          align-self: center;
          .g-current-location-icon {
            width: 21px;
            height: 21px;
          }
        }
      }
      .google-add {
        display: flex;
        gap: 10px;
        margin-top: 30px;
        font-weight: 500;
        align-items: center;
        .google-add-icon {
          width: 40px;
          align-self: flex-start;
          vertical-align: top;
        }
        
      }
      
    }

    .add-adrs-btns {
      display: flex;
      gap: 30px;
    }

    .add-save-btn {
      margin-top: 25px;
      background-color: #6fae79;
      padding: 12px 66px;
      color: #fff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    .add-address-form {
      width: 100%;

      .inputFlex {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 30px;

        .newDic {
          flex: 1 1 48%;
          display: flex;
          flex-direction: column;

          .error-text {
            color: red;
            font-size: 12px;
            margin-top: 4px;
          }

          .error-border {
            border-color: red;
          }

          .inputLable {
            color: #7c7c7c;
            margin-bottom: 5px;
          }

          .inputBorder {
            padding: 10px 1px;
            border-bottom: 2px solid #e2e2e2;
            background: transparent;
            outline: none;
            font-size: 18px;
          }

          .inputBorder:focus {
            border-bottom: 2px solid #b0dcc0 !important;
          }

          .iconWithTexts {
            display: flex;
            margin-top: 5px;
            gap: 20px;
            align-items: center;

            .iconWithText {
              cursor: pointer;
              display: flex;
              gap: 10px;
              align-items: center;

              .saveIcon {
                width: 35px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .google-fields {
    flex-direction: column;
    gap: 15px;
  }
}

@media (min-width: 580px) and (max-width: 768px) {
  .google-fields {
    gap: 80px !important;
  }
  .add-address-content {
    padding: 0 !important;
    border: 0 !important;
    margin: 31px 0 !important;
  }
  .add-header {
    padding: 0 !important;
    padding-bottom: 10px !important;
    border: 0 !important;
    border-bottom: 1px solid #d5d9d9 !important;
  }
  .google-fields {
    justify-content: unset;
    flex-direction: unset;
  }
  .g-current-location-btn {
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #676767;
  }
  .inputFlex {
    margin-top: 23px !important;
  }
  .inputBorder {
    border-bottom: 1px solid #e2e2e2 !important;
  }
  .inputLable {
    font-size: 14;
    font-weight: 400;
    color: #7c7c7c;
  }
  .iconWithText {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
  .add-save-btn {
    padding: 10px 30px !important;
  }
}

@media (max-width: 580px) {
  .add-address-content {
    padding: 0 !important;
    border: 0 !important;
    margin: 31px 0 !important;
  }
  .add-header {
    padding: 0 !important;
    padding-bottom: 10px !important;
    border: 0 !important;
    border-bottom: 1px solid #d5d9d9 !important;
    gap: 2px;
    display: flex;
    justify-content: space-between;

    h2 {
      white-space: nowrap;
      max-width: calc(100% - 80px);
      transition: font-size 0.2s ease;
      @media (max-width: 360px) {
        font-size: 16px !important;
      }
    }
    div {
      white-space: nowrap;
      max-width: calc(100% - 80px);
      font-size: 16px !important;
      @media (max-width: 360px) {
        font-size: 14px !important;
      }
    }
  }
  .add-address-form {
    .inputFlex {
      display: flex;
      flex-direction: column;
    }
  }

  .g-current-location-btn {
    align-self: flex-end !important;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #676767;
  }
  .g-current-location-icon {
    width: 16px !important;
    height: 16px !important;
  }

  .iconWithTexts {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: 7px !important;

    .iconWithText {
      flex-basis: calc(25.33% - 16px);
      display: flex;
      align-items: center;
      text-align: center;
      gap: 10px !important;
      .saveIcon {
        width: 26px !important;
        height: 26px !important;
      }

      span {
        font-size: 14px;
      }
    }
  }
}
