.festive-product-page {
    .festive-banner {
      padding: 50px 10px;
      display: flex;
      // display: none;
      justify-content: center;
      background-image: linear-gradient(75.04deg, #f4e8f3 0%, #f3eff6 52.07%, #eee0f9 102.02%);
      // background-image: url(../../assets/images/category-product-list/pattern_white.jpg),
      // url(../../assets/images/category-product-list/grocery_banner.jpg), 
      // linear-gradient(75.04deg,
      // #f4e8f3 0%,
      // #f3eff6 52.07%,
      // #eee0f9 102.02%);
      // background-repeat: repeat, no-repeat, no-repeat;
      // background-position: top left, center, top left;
      // background-size: auto, contain, auto;
  
      .festive-banner-content {
        text-align: center;
  
        .festive-breadcrumb {
          font-size: 13px;
          padding-top: 7px;
          color: #53b175;
        }
  
      }
    }
    .festive-list-section {
        margin-top: 42px;
        max-width: 1400px;
        margin-inline: auto;
        font-family: "inter";
        margin-bottom: 80px;
    
        .search-sort-row {
          margin-bottom: 10px;
        }
    
        .hracpl {
          border: 1px solid #D2D2D2;
          margin-bottom: 20px;
        }
    
        .filter-and-product-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 16px;
    
          .left-content {
            display: none; // uncomment this before code push
          }
    
          .right-content {
            width: 100%;
    
            .selected-filters-row {
              display: flex;
              display: none; // uncomment this before code push
              gap: 10px;
    
              .selected-filter {
                border: 1.5px solid #E2E2E2;
                border-radius: 5px;
                padding-block: 9px;
                padding-inline: 12px 30px;
                font-size: 12px;
                position: relative;
    
                &::after {
                  content: "\00D7";
                  position: absolute;
                  right: 10px;
                }
              }
            }
    
            .cardsContainer {
              display: grid;
              gap: 16px;
              margin-top: 13px;
              grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
              flex-wrap: wrap;
            }
          }
        }
    
        .shopByCategoryHeading {
          font-family: --var(font-public-sans);
          color: #000;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 16px;
        }
    
        .categoryList {
          .category-row {}
        }
    
        @media (max-width: 1440px) {
          margin-inline: 20px;
        }
      }
}