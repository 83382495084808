@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy-Medium:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes:wght@400&display=swap");

body {
  margin: 0;
  line-height: normal;
}

p {
  margin-bottom: 0;
}

.root {
  margin: 0px;
}

.row-container {
  max-width: 1400px;
  margin-top: 30px;
  margin-inline: auto;
  font-family: "inter";
  margin-bottom: 80px;

  @media (max-width: 1440px) {
    margin-inline: 20px;
  }
}

:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-public-sans: "Public Sans";
  --font-inter: Inter;
  --font-great-vibes: "Great Vibes";
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-gilroy-medium: Gilroy-Medium;
  --font-source-sans-pro: "Source Sans Pro";
  --font-gilroy: Gilroy;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-smi: 13px;
  --font-size-base: 16px;
  --font-size-4xl: 23px;
  --font-size-11xl: 30px;
  --font-size-xs: 12px;
  --font-size-xs-8: 11.8px;
  --font-size-smi-4: 12.4px;
  --font-size-lg-5: 18.5px;
  --font-size-base-1: 15.1px;
  --font-size-xl: 20px;
  --font-size-xs-2: 11.2px;
  --font-size-mini-2: 14.2px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-19xl: 38px;
  --font-size-lg: 18px;
  --font-size-77xl: 96px;
  --font-size-10xl: 29px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #020203;
  --color-gray-200: #010101;
  --color-gray-300: rgba(0, 0, 0, 0.4);
  --color-gray-400: rgba(0, 0, 0, 0.7);
  --color-gray-500: rgba(255, 255, 255, 0.5);
  --color-mediumseagreen-100: #53b175;
  --color-mediumseagreen-200: #22a87a;
  --color-black: #000;
  --color-whitesmoke-100: #eee;
  --color-whitesmoke-200: #ededed;
  --color-3: #0156ff;
  --color-darkgray-100: #ababab;
  --color-5: #a2a6b0;
  --color-darkgray-200: #989898;
  --color-1: #f5f7ff;
  --color-cornflowerblue: #01a3ff;
  --color-seagreen-100: #21a57f;
  --color-seagreen-200: #3b965c;
  --color-dimgray: #676767;
  --color-darkseagreen: #b0dcc0;

  /* Gaps */
  --gap-lgi: 19px;
  --gap-5xl: 24px;
  --gap-xl: 20px;
  --gap-4xl: 23px;
  --gap-smi: 13px;
  --gap-10xl: 29px;
  --gap-38xl: 57px;
  --gap-lg: 18px;
  --gap-base: 16px;
  --gap-13xl: 32px;
  --gap-8xl-6: 27.6px;
  --gap-base-9: 16.9px;
  --gap-12xs-2: 0.2px;
  --gap-sm-6: 13.6px;
  --gap-9xl: 28px;
  --gap-21xl-9: 40.9px;
  --gap-mini: 15px;
  --gap-3xs-4: 9.4px;
  --gap-3xs-2: 9.2px;
  --gap-mid: 17px;
  --gap-sm-1: 13.1px;
  --gap-3xs-3: 9.3px;
  --gap-mid-5: 17.5px;
  --gap-2xl-4: 21.4px;
  --gap-sm: 14px;
  --gap-8xs: 5px;
  --gap-9xs-9: 3.9px;
  --gap-29xl: 48px;

  /* Paddings */
  --padding-28xl: 47px;
  --padding-xl: 20px;
  --padding-12xl: 31px;
  --padding-mid: 17px;
  --padding-12xs: 1px;
  --padding-8xs: 5px;
  --padding-11xs: 2px;
  --padding-5xl: 24px;
  --padding-2xl: 21px;
  --padding-9xs: 4px;
  --padding-7xs: 6px;
  --padding-10xl: 29px;
  --padding-40xl: 59px;
  --padding-7xl: 26px;
  --padding-16xl: 35px;
  --padding-6xl: 25px;
  --padding-4xl: 23px;
  --padding-8xl: 27px;
  --padding-9xl: 28px;
  --padding-base-6: 15.6px;
  --padding-19xl: 38px;
  --padding-lgi: 19px;
  --padding-22xl-9: 41.9px;
  --padding-42xl: 61px;
  --padding-43xl: 62px;
  --padding-mini-2: 14.2px;
  --padding-10xs: 3px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-64xl-6: 83.6px;
  --padding-15xl: 34px;
  --padding-14xl: 33px;
  --padding-lg: 18px;
  --padding-xs: 12px;
  --padding-126xl: 145px;
  --padding-36xl: 55px;
  --padding-sm: 14px;
  --padding-37xl: 56px;
  --padding-smi: 13px;

  /* Border radiuses */
  --br-31xl: 50px;
  --br-9xs: 4px;
  --br-xl: 20px;
  --br-3xl-5: 22.5px;
  --br-7xl-5: 26.5px;
  --br-4xs-4: 8.4px;
  --br-9xs-4: 3.4px;
  --br-8xs: 5px;
  --br-3xl: 22px;
  --br-9xl: 28px;
  --br-mid: 17px;
  --br-base-1: 15.1px;
  --br-3xs: 10px;
  --br-5xs: 8px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.buyer-form-control {
  display: block;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  border: 1.5px solid #acacac;
  outline: 0;
  border: 1px solid #b0dcc0;
  padding: 10px;
  border-radius: 18px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.popup-box {
  padding: 30px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 30px;

  .close-cross {
    width: 14px;
    display: block;
    margin-left: auto;
    cursor: pointer;
  }

  .popup-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #182035;
  }

  .popup-content {
    text-align: left;
    margin-bottom: 15px;
    line-height: 1.6;
    color: #666666;
  }

  .popup-button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
    margin-left: auto;
    max-width: fit-content;

    .popup-button {
      padding: 10px 40px;
      border-radius: 30px;
      cursor: pointer;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .popup-reject {
      background-color: rgba(215, 215, 215, 1);
      color: #666666;
    }

    .popup-accept {
      background-color: rgba(83, 177, 117, 1);
      color: #fff;
    }
  }
}