.address-container {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #e5e5e5;
    border-radius: 8px 8px 0 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    .add-address {
      color: #00b207;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .address-grid {
    padding: 24px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    border: 1px solid #e6e6e6;
    border-top: 0;
    border-radius: 0 0 8px 8px;
  }

  .address-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;

    .name {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
      color: #1a1a1a;
      line-height: 24px;
      word-break: break-all;
    }

    .address {
      color: #666666;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 400;
      // height: 50px;
      overflow: hidden;
      word-break: break-all;
    }


    .add-contact {
      color: #999999;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 27px;

      p:first-child {
        font-size: 12px;
        margin-bottom: 4px;
      }

      p:last-child {
        color: #1a1a1a;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      margin-top: auto;

      .remove-btn,
      .edit-btn {
        background: none;
        border: none;
        outline: none;
        font-size: 14px;
        line-height: 21px;
        color: #ef4f5f;
        font-weight: 500;
        cursor: pointer;
        margin-right: 11px;
        padding-inline: 5px;
        border-radius: 5px;
      }

      .remove-btn:hover,
      .edit-btn:hover {
        background-color: #eee;
      }

      .edit-btn {
        color: #4caf50;
        margin-right: 0 !important;
      }

      .type {
        display: flex;
        align-items: center;
        margin-left: auto;
        line-height: 22px;
        font-size: 14px;

        img {
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
      }
    }
  }
  .selected-add {
    border: 1px solid #4caf50;
  }
}


.no-address {
  
  text-align: center;
  font-size: 16px;
  // position: absolute;
  top: 50%;
  // transform: translateY(-50%);
  width: 100%;
  margin-block: 80px;

  

.no-address img {
  width: 140px;
  height: 140px;
  margin-bottom: 8px;
}
.no-address-add-address {
  color: #00b207;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  cursor: pointer;
}
}

@media screen and (min-width: 580px) and (max-width: 850px) {
  .address-container {
    padding: 0 !important;
  }
  .address-grid {
    display: grid;
    grid-template-columns: auto auto !important;
    padding: 0 !important;
    border: 0 !important;
    margin-top: 28px !important;
    margin-bottom: 28px !important;
    gap: 19px !important;
  }
  .address-card {
    border-radius: 6px !important;
  }
  .header {
    border: 0 !important;
    justify-content: unset !important;
    flex-direction: row !important;
    border-bottom: 1px solid #d5d9d9 !important;
    padding: 0 !important;
    padding-bottom: 14px !important;
    h2 {
      flex-grow: 1;
    }
  }

  .address {
    padding-right: 54px;
  }
  .actions {
    padding-right: 54px;
  }
}

@media screen and (max-width: 580px) {
  .address-container {
    padding: 0 !important;
  }
  .address-grid {
    padding: 0 !important;
    border: 0 !important;
    margin-top: 28px !important;
    margin-bottom: 28px !important;
    gap: 16px !important;
  }
  .address-card {
    border-radius: 6px !important;
  }
  .header {
    border: 0 !important;
    justify-content: unset !important;
    flex-direction: row !important;
    border-bottom: 1px solid #d5d9d9 !important;
    padding: 0 !important;
    padding-bottom: 18px !important;
    h2 {
      flex-grow: 1;
    }
  }

  .address {
    padding-right: 32px;
  }
  .actions {
    padding-right: 32px;
  }
}
