.content-cancel {
    width: 100%;
    // height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px;
    // .content.blur {
    //   filter: blur(5px);
    //   pointer-events: none;
    // }
    
    .order-header-cancel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 15px;
    
    
    
        .back-to-list-cancel {
          color: rgba(83, 177, 117, 1);;
          font-weight: bold;
          text-decoration: none;
          cursor: pointer;
        }
      }
      .tell-us-problem-cancel{
        padding: 25px 0;

      }
      .select-products-cancel{
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .order-form-cancel{
        margin-bottom: 40px;
      }
      .describe-issue-cancel{
        padding-bottom: 20px;
        border-bottom: 1px dashed #e6e6e6;
        margin-bottom: 20px;

        
    }
    .reason-options-cancel {
        margin: 5px 0;
        padding: 10px 0;

        .reason-item-cancel {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #D5D9D9;
            margin-bottom: 20px;

            input[type="radio"] {
                margin-right: 10px;
            }

            label-cancel {
                font-size: 14px;
            }
        }
    }
}
    
    
.submit-issue-btn-cancel {
    margin-top: 20px;
    background-color: rgba(83, 177, 117, 1);
    color: #fff;
    padding: 10px 60px;
    text-align: center;
    border-radius: 30px;
    display: inline-flex; 
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: auto; 
    max-width: 80%; 
    min-width: 120px; 
    cursor: pointer;
    font-size: 1rem; 
    white-space: nowrap;
  
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  @media  screen and (max-width:770px) {
    .content-cancel {
      border: 0;
      padding: 0;
    }
    
  }
  @media  screen and (max-width:430px) {
    .content-cancel {
    .order-header-cancel {
      flex-wrap: wrap-reverse;
      .back-to-list-cancel {
      font-weight: 400;
      // margin-bottom: 10px;
      width: 100%;
    }

    }
  }
    
  }