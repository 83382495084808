.home-page {
  font-family: inter;
  // .row-container {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 50px;
  // }
  
}

// .row-container {
//   .title-container {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-size: 40px;
//     gap: 10px;

//     .product-list-title {
//       font-weight: 500;
//       font-size: 1em;
//     }

//     .explore-btn {
//       text-transform: uppercase;
//       color: #000;
//       text-decoration: underline;
//       font-size: 16px;
//       text-wrap: nowrap;
//     }

//     @media screen and (max-width: 768px) {
//       font-size: 22px;

//       .explore-btn {
//         font-size: 12px;
//       }
//     }

//     @media screen and (max-width: 650px) {
//       font-size: 20px;

//       .explore-btn {
//         font-size: 10px;
//       }
//     }
//   }

//   .cards-grid {
//     display: flex;
//     flex-wrap: wrap;
//     // overflow-x: auto;
//     gap: 20px;

//     &::-webkit-scrollbar {
//       display: none;
//     }

//     .deal-card {
//       max-width: 244px;
//     }

//     .store-card {
//       max-width: 200px;
//       flex: 1;
//       cursor: pointer;

//       .store-img {
//         width: 100%;
//         min-width: 160px;
//         max-width: 223px;
//         aspect-ratio: 223 / 228;
//         object-fit: cover;
//         border-radius: 10px;
//       }

//       .store-name {
//         text-align: center;
//         width: 100%;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         height: 2.6em;
//         display: -webkit-box;
//         -webkit-box-orient: vertical;
//         -webkit-line-clamp: 2;
//       }
//     }

//     @media (max-width: 1100px) {
//       // if even - consider this limit
//       .store-card:nth-last-child(1) {
//         display: none;
//       }
//     }

//     @media (max-width: 920px) {
//       // if odd - doesnt cosider
//       .store-card:nth-last-child(2) {
//         display: none;
//       }
//     }

//     @media (max-width: 768px) {
//       display: grid;
//       grid-template-columns: auto auto auto;
//       gap: 10px;
//       row-gap: 20px;

//       .store-card {
//         display: block !important;

//         .store-img {
//           min-width: 120px;
//         }
//       }
//     }

//     @media (max-width: 425px) {
//       display: grid;
//       grid-template-columns: auto auto;
//       gap: 10px;
//       row-gap: 20px;

//       .store-card {
//         display: block !important;

//         .store-img {
//           min-width: 120px;
//         }
//       }
//     }
//   }
// }


.adv-container {
  max-width: 1400px;
  padding: 20px;
  margin-inline: auto;

  .adv-home {
    width: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }
}


.sell-on-ondc-container {
  position: relative;
  background: url("../../assets//images/homepage/sell-on-ondc.png") no-repeat
    center center/cover;
  border-radius: 20px;
  max-width: 1400px;
  margin-inline: auto;
  margin-bottom: 80px;

  @media screen and (max-width: 1440px) {
    margin-inline: 20px;
  }
}

.sell-on-ondc-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Adjust the 0.5 to change transparency */
  border-radius: 20px;
}

.text-overlay {
  margin-inline: auto;
  padding: 8%;
  width: 80%;
  text-align: center;
  color: white;
  font-family: Inter;
  position: relative;
  z-index: 1;
  /* Ensures the text is above the overlay */

  .title {
    font-size: 40px;
    font-weight: 400;
    text-wrap: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }

    .ondc {
      color: #53b175;
    }
  }

  .description {
    color: #fff;
    text-align: center;
    font-family: var(--font-inter);
    margin: 15px auto;
    margin-bottom: 26px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .connect-now {
    background-color: #53b175;
    color: #fff;
    text-align: center;
    padding: 0.8em 2em;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }
}

.features-home {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .feature-home-item {
    text-align: center;
    align-items: center;

    img {
      text-align: center;
      margin: 20px auto 20px auto;
      height: 40px;
      width: 40px;
      border: 2px solid black;
    }

    .title {
      color: #010101;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      padding: 20px 20px 20px 20px;
      text-align: center;
      max-width: 400px;
      color: #989898;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  img {
    padding: 35px 55px;
    width: 200px;
    filter: grayscale(100%);
  }
}

.feedback-container {
  background: #eee;
  text-align: center;
  padding: 50px 120px 70px;
  max-width: 85%;
  margin-inline: auto;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 25px;
  }

  .total-feedback {
    display: flex;
    flex-direction: row;
    gap: 34px;

    @media screen and (max-width: 768px) {
      gap: 20px;
    }

    .feedback {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 900px;
      flex: 1;
    }

    .cust-name {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }

    .open-quotes {
      vertical-align: top;
      align-self: flex-start;
      color: #000;
      font-family: Poppins;
      font-size: 96px;
      font-style: normal;
      font-weight: 400;
      line-height: 86px;
    }

    p {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }
  }

  .four-circles {
    margin-top: 40px;
    width: 78px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .circle1 {
      height: 8px;
      width: 8px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #53b175;
    }

    .circle2 {
      height: 8px;
      width: 8px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #d9d9d9;
    }
  }
}
