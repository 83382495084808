.popup-box-selectLocation {
  // width: 575px;
  width: 50%;
  max-width: 500px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  outline: none !important;
  .close-cross-selectLocation {
    width: 20px;
    display: block;
    margin-left: auto;
    cursor: pointer;
  }
  .popup-map-selectLocation {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: -20px;
    .map-image-selectLocation {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #f5f5f5;
    }
  }
  .popup-title-selectLocation {
    font-size: 20px;
    font-weight: 600;
    color: #182035;
    margin-bottom: 20px;
  }
  .popup-search-selectLocation {
    // display: flex;
    margin-bottom: 10px;
    flex: 1;
    position: relative;
    .popup-input-selectLocation {
      border: 1px solid #b0dcc0;
      padding: 15px 50px 15px 30px;
      border-radius: 27px;
      font-size: 14px;
      width: 100%;
      transition: border-radius 0.3s, border 0.3s;
  
      &.with-suggestions {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
      }
    }
    .g-search-lens-selecetLocation {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .suggestions-dropdown-selectLocation {
      // padding: 2px;
      border: 1px solid #ddd;
      background: #fff;
      position: absolute;
     overflow: hidden;
     width: 100%;
      padding-bottom: 10px;
      border-bottom-left-radius: 27px;
      border-bottom-right-radius: 27px;
      z-index: 10;
    .suggestion-list{
      overflow-y: auto;
      height: 150px;
      // margin: 5px;
      // padding: 2px;
      // max-height: 150px;
      
    
    .suggestion-item-selectLocation {
      padding: 10px;
      cursor: pointer;
      text-align: start;
    }
    
    .suggestion-item-selectLocation:hover {
      background-color: #f0f0f0;
        }
      }
    }
  }
  .popup-options-selectLocation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .option-selectLocation {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      
      p {
        color: #676767;
        font-size: 12px;
        font-weight: 600;
        padding: 5px;
      }
    }
  }
  
  .map-address-section{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: space-between;
  .mapAddress{
    font-weight: 400;
    text-align: start;
    font-size: 14px;
  }
  .popup-accept-selectLocation {
    display: flex;
    max-width: fit-content;
    padding: 10px 25px;
    justify-content: center;
    border-radius: 25px;
    // height: 40px;
    align-self: center;
    cursor: pointer;
      background-color: rgba(83, 177, 117, 1);
      color: #fff;
    }
  }
  
}
@media only screen and (max-width: 770px) {
  .popup-box-selectLocation {
    width: 90%;
    padding: 25px;
  }
}
@media only screen and (max-width: 500px) {
  .popup-options-selectLocation {
    flex-direction: column; 
    gap: 15px; 
    .option-selectLocation {
      width: 100%;          
    }
  }
  .popup-box-selectLocation {
  .map-address-section{
    flex-direction: column;
    gap: 10px;
    .mapAddress{
      width: 100%;
    }
    .popup-accept-selectLocation{
      width: 100%;
      align-self: flex-start;
    }
  }
}
}