.sidebar {
  width: 300px;
  height: fit-content;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;

  @media (max-width: 1024px) {
    width: 220px;
    min-width: 220px;
  }

  .nav-ham {
    color: #333;
    padding: 24px 20px 0 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }

    .ham-button {
      display: none;
      cursor: pointer;

      div {
        width: 22px;
        background-color: #000;
        height: 1.8px;
        margin-block: 7px;
      }

    }
  }

  .profile-menus {
    list-style: none;
    padding: 0;

    li {
      .nav-link {
        display: flex;
        align-items: center;
        gap: 7px;
        text-decoration: none;
        color: #000;
        padding: 16px 20px;
        color: #666;
        font-weight: 500;
        border-left: 4px solid #fff;
        cursor: pointer;

        &.active {
          background-color: #e7f3e8;
          color: #34a853;
          border-left: 4px solid #34a853;
        }
      }
    }
  }
}

.sidebar-desktop {
 display: block;
}

.sidebar-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar-desktop {
    display: none;
  }
  .sidebar-mobile {
    display: block;
    position: relative;
    margin-bottom: 0;
    width: 100%;

    .nav-ham {
      .ham-button {
        display: block;
      }
    }

    .profile-menus {
      position: absolute;
      width: 100%;
      z-index: 2;
      top: 70px;
      height: 0px;
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: height 0.5s;

    }
  }
}