.searchMainDiv {
  display: flex;
  // overflow-x: scroll;
}

@media screen and (max-width: 1230px) {
  .searchMainDiv {
    display: flex;
    margin-top: 10px;
  }
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-top: 9px;
  margin-left: 8px;
  position: absolute;
}

.searchInputs {
  border: 1px solid #e2e2e2;
  padding: 10px 32px 12px;
  border-radius: 6px;
  margin-right: 10px;
  min-width: 50px;
}

.manuIcon {
  margin: 0px 8px;
  padding: 5px 10px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.menuIcons {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .searchMainDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .manuIcon {
    display: none !important;
  }
  .search-container {
    // display: none; // uncomment this before code push
  }
  .filterIcon {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    align-items: center;
    padding: 10px 20px;
  }
}
