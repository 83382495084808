.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #f5f5f5;

  .register-form-container {
    width: 350px;
    padding: 20px;
    margin: 20px 30px 80px;
    // margin: 80px 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;

    .register-title {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: bold;
      @media (max-width: 360px) {
        font-size: 28px;
      }
    }

    .register-form {
      display: flex;
      flex-direction: column;

      .register-input {
        margin-bottom: 12px;
        padding: 13px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .password-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        .password-input {
          width: 100%;
          padding-right: 40px; /* Space for the icon */
        }

        .password-icon {
          position: absolute;
          right: 10px;
          top: 14px;
          cursor: pointer;
          .image {
            height: 20px;
          }
        }
      }

      .register-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        .label-with-checkbox {
          display: flex;
          align-items: center;
          margin-top: 4px;
          .checkbox-input {
            height: 20px;
            width: 20px;
            accent-color: #6fae79;
          }
          .remember {
            margin-left: 6px;
            color: #666666;
          }
          .terms-link {
            color: #53b175;
            text-decoration: underline;
            cursor: pointer;
          }

          .terms-link:hover {
            text-decoration: none;
          }
        }
        .forgot-password {
          color: #000;
          text-decoration: none;
        }
      }

      .register-button {
        padding: 13px;
        background: #53b175;
        color: #fff;
        font-size: 16px;
        border: none;
        border-radius: 24px;
        cursor: pointer;
      }

      .register-text {
        margin-top: 20px;
        font-size: 14px;

        a {
          color: #1a1a1a;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .register-container {
    padding: 20px;
  }

  .register-form-container {
    width: 100%;
    padding: 20px;
    margin: 40px 0;

    .register-title {
      font-size: 24px;
    }

    .register-form {
      .register-input {
        font-size: 14px;
        padding: 10px;
      }

      .register-button {
        font-size: 14px;
        padding: 10px;
      }

      .register-text {
        font-size: 12px;
      }

      .register-options {
        flex-direction: column;
        align-items: flex-start;
        .label-with-checkbox {
          .remember {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
