.checkout-banner {
  width: 100%;
  height: 100px;
  // background: linear-gradient(180deg, #53b175 0%, #b3dec3 100%);
  background-image: linear-gradient(75.04deg, #f4e8f3 0%, #f3eff6 52.07%, #eee0f9 102.02%);
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: #000;
  }
}
.headerTag {
  padding: 20px 0 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-block: 0;

  .headerText {
    cursor: pointer;
  }
}

.checkout {
  display: flex;
  gap: 30px;
  // margin-top: 60px;

  .left-checkout {
    width: 65%;

    .checkout-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      gap: 10px;

      h2 {
        font-size: 20px;
        font-weight: 500;
      }

      .add-address-btn {
        color: #4caf50;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        .add-address-btn-text2 {
          display: none;
        }
        @media (max-width: 350px) {
          .add-address-btn-text1 {
            display: none;
          }
          .add-address-btn-text2 {
            display: block;
          }
        }
      }
    }

    .address-grid {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

      .address-card {
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 18px;
          margin-bottom: 8px;
          word-break: break-all;
        }

        .address-text {
          color: #999999;
          margin-bottom: 30px;
          word-break: break-all;
        }

        .email-div {
          margin-bottom: 20px;

          & > p {
            color: #999999;
          }
        }

        span {
          font-size: 14px;
        }

        .addressCheckDiv {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: auto;

          .checkboxInput {
            width: 20px;
            height: 20px;
            accent-color: #52ae73;
            color: #fff;

            /* Ensures no extra margin is added by default */
          }

          .checkboxText {
            margin: 0;
            color: #4d4d4d;
            /* Ensures consistent alignment */
          }
        }
      }
    }

    .scrollable-cart {
      max-height: 100px;
      overflow-y: auto;
      margin-top: 1rem;
      padding-right: 10px;
    }

    @media (max-width: 576px) {
      .address-grid {
        grid-template-columns: 1fr;
      }
    }
  }

  .right-checkout {
    width: 35%;
    min-width: 280px;
    // background-color: #f5f7ff;
    align-self: flex-start;
    padding: 20px;
    border-radius: 10px;
    background-color: #effff4;
    box-shadow: 0px 0px 5px 1px rgba(221, 221, 221, 0.5);

    .summryTitle {
      font-size: 20px;
      padding: 0px 0px 25px;
    }

    .summary-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      gap: 10px;
      margin-bottom: 15px;

      .summary-card-left {
        display: flex;
        align-items: center !important;
        gap: 10px;
        font-size: 14px;

        .imageSummery {
          object-fit: contain;
          width: 50px;
          height: 50px;
        }

        .sum-prod-details {
          .sum-prod-name {
            margin-bottom: 3px;
          }

          .sum-prod-quant {
            color: #7c7c7c;
          }
        }
      }

      .summary-card-right {
      }
    }

    .summryChild {
      display: flex;
      font-size: 14px;
      padding: 10px 0px;
      justify-content: space-between;
    }

    .paymentMethod {
      margin-top: 40px;
    }

    .childColor {
      color: gray;
    }
    .buttonMainDivs {
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 20px 0px;

      .Proceed-button {
        padding: 12px 90px;
        @media (max-width:350px) {
          padding: 12px 65px;
        }
      }
    }
  }

  .scrollable-container {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 1rem;
    padding-right: 10px;
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }

  h4 {
    font-size: 24px;
    font-size: 500;
  }

  .inputFlex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
  }

  .newDiv {
    flex: 1 1 48%;
    display: flex;
    flex-direction: column;

    .error-text {
      color: red;
      font-size: 12px;
      margin-top: 4px;
    }

    .error-border {
      border-color: red;
    }
  }

  .inputLable {
    color: #7c7c7c;
    margin-bottom: 5px;
  }

  .radioContainer {
    margin-bottom: 10px;
    display: flex;

    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid green;
      border-radius: 50%;
      margin-right: 10px;
      background-color: transparent;
      position: relative;
      cursor: pointer;
    }

    input[type="radio"]:checked::before {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      background-color: #52ae73;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: appear 0.8s;
    }

    @keyframes appear {
      0% {
        transform: translate(-50%, -50%) scale(0);
        background-color: #fff;
      }

      45% {
        transform: translate(-50%, -50%) scale(1.6);
        background-color: #64aedf;
      }

      50% {
        transform: translate(-50%, -50%) scale(1.7);
        background-color: #ad97b3;
      }

      55% {
        transform: translate(-50%, -50%) scale(1.6);
      }

      100% {
        transform: translate(-50%, -50%) scale(1);
        background-color: #52ae73;
      }
    }

    p {
      margin-left: 5px;
    }
  }

  .customRadio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .customRadio input[type="radio"] {
    display: none;
    /* Hide default radio button */
  }

  .customRadio:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-right: 10px;
    background-color: white;
    position: relative;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }

  .customRadio.selected:before {
    border-color: green;
    /* Green border for selected radio button */
  }

  .customRadio.selected:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: green;

    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    transition: background-color 0.3s ease;
  }

  .placeOrder {
    width: 100%;
    text-align: center !important;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  // .placeOrder:disabled {
  //   background-color: rgba(83, 177, 117, 0.7);
  // }

  .single-button {
    width: 50%;
    text-align: center !important;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 25px;
  }

  .paymentMethod {
    margin-top: 10px;

    .paymentMethodname {
      cursor: pointer;
      padding-left: 5px;
    }
  }

  .discount2 {
    width: 50px;
    padding: 4px;
    text-align: center;
    border-radius: 28px;
    background: #53b175;
    color: #fff;
    font-size: 10px;
    position: relative;
    z-index: 1;
    margin-top: 5px;
    font-weight: 400;
  }

  .summryChildMian {
    margin-top: 20px;
  }

  .saveIcon {
    width: 35px;
  }

  .iconWithTexts {
    display: flex;
    margin-top: 5px;
    gap: 20px;
    align-items: center;

    .iconWithText {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .checkMainDiv {
    display: flex;
    align-items: center;
    margin: 30px 0px;
    padding-bottom: 30px;
    user-select: none;
    border-bottom: 2px solid #e2e2e2 !important;

    .checkboxInput {
      width: 20px;
      height: 20px;
      accent-color: #52ae73;
    }

    .checkboxText {
      padding-top: -3px !important;
      margin-left: 6px;
      color: #4d4d4d;
    }
  }

  .textAreaChange {
    border: 2px solid #e2e2e2 !important;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
  }

  .inputBorder {
    padding: 10px 1px;
    border-bottom: 2px solid #e2e2e2 !important;
    background: transparent;
    outline: none;
    font-size: 18px;
  }

  .inputBorder:focus {
    border-bottom: 2px solid #b0dcc0 !important;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;

    .inputFlex {
      flex-direction: column;
    }

    .newDiv {
      flex: 1 1 100%;
    }

    .headerMenus {
      padding: 20px;
      display: flex;
      flex-wrap: wrap !important;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    padding: 10px 15px;

    h4 {
      font-size: 20px;
    }
  }
}

@media (max-width: 744px) {
  .checkout {
    padding: 20px;
    display: flex;
    flex-wrap: wrap !important;
    width: 100%;
    margin-inline: 0px;

    .left-checkout {
      width: 100%;
      padding: 0px !important;
    }
  }

  .right-checkout {
    width: 100% !important;
  }
  .address-container {
    padding: 0px !important;
  }

  .left-checkout {
    padding: 0px !important;
  }

  .HomeNavigate {
    position: absolute;
    top: 185px;
    z-index: 10;
  }

  .discount2 {
    font-size: 9px;
  }

  .add-adrs-btns {
    gap: 10px !important;
  }
  .add-save-btn {
    padding: 10px 40px !important;
  }
}

@media (min-width: 744px) and (max-width: 1025px) {
  .checkout {
    padding: 20px;
    display: flex;
    flex-wrap: wrap !important;
    width: 100%;
    margin-inline: 0px;

    .left-checkout {
      width: 100%;
      padding: 0px !important;
    }
    .right-checkout {
      width: 100% !important;
    }
    .placeOrder {
      width: 50%;
    }
  }
}
