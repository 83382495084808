@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
select,
textarea,
button {
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    background: none;
    
}