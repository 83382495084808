.order-history {
  background-color: #fff;
  border-radius: 8px;
  padding-block: 16px;
  border: 1px solid #e6e6e6;

  .order-header {
    padding-left: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    h1{
      font-size: 20px;
      font-weight: 500;
    }

    .view-all {
      color: #34a853;
      font-weight: 500;
      text-decoration: none;
      cursor: pointer;
      padding-right: 40px;
    }
    
  }

  .loading-text {
    text-align: center;
    margin-block: 30px;
  }

  .history-table-wrapper {
    max-height: 350px;
    overflow-y: auto;

    .grid-wrapper {
      display: none;
      grid-template-columns: 1fr 1fr;
      padding: 10px;
      row-gap: 10px;
      column-gap: 10px;
    }

    .history-table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;

      .history-head-row,
      .history-body-row {
        display: flex;
        width: 100%;
      }

      // .history-body-row:last-child {
      //   background-color: rgb(234, 112, 112);
      // }

      th,
      td {
        padding-block: 12px;
      }

      thead {
        &::after {
          content: "";
          display: block;
          height: 13px;
        }

        .history-head-row {
          th {
            color: #4D4D4D;
            background-color: #f2f2f2;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      tbody {
        .history-body-row {
          font-size: 14px;
          color: #333333;
          font-weight: 500;

          &:hover {
            background-color: #e7f3e8;
          }

          td {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          td:nth-child(1) {
            overflow: hidden;
            display: -webkit-box;
            --webkit-box-orient: vertical;
            --webkit-line-clamp: 2;
            text-overflow: ellipsis;
            word-break: break-word;
          }

          .order-products {
            display: flex;
            gap: 3px;
            flex-wrap: wrap;

            .order-amount {
              white-space: nowrap;
            }

            .order-quantity {
              white-space: nowrap;
            }

          }

          .view-details {
            color: #34a853;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }


      th:nth-child(1),
      td:nth-child(1) {
        flex: 1 1 auto;
        min-width: 100px;
        padding-left: 24px;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 105px;
        flex-shrink: 0;
        white-space: nowrap;
        padding-left: 10px;
      }

      th:nth-child(3),
      td:nth-child(3) {
        width: 170px;
        flex-shrink: 0;
        white-space: nowrap;
        padding-left: 10px;

        @media (max-width: 1024px) {
          width: 100px;
        }
      }

      th:nth-child(4),
      td:nth-child(4) {
        width: 120px;
        flex-shrink: 0;
        white-space: nowrap;
        padding-left: 10px;
      }

      th:nth-child(5),
      td:nth-child(5) {
        width: 120px;
        flex-shrink: 0;
        white-space: nowrap;
        padding-left: 10px;
      }

      th:last-child,
      td:last-child {
        padding-right: 24px;
      }
    }
  }


  @media (max-width: 880px) {
    border: 0;

    .order-header {
      padding-left: 0;
      margin-bottom: 30px;
    }

    .history-table-wrapper {
      max-height: unset;
      overflow-y: unset;

      .history-table {
        display: none;
        border: 0;
        padding: 0;
      }

      .grid-wrapper {
        display: grid;
        padding: 0;

        @media (max-width: 855px) {
          grid-template-columns: 1fr;
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 605px) {
          grid-template-columns: 1fr;
          row-gap: 15px;
        }
      }

    }

  }

}