.store-background {
  // background-image: linear-gradient(#53b175, #b3dec3);
  background-image: linear-gradient(75.04deg, #f4e8f3 0%, #f3eff6 52.07%, #eee0f9 102.02%);
  background-size: 100% 95px;
  background-repeat: no-repeat;

  .row-container {
    margin-block: 0;
    padding-top: 10px;
    position: relative;

    .store-content {
      width: 100%;

      .store-image {
        width: 150px;
        height: 150px;
        border-radius: 14px;
        object-fit: contain;
        border: 1.5px solid #e2e2e2; // Styles for the images inside store-content
        background-color: #fff;
      }

      .share-button {
        position: absolute;
        top: 26px;
        right: 50px;

        @media (max-width:1440px) {
          right: 55px;

        }

        img.share-icon {
          width: 34px;
          height: 34px;
          // Styles for the share-icon image inside share-button
        }

        @media (max-width:770px) {
          margin-top: 40px;

        }
      }

    }

    .store-details {
      --offset: 165px;
      position: absolute;
      top: 40px;
      width: calc(100% - 40px - var(--offset));
      margin-left: auto;
      left: calc(20px + var(--offset));

      .store-name {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 50px;

      }

      .store-add-dtls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;

        .store-adds {
          display: flex;
          
          .store-add-text {
            display: -webkit-box;
            max-height: 2.4em;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

        }

        .store-time {
          display: flex;
          flex-shrink: 0;

        }

        .location-icon {
          width: 14px;
          height: 14px;
          object-fit: contain;
          margin-top: 3px;
          margin-right: 5px;


        }

        @media (max-width: 770px) {
          margin-top: 10px;
          gap: 10px;
          flex-direction: column;
          align-items: flex-start;

          .store-adds,
          .store-time {
            width: 100%;
          }
        }

      }

      @media (max-width:770px) {
        position: static;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 36px;

        .store-name {
          margin-bottom: 15px;
        }
      }

      // @media (max-width:500px) {
      //   width: 90%;

      // } 
      .store-closed-in,
      .store-closed{
        display: flex;
          color: red;
          font-weight: 600;
          


          @media (max-width:770px) {
            width: 100%;
          }

      }
      .store-open{
        color: #53b175;
        display: flex;
      font-weight: 500;
      @media (max-width:770px) {
        width: 100%;
      }
      }



    }
  }

}

.filterContainers {
  padding-top: 16px !important;
}

.filterContainer {
  margin-top: 36px;

  @media (max-width:770px) {
    margin-top: 0;


  }



  .fullWidthSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;

    .leftSidebar {
      margin-bottom: 20px;
    }

    .mainComponent {
      flex: 1;
      overflow-x: hidden;

      .shopByCategoryHeading {
        font-family: --var(font-public-sans);
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .categoryList {
        .category-row {}
      }
    }
  }

  @media (max-width: 1440px) {
    margin-inline: 20px;
  }
}

.container {
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.headerChanges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .headerChanges {
    // display: none;
  }
}

.fullWidthSection {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: row;
}

.shopByCategoryHeading {
  font-family: --var(font-public-sans);
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
}

.trendingHeading {
  padding: 40px;
  background-color: #f8f8f8;
}

.categoryList {
  overflow: hidden;
}

.cardsContainer {
  display: grid;
  gap: 16px;
  margin-top: 24px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .cardsContainer {
    display: grid;
    gap: 16px;
    margin-top: 24px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    flex-wrap: wrap;
  }
}

.dropdownContainer {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.catdropdown {
  padding: 8px;
  border-radius: 10px;
  background: #b0dcc0;
  width: 30%;
  color: #222;
  font-family: "Public Sans";
  font-size: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  border: none;
}

.storedropdown {
  padding: 8px;
  font-family: "Public Sans";
  font-size: 20px;
  color: #222;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  background: #b0dcc0;
  border: none;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .catdropdown {
    width: 100%;
  }

  .storedropdown {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .dropdownContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fullWidthSection {
    display: flex;
    flex-direction: row;
  }

  .leftSidebar {
    width: 50%;
  }
}

.heading {
  color: #222;
  font-family: "Public Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.underline {
  background: #0da487;
  width: 120px;
  height: 2px;
  margin-bottom: 16px;
}

.subheading {
  font-family: "Public Sans";
  font-size: 24px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 24px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 8px;
  border-radius: 8px;
  background: #53b175;
  accent-color: #0da487;
}

.dottedline {
  margin-top: 20px;
  border-bottom: 1px dashed rgba(74, 85, 104, 0.3);
  max-width: 213px;
  height: 1px;
}

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.prodName {
  color: #222;
  font-style: normal;
  font-weight: 600;
}

.prodWeight {
  color: #4a5568;
  font-style: normal;
  font-weight: 400;
}

.productImage {
  width: 60px;
  height: 50px;
  margin-right: 16px;
}

.rupee {
  font-family: "Public Sans";
  color: #53b175;
}

.strikethrough {
  text-decoration: line-through;
  color: #7c7c7c;
}

.HeaderLinear {
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, #53b175 0%, #b3dec3 100%);
  position: absolute !important;
}

.filterFlexdiv {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.HeaderLinearChild {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .HeaderLinearChild {
    padding: 0px 10px;
  }

  .superMakerths {
    padding: 10px !important;
  }
}

.superMakerth {
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    height: 80px;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    padding: 10px;
  }
}

.superMakerths {
  position: relative;
  font-size: 14px;
  display: flex;
  row-gap: 15px;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  p {
    img {
      margin-right: 10px;
    }
  }

  @media (min-width: 768px) {
    height: 70px;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.shareIcon {
  position: relative;
  top: 15px;
  width: 34px;
  height: 34px;
}

.locationIcon {
  width: 14px;
  height: 14px;
  margin: 5px 5px 0px 0px;
}

.HeaderLinearChildImg {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 14px;
  overflow: hidden;
  object-fit: contain;
  border: 1.5px solid #e2e2e2;
  background-color: #fff;
}

.rightArrow {
  width: 10px;
  height: 10px;
  margin: 0px 5px;
}