.headerImage {
  // transform: rotate(180deg);
  height: 120px;
  width: 100%;
  object-fit: cover !important;
}
.BreadCumbNavigate {
  padding: 0px 80px;
  .homenav {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    .homeIconImage {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    .vectorImage {
      width: 10px;
      height: 10px;
    }
    .shoppingText {
      color: #999999;
      cursor: pointer;
    }
    .checkOutText {
      color: #00b207;
    }
  }
}

