.popup-box {
    padding: 30px;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 30px;
    outline: none !important;

    .close-cross {
      width: 14px;
      display: block;
      margin-left: auto;
      cursor: pointer;
      display: none;
    }
  
    .popup-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #182035;
    }
  
    .popup-content {
      text-align: left;
      margin-bottom: 15px;
      line-height: 1.6;
      color: #666666;
    }
    .location-instruction-content ol {
      margin: 0;
      padding-left: 1rem;
    }
  
    .popup-button-group {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 15px;
  
      .popup-button {
        padding: 10px 40px;
        border-radius: 30px;
        cursor: pointer;
  
        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
  
      .popup-accept {
        background-color: rgba(83, 177, 117, 1);
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 770px) {
    .popup-box {
      width: 90%;
      padding: 25px;
    }

    
  }
  